import { Injectable } from '@angular/core';
import { ActivityService } from './activity.service';
import { Endpoints } from '../shared/endpoints';
import { GetAllInvoicesResponseApi, GetAllInvoicesResponseView, InvoiceApi, InvoiceView } from '../models/invoice.model';
import { BookingView, GetAllBookingsResponseApi, GetAllBookingsResponseView, GetBookingsPayload } from 'src/models/activity.model';
import { ISendEmailConfig } from 'src/modules/shared-modals/modals/send-email/send-email.modal';
import { map } from 'rxjs/operators';
import { NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ObjectUtilities } from 'src/shared/object.utilities';
import { Observable } from 'rxjs';
import { RequestService } from './request.service';
import { SharedModalsService } from './shared-modals.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class InvoicesService {
    public iGoInvoices: GetAllInvoicesResponseView = null;
    public originatorInvoices: GetAllInvoicesResponseView = null;

    private invoiceBookings: GetAllBookingsResponseView = null;

    public constructor(
        private activityService: ActivityService,
        private http: RequestService,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService
    ) { };

    public getIGoInvoices = (): GetAllInvoicesResponseView => this.iGoInvoices;
    public getOriginatorInvoices = (): GetAllInvoicesResponseView => this.originatorInvoices;

    public getInvoiceBookings = (): GetAllBookingsResponseView => this.invoiceBookings;

    public emailCreditNoteAsync(url: string, email: string): Observable<void> {
        return this.http.authenticatedPost<void>(url, { email }, {}, 'global.request.sending_email');
    };

    public emailRiderReceiptAsync(url: string, email: string): Observable<void> {
        return this.http.authenticatedPost<void>(url, { email }, {}, 'global.request.sending_email');
    };

    public emailInvoiceAsync(url: string, email: string): Observable<void> {
        return this.http.authenticatedPost<void>(url, { email }, {}, 'global.request.sending_email');
    };

    public getBookingsByInvoiceObservable({ id, isIGoInvoice }: InvoiceView, payload: GetBookingsPayload, start: number = 0): Observable<GetAllBookingsResponseView> {
        const payloadCopy: Partial<GetBookingsPayload> = this.activityService.formatBookingsSearchPayload(payload, start);
        const url: string = Endpoints.API_URL + Endpoints.BOOKINGS._BOOKINGS + Endpoints.BOOKINGS._BY_INVOICE + `/${id}` + Endpoints.BOOKINGS._FIND;
        return this.http.authenticatedPost<GetAllBookingsResponseApi>(url, { bookingFilter: payloadCopy, type: isIGoInvoice ? 'IGo' : 'Originator' }, {}, 'global.request.loading_bookings').pipe(
            map(res => {
                let existingBookings: BookingView[] = [];
                if (start !== 0 && this.invoiceBookings?.bookings) {
                    existingBookings = ObjectUtilities.deepCopy(this.invoiceBookings?.bookings);
                };
                const bookingsResultView = new GetAllBookingsResponseView(res as GetAllBookingsResponseView);
                this.invoiceBookings = ObjectUtilities.deepCopy(bookingsResultView);
                this.invoiceBookings.bookings = existingBookings.concat(this.invoiceBookings.bookings);

                return bookingsResultView;
            })
        );
    };

    public getIGoInvoicesObservable(url?: string): Observable<void> {
        return this.http.authenticatedGet<GetAllInvoicesResponseApi>(url || (Endpoints.API_URL + Endpoints.INVOICES._INVOICES + Endpoints.INVOICES._IGO), {}, 'global.request.loading_invoices').pipe(
            map(res => {
                const invoicesResultView = new GetAllInvoicesResponseView(res as GetAllInvoicesResponseView);
                invoicesResultView.invoices.map(invoice => {
                    invoice.isIGoInvoice = true;
                    return invoice;
                });
                this.iGoInvoices = invoicesResultView;
            })
        );
    };

    public getInvoiceBookingsCountObservable({ id, isIGoInvoice }: InvoiceView, payload: GetBookingsPayload, start: number = 0): Observable<number> {
        const payloadCopy: Partial<GetBookingsPayload> = this.activityService.formatBookingsSearchPayload(payload, start);
        const url: string = Endpoints.API_URL + Endpoints.BOOKINGS._BOOKINGS + Endpoints.BOOKINGS._BY_INVOICE + `/${id}` + Endpoints.BOOKINGS._COUNT;
        return this.http.authenticatedPost<{ count: number; }>(url, { bookingFilter: payloadCopy, type: isIGoInvoice ? 'IGo' : 'Originator' }, 'global.request.loading_bookings').pipe(
            map(res => res.count)
        );
    };

    public getInvoiceByIdObservable(url: string, isIGoInvoice: boolean): Observable<InvoiceView> {
        return this.http.authenticatedGet<InvoiceApi>(url, {}, 'global.request.loading_invoice').pipe(
            map(res => new InvoiceView(res as InvoiceView, isIGoInvoice))
        );
    };

    public getOriginatorInvoicesObservable(url?: string): Observable<void> {
        return this.http.authenticatedGet<GetAllInvoicesResponseApi>(url || (Endpoints.API_URL + Endpoints.INVOICES._INVOICES + Endpoints.INVOICES._ORIGINATOR), {}, 'global.request.loading_invoices').pipe(
            map(res => {
                const invoicesResultView = new GetAllInvoicesResponseView(res as GetAllInvoicesResponseView);
                invoicesResultView.invoices.map(invoice => {
                    invoice.isIGoInvoice = false;
                    return invoice;
                });
                this.originatorInvoices = invoicesResultView;
            })
        );
    };

    public showEmailInvoiceModal(invoice: InvoiceView, options: Partial<NgbModalConfig>): NgbModalRef {
        const config: ISendEmailConfig = {
            email: invoice.email,
            title: this.translateService.instant('global.modals.send_email.email_invoice'),
        };
        return this.sharedModalsService.showSendEmailModal(config, options);
    };

    public deleteInvoiceAsync(invoice: InvoiceView): Observable<string> {
        const url: string = invoice.isIGoInvoice ? invoice._links.DeleteIGoInvoice?.href : invoice._links.DeleteOriginatorInvoice?.href;
        return this.http.authenticatedDelete<void>(url, {}, 'global.request.deleting_invoice').pipe(
            map(res => invoice.id)
        );
    };
};