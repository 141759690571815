import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { iGoUserView } from 'src/models';

@Component({
    selector: 'user-status-cell',
    template: `<div>
        <span class="status-card {{class}}">{{status}}</span>
    </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [`
        :host { display: flex; flex-flow: column; height: 100%; justify-content: center; padding: 0 20px; width: 100%; }
        .status-card { border-radius: 5px; color: white; padding: 5px; }
    `]
})
export class UserStatusCellTplComponent {
    @Input() public set row(item: iGoUserView) {
        if (item) {
            if (item.registered) {
                this.class = 'bg-success';
                this.status = this.translateService.instant('igo_portal.admin.users.table.registered');
            } else {
                this.class = 'bg-warning';
                this.status = this.translateService.instant('igo_portal.admin.users.table.registered_pending');
            }
            this.cd.detectChanges();
        };
    };
    public class: string = '';
    public status: string = '';
    public constructor(
        private cd: ChangeDetectorRef,
        private translateService: TranslateService
    ) { };
};