import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DisputeView } from 'src/models';

@Component({
    selector: 'dispute-last-message-cell',
    template: `<i *ngIf="showWarningIcon" class="fal fa-circle-exclamation text-danger me-2"></i> <span title="{{messageText}}">{{messageText}}</span>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [`
        :host { align-items: center; display: flex; height: 100%; padding: 2px 20px; white-space: pre-line; width: 100%; }
        :host.gvs-action-cell:hover { background-color: rgb(42 42 42 / 20%) !important; transition: 0.2s ease-in-out; }
        :host span { overflow: hidden; text-overflow: ellipsis; white-space: pre; }
        :host.gvs-action-cell i { font-size: 20px; }
    `]
})
export class DisputeLastMessageCellTplComponent {
    @Input() public set row(item: DisputeView) {
        if (item) {
            this.messageText = item.lastMessage?.messageSenderAndTime;
            if (item && item.lastMessage) {
                this.showWarningIcon = !item.lastMessage.sentByCurrentUserPartner;
            };
            this.cd.detectChanges();
        };
    };
    public messageText: string = '';
    public showWarningIcon: boolean = false;
    public constructor(private cd: ChangeDetectorRef) { };
};