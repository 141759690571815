// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rating-wrapper {
    height: 47px;
}

ngb-rating.disabled,
ngb-rating.disabled span {
    cursor: not-allowed;
    opacity: .9;
}

ngb-rating:focus {
    outline: none;
}

.star {
    position: relative;
    display: inline-block;
    font-size: 1.5rem;
    color: #cccccc;
    cursor: pointer;
}

.full {
    color: #F0A61D;
}

.half {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    color: #F0A61D;
}`, "",{"version":3,"sources":["webpack://./src/styles/ngbrating.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;;IAEI,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,iBAAiB;IACjB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".rating-wrapper {\n    height: 47px;\n}\n\nngb-rating.disabled,\nngb-rating.disabled span {\n    cursor: not-allowed;\n    opacity: .9;\n}\n\nngb-rating:focus {\n    outline: none;\n}\n\n.star {\n    position: relative;\n    display: inline-block;\n    font-size: 1.5rem;\n    color: #cccccc;\n    cursor: pointer;\n}\n\n.full {\n    color: #F0A61D;\n}\n\n.half {\n    position: absolute;\n    display: inline-block;\n    overflow: hidden;\n    color: #F0A61D;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
