// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        :host {
            display: block;
            height: 100%;
            overflow: auto;
            padding: 0 40px 40px 40px;
            width: 100%;
        }
    `, "",{"version":3,"sources":["webpack://./src/shared/components/activity-bookings-filters/activity-bookings-filters.component.ts"],"names":[],"mappings":";QACQ;YACI,cAAc;YACd,YAAY;YACZ,cAAc;YACd,yBAAyB;YACzB,WAAW;QACf","sourcesContent":["\n        :host {\n            display: block;\n            height: 100%;\n            overflow: auto;\n            padding: 0 40px 40px 40px;\n            width: 100%;\n        }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
