import { Input, Pipe, PipeTransform } from '@angular/core';
import { getCurrencySymbol, getLocaleCurrencySymbol } from '@angular/common';
import { SettingsService } from '../../services/settings.service';
import { StorageService } from '../../services/storage.service';

@Pipe({
    name: 'currencyPipe', pure: true
})
export class CurrencyGlobalPipe implements PipeTransform {
    @Input() public currency: string = '';
    
    public transform(value: number): string {
        const languageCode = StorageService.getItem('languageCode') || SettingsService.LanguageCode;
        const locale = languageCode.replace('_', '-');
        if (this.currency) {
            try {
                return getCurrencySymbol(this.currency, 'narrow') + new Intl.NumberFormat(locale, { style: 'decimal', minimumFractionDigits: 2, currency: this.currency }).format(value);
            } catch (error) {  
                return value.toString();
            };
        } else {
            return getLocaleCurrencySymbol(locale) + new Intl.NumberFormat(locale, { style: 'decimal', minimumFractionDigits: 2 }).format(value);
        };
    };
};