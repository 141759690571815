// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.permission-group {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.permission-group .permission-title {
    font-family: ZonaProBold;
    margin-bottom: 0;
}

.permission-group .toggle-all {
    align-items: center;
    border-bottom: 1px solid #e2e2e2;
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
    width: 100%;
}

.permission-group .permission {
    align-items: center;
    border-bottom: 1px solid #e2e2e2;
    color: rgb(var(--igo-black));
    cursor: pointer;
    display: flex;
    font-family: ZonaProRegular;
    justify-content: space-between;
    padding: 10px 0;
    text-align: center;
    width: 100%;
}

.permission-group .permission.selected {
    font-family: ZonaProBold;
}

.permission-group .permission span {
    transition: all 0.2s ease-in-out;
}

.permission-group .permission:hover span {
    transform: translateX(3px);
    transition: all 0.2s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/modules/shared-modals/modals/user-role/user-role.modal.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,wBAAwB;IACxB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,gCAAgC;IAChC,aAAa;IACb,yBAAyB;IACzB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,gCAAgC;IAChC,4BAA4B;IAC5B,eAAe;IACf,aAAa;IACb,2BAA2B;IAC3B,8BAA8B;IAC9B,eAAe;IACf,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,0BAA0B;IAC1B,gCAAgC;AACpC","sourcesContent":[".permission-group {\n    align-items: center;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n.permission-group .permission-title {\n    font-family: ZonaProBold;\n    margin-bottom: 0;\n}\n\n.permission-group .toggle-all {\n    align-items: center;\n    border-bottom: 1px solid #e2e2e2;\n    display: flex;\n    justify-content: flex-end;\n    padding: 10px 0;\n    width: 100%;\n}\n\n.permission-group .permission {\n    align-items: center;\n    border-bottom: 1px solid #e2e2e2;\n    color: rgb(var(--igo-black));\n    cursor: pointer;\n    display: flex;\n    font-family: ZonaProRegular;\n    justify-content: space-between;\n    padding: 10px 0;\n    text-align: center;\n    width: 100%;\n}\n\n.permission-group .permission.selected {\n    font-family: ZonaProBold;\n}\n\n.permission-group .permission span {\n    transition: all 0.2s ease-in-out;\n}\n\n.permission-group .permission:hover span {\n    transform: translateX(3px);\n    transition: all 0.2s ease-in-out;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
