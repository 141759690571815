import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { CacheLoaderService } from './cache-loader.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RootActivationService implements CanActivate {

    public constructor(
        private authenticationService: AuthenticationService,
        private cacheLoaderService: CacheLoaderService,
        private router: Router
    ) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | UrlTree {
        if (state.url !== '' && !this.authenticationService.authenticated) {
            this.cacheLoaderService.appCacheResolved = false;
            return this.router.createUrlTree(['/login']);
        };
        if (!this.cacheLoaderService.appCacheResolved) {
            return this.cacheLoaderService.fetchAppData();
        };
        return true;
    };
};