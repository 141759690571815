import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { iGoCompanyOperatorPartialView, iGoCompanyOriginatorPartialView, iGoCompanyView } from 'src/models';

@Component({
    selector: 'access-company-profile-cell',
    template: `
        <stacked-arrow-right-icons *ngIf="!accessingProfile" class="stacked-icons-wrapper" [iconClass]="'fal'"></stacked-arrow-right-icons>
        <i *ngIf="accessingProfile" class="fal fa-spinner-third fa-spin"></i>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { '[class.disabled]': 'disabled' },
    styles: [`
        :host { align-items: center; display: flex; flex-flow: column; justify-content: center; height: 100%; width: 100%; }
        ::ng-deep .gvs-action-cell:hover stacked-arrow-right-icons.stacked-icons-wrapper span.stacked-icons-wrapper i.stacked-icon.fa-arrow-right {
            transform: translateX(10px);
            transition: 0.3s ease;
        }
        :host.gvs-action-cell stacked-arrow-right-icons { font-size: 20px; }
        :host.disabled { opacity: 0.5; cursor: not-allowed; }
    `]
})
export class AccessCompanyProfileCellTplComponent {
    @Input() public set row(item: iGoCompanyView | iGoCompanyOperatorPartialView | iGoCompanyOriginatorPartialView) {
        if (item) {
            this.accessingProfile = Boolean(item.accessingProfile);
            this.disabled = this.accessingProfile || !item._links.AccessProfile;
            this.cd.detectChanges();
        };
    };
    public accessingProfile: boolean = false;
    public disabled: boolean = false;
    public constructor(private cd: ChangeDetectorRef) { };
};