import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class WindowService {
    public constructor() { };

    public openWindow(url: string, name: string, height: number, width: number): Window {
        var windowSize = {
            height: height,
            width: width
        };

        var windowLocation = {
            left: (window.screen['availLeft'] + (window.screen.availWidth / 2)) - (windowSize.width / 2),
            top: (window.screen['availTop'] + (window.screen.availHeight / 2)) - (windowSize.height / 2)
        };

        return window.open(url, name, 'width=' + windowSize.width + ', height=' + windowSize.height + ', left=' + windowLocation.left + ', top=' + windowLocation.top);
    };
};