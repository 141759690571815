import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ArrayUtilities } from "src/shared/array.utilities";
import { ConnectionsService, SharedModalsService, UserService } from "src/services";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { GetCXConnectionsFilters, iGoCompanyOperatorPartialView, iGoCompanyOriginatorPartialView, OriginatorMarketplacePartialApi } from "src/models";
import { MatSelectChange } from "@angular/material/select";
import { ObjectUtilities } from "src/shared/object.utilities";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'cx-connections-filters',
    templateUrl: 'cx-connections-filters.component.html',
    styles: [`
        :host {
            display: block;
            height: 100%;
            overflow: auto;
            padding: 0 40px 40px 40px;
            width: 100%;
        }
    `]
})
export class CXConnectionsFiltersComponent implements OnInit {
    @Input() public class: string = '';
    @Input() public set filters(value: GetCXConnectionsFilters) {
        this._filters = new GetCXConnectionsFilters(value);
        this.filtersCopy = ObjectUtilities.deepCopy(this.filters);
    };
    @Output() public update: EventEmitter<GetCXConnectionsFilters> = new EventEmitter<GetCXConnectionsFilters>();
    public get filters(): GetCXConnectionsFilters {
        return this._filters;
    };

    public currentPartnerType: string = '';
    public filtersCopy: GetCXConnectionsFilters = new GetCXConnectionsFilters();
    public form: UntypedFormGroup = null;
    public marketplacesFormControl: UntypedFormControl = new UntypedFormControl();
    public operatorsFormControl: UntypedFormControl = new UntypedFormControl();
    public originatorsFormControl: UntypedFormControl = new UntypedFormControl();
    public selectedMarketplaceNames: string[];
    public selectedOperatorNames: string[];
    public selectedOriginatorNames: string[];

    //Multiselect Inputs
    public allMarketplaces: string[] = [];
    public allOperators: iGoCompanyOperatorPartialView[] = [];
    public allOriginators: iGoCompanyOriginatorPartialView[] = [];

    private _filters: GetCXConnectionsFilters;

    public constructor(
        private connectionService: ConnectionsService,
        private formBuilder: UntypedFormBuilder,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService
    ) {
        this.currentPartnerType = UserService.getPartnerTypeDetails().type;
    };

    public ngOnInit(): void {
        this.form = this.buildForm();
    };

    public initialiseFilters(): void {
        this.currentPartnerType !== 'Originator' && this.initialiseOriginatorMultiselect();
        this.currentPartnerType !== 'Operator' && this.initialiseOperatorMultiselect();
        this.initialiseMarketplacesMultiselect();
    };

    public onReset(): void {
        this.form.reset();
        this.filtersCopy.marketplaceNames = [];
        this.filtersCopy.operatorIds = [];
        this.filtersCopy.originatorIds = [];
    };

    public onSelectMarketplaces($event: MatSelectChange): void {
        this.filtersCopy.marketplaceNames = $event.value;
        this.selectedMarketplaceNames = [...this.filtersCopy.marketplaceNames];
    };

    public onSelectOperators($event: MatSelectChange): void {
        this.filtersCopy.operatorIds = $event.value;
        this.selectedOperatorNames = this.filtersCopy.operatorIds.map(i => this.allOperators.find(x => x.id == i)?.name);
    };

    public onSelectOriginators($event: MatSelectChange): void {
        this.filtersCopy.originatorIds = $event.value;
        this.selectedOriginatorNames = this.filtersCopy.originatorIds.map(i => this.allOriginators.find(x => x.id == i)?.name);
    };

    public onUpdate(): void {
        if (this.form.valid) {
            this.update.emit(this.filtersCopy);
        } else {
            this.sharedModalsService.showFormValidationErrorsModal(this.translateService.instant('global.side_panel.filters'), this.form);
        };
    };

    private buildForm(): UntypedFormGroup {
        return this.formBuilder.group({
            'igo_portal.network.filters.marketplaces': this.marketplacesFormControl,
            'igo_portal.network.filters.operators': this.operatorsFormControl,
            'igo_portal.network.filters.originators': this.originatorsFormControl,
        });
    };

    private initialiseMarketplacesMultiselect(): void {
        this.allMarketplaces = ArrayUtilities.sortAlphabetically(this.connectionService.getAllCXMarketplaces());
        this.selectedMarketplaceNames = [...this.filtersCopy.marketplaceNames];
    };

    private initialiseOperatorMultiselect(): void {
        this.allOperators = ArrayUtilities.sortAlphabetically(this.connectionService.getAllCXOperators(), this.class === 'igo' ? 'displayName' : 'name');
        this.selectedOperatorNames = this.filtersCopy.operatorIds.map(i => this.allOperators.find(x => x.id == i)?.name);
    };

    private initialiseOriginatorMultiselect(): void {
        this.allOriginators = ArrayUtilities.sortAlphabetically(this.connectionService.getAllCXOriginators(), this.class === 'igo' ? 'displayName' : 'name');
        this.selectedOriginatorNames = this.filtersCopy.originatorIds.map(i => this.allOriginators.find(x => x.id == i)?.name);
    };
};