import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { IPortalClassTypes } from 'src/interfaces/portal-class-types.interface';
import { Dictionary } from 'src/models';

@Component({
    selector: 'igo-button-group-multi',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => iGoButtonGroupMultiComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => iGoButtonGroupMultiComponent),
            multi: true
        }
    ],
    styleUrls: ['igo-button-group-multi.component.css'],
    templateUrl: './igo-button-group-multi.component.html',
})

export class iGoButtonGroupMultiComponent implements OnInit, ControlValueAccessor, Validator {
    @Input() public class: IPortalClassTypes;
    @Input() public disabled: boolean;
    @Input() public displayValue: string = 'description';
    @Input() public id: string = '';
    @Input() public key: string = 'id';
    @Input() public label: string = '';
    @Input() public options: any[] | Dictionary<any> = [];
    @Input() public required: boolean = false;
    @Output() public onSelect: EventEmitter<any> = new EventEmitter<any>();
    @Input() public set value(value: string[] | number[]) {
        this._value = value || [];
        this.onChange(value);
    };
    @Output() public onKeyup = new EventEmitter<KeyboardEvent>();

    private _value: string[] | number[];
    public get value(): string[] | number[] { return this._value; };

    public constructor() { };

    public ngOnInit(): void {
        this.options = Object.values(this.options);
    };

    public onSelectOption(option: any): void {
        if (this.disabled) return;

        const index = this.value.findIndex(i => i == option[this.key]);
        if (index >= 0) {
            this.value.splice(index, 1);
            this.writeValue(this.value);
        } else {
            this.writeValue([...this.value, option[this.key]]);
        };

        this.onSelect.emit(this.value);
    };

    public onChange = (value: string[] | number[]) => { };

    public onTouched: () => {};

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    };

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    };

    public writeValue(value: string[] | number[]): void {
        this.value = value;
    };

    public validate(control: AbstractControl): ValidationErrors | null {
        if (this.required && !this.value) {
            return { required: true };
        };
        return null;
    };
};