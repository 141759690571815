import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LocationStrategy } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { SettingsService } from "./settings.service";
import { StorageService } from "./storage.service";
import { tap } from "rxjs/operators";
import { UserService } from "./user.service";

@Injectable({ providedIn: 'root' })
export class GhostTranslationService {

    public constructor(
        private http: HttpClient,
        private locationStrategy: LocationStrategy,
        private translateService: TranslateService,
    ) { };

    public loadTranslation(languageCode: string): Observable<Object> {
        return this.http.get(this.locationStrategy.getBaseHref() + `/shared/assets/i18n/${languageCode}.json`).pipe(tap(translations => {
            this.translateService.setTranslation(languageCode, translations);
        }));
    };

    public setSystemLanguageTranslation(lang?: string): Observable<Object> {
        const languageCode = lang ? lang : SettingsService.LanguageCode;
        return this.http.get(this.locationStrategy.getBaseHref() + `/shared/assets/i18n/${languageCode}.json`).pipe(tap(translations => {
            this.translateService.setTranslation(languageCode, translations);
            this.translateService.use(languageCode);
        }));
    };

    public setDefaultLanguageTranslation(): Observable<Object> {
        return this.http.get(this.locationStrategy.getBaseHref() + '/shared/assets/i18n/en-GB.json').pipe(tap(translations => {
            this.translateService.setTranslation('en-GB', translations);
            this.translateService.setDefaultLang('en-GB');
        }));
    };

    public setTableTranslations(): void {
        const translations = this.translateService.translations[StorageService.getItem('languageCode') || 'en-GB'];
        const defaultTranslations = this.translateService.translations[SettingsService.LanguageCode];
        for (const key in UserService.customisations) {
            const tableName: string = UserService.customisations[key].tableName;
            if (tableName) {
                UserService.customisations[key].tableName = (translations && translations[tableName]) || tableName;
            };
            for (const columnKey in UserService.customisations[key].defaultColumns) {
                let defaultName = UserService.customisations[key].defaultColumns[columnKey].name;
                UserService.customisations[key].defaultColumns[columnKey].nameOverride = (translations && translations[defaultName]) || defaultTranslations[defaultName];
            };
        };
    };
};