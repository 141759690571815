export class ChartUtilities {
    public static setActiveAdvancedPieChartLabel(selectedChartFilter: string): void {
        var elements = document.getElementsByClassName('legend-item');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].children[2].innerHTML == selectedChartFilter){
                elements[i].className += " selected-chart-label";
            } else {
                elements[i].className = "legend-item ng-star-inserted";
            };
        };
    };
};