// Modules
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { CoreModule } from '../modules/core/core.module';
import { MsalGuard, MsalBroadcastService, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalGuardConfiguration, MSAL_INTERCEPTOR_CONFIG, MsalInterceptorConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { TitleStrategy } from '@angular/router';

// Services
import { SettingsService, StorageService } from 'src/services';

// Components
import { AppComponent } from './app.component';
import { CookieConsentComponent } from './standalone/cookie-consent/cookie-consent.component';
import { PortalsComponent } from './portals/portals.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RootComponent } from '../root.component';
import { SetupUserComponent } from './setup-user/setup-user.component';
import { SidebarComponent } from 'src/modules/sidebar/sidebar.component';
import { TitleCustomStrategyService } from 'src/services/title-custom-strategy.service';
import { UserPanelComponent } from 'src/modules/user-panel/user-panel.component';

// Modals
import { CompanyOriginatorsOrOperatorsModalComponent } from './portals/modals/company-originators-or-operators/company-originators-or-operators.modal';

// Standalone Components
import { iGoColourBarComponent } from './standalone/igo-colour-bar/igo-colour-bar.component';
import { ModalHeaderComponent } from './standalone/modal-header/modal-header.component';
import { PasswordRequirementDetailsComponent } from './standalone/password-requirement-details/password-requirement-details.component';
import { StackedArrowLeftIconsComponent } from './standalone/stacked-arrow-left-icons/stacked-arrow-left-icons.component';
import { StackedArrowRightIconsComponent } from './standalone/stacked-arrow-right-icons/stacked-arrow-right-icons.component';

import { environment } from 'src/environments/environment';

export const MSALInstanceFactory = (): IPublicClientApplication => {
    return new PublicClientApplication({
        auth: {
            clientId: environment.azureClientId,
            authority: 'https://login.microsoftonline.com/autocab.dev',
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: false
        }
    });
};

export const MSALInterceptorConfigFactory = (): MsalInterceptorConfiguration => {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(environment.azureClientURI, [`${environment.azureClientURI}/IGo.Engineer`]);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
};

export const MSALGuardConfigFactory = (): MsalGuardConfiguration => {
    return {
        interactionType: InteractionType.Popup,
        authRequest: {
            scopes: [`${environment.azureClientURI}/IGo.Engineer`]
        }
    };
};

@NgModule({
    imports: [
        AppRoutingModule,
        CoreModule,
        CookieConsentComponent,
        BrowserModule,
        BrowserAnimationsModule,
        iGoColourBarComponent,
        MsalModule,
        ModalHeaderComponent,
        PasswordRequirementDetailsComponent,
        NgbNavModule,
        ReactiveFormsModule,
        SharedModule,
        StackedArrowLeftIconsComponent,
        StackedArrowRightIconsComponent
    ],
    declarations: [
        AppComponent,
        CompanyOriginatorsOrOperatorsModalComponent,
        PortalsComponent,
        ResetPasswordComponent,
        RootComponent,
        SetupUserComponent,
        SidebarComponent,
        UserPanelComponent
    ],
    providers: [
        {
            provide: 'canActivateOriginator',
            useValue: (): boolean => Boolean(StorageService.getItem('originator'))
        },
        {
            provide: 'canActivateOperator',
            useValue: (): boolean => Boolean(StorageService.getItem('operator'))
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        {
            provide: TitleStrategy,
            useClass: TitleCustomStrategyService
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        { provide: LOCALE_ID, deps: [SettingsService], useFactory: (settingsService: SettingsService) => settingsService.getLanguageCode() }
    ],
    bootstrap: [RootComponent, MsalRedirectComponent]
})
export class AppModule { };