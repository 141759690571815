import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'checkbox-cell',
    template: `<mat-checkbox [ngModel]="checked" [indeterminate]="!checked" disabled></mat-checkbox>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [`
        :host { display: flex; flex-flow: column; height: 100%; justify-content: center; padding: 0 20px; width: 100%; }
        :host:not(.gvs-action-cell) { opacity: .7; }
    `]
})
export class CheckBoxCellTplComponent {
    @Input() public set row(item: any) {
        if (item) {
            this.checked = Boolean(item[this.prop]);
            this.cd.detectChanges();
        };
    };
    public checked: boolean = false;
    public prop: string = '';
    public constructor(private cd: ChangeDetectorRef) { };
};