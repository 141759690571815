import { Component, Input, AfterViewInit, ViewChild } from "@angular/core";
import { AdminSettingsService, ITimezone, SharedModalsService, TypesService, UserService } from "src/services";
import { CommonType, CompanySettingsApi, CultureType, OriginatorSettingsApi } from "src/models";
import { HttpErrorResponse } from "@angular/common/http";
import { IPortalClassTypes } from "src/interfaces/portal-class-types.interface";
import { MatSelectChange } from "@angular/material/select";
import { NgForm } from "@angular/forms";
import { ObjectUtilities } from "src/shared/object.utilities";
import { TranslateService } from "@ngx-translate/core";
import { lastValueFrom } from "rxjs";

@Component({
    selector: 'admin-settings',
    templateUrl: './admin-settings.component.html'
})

export class AdminSettingsComponent implements AfterViewInit {
    @ViewChild('form') public form: NgForm;
    @Input() public class: IPortalClassTypes = 'default';
    public companySettings: CompanySettingsApi = null;
    public companySettingsCopy: CompanySettingsApi = new CompanySettingsApi();
    public cultures: CultureType[] = [];
    public currentPartnerType: string = '';
    public editing: boolean = false;
    public getSettingsUrl: string = '';
    public hasPermission: boolean = true;
    public invoiceFrequencies: string[] = [];
    public settings: OriginatorSettingsApi = null;
    public settingsCopy: OriginatorSettingsApi = new OriginatorSettingsApi();
    public timezones: Array<ITimezone> = [];
    public weekDaysWithDayNumber: CommonType[] = [];

    public noDataChange = (): boolean => this.hasPermission ? ObjectUtilities.equal(this.settings, this.settingsCopy) && ObjectUtilities.equal(this.companySettings, this.companySettingsCopy) : true;

    public constructor(
        private adminSettingsService: AdminSettingsService,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService,
        private typesService: TypesService,
        private userService: UserService
    ) {
        this.currentPartnerType = UserService.getPartnerTypeDetails().type;
        this.timezones = AdminSettingsService.timezonesTypes;
    };

    public async ngAfterViewInit(): Promise<void> {
        this.getSettingsUrl = this.userService.getUserHalLink('GetOriginatorSettings')?.href;
        if (!this.getSettingsUrl) {
            this.hasPermission = false;
            return;
        };

        await Promise.all([
            lastValueFrom(this.typesService.getCultureTypesObservable()),
            lastValueFrom(this.typesService.getInvoiceFrequenciesObservable())
        ]);
        this.cultures = this.typesService.CultureTypes;
        this.invoiceFrequencies = this.typesService.InvoiceFrequencies;
        this.weekDaysWithDayNumber = this.typesService.DayOfWeekWithDayNumber;
        this.recalculateData();
    };

    public initialiseData = (originatorSettings: OriginatorSettingsApi, companySettings: CompanySettingsApi): void => {
        this.editing = false;
        this.settings = originatorSettings;
        this.settingsCopy = ObjectUtilities.deepCopy(this.settings);
        this.companySettings = companySettings;
        this.companySettingsCopy = ObjectUtilities.deepCopy(this.companySettings);
    };

    public async onSave(): Promise<void> {
        if (!this.editing) {
            this.form.ngSubmit.emit();
            if (this.form.valid) {
                this.editing = true;
                try {
                    const requests: Promise<any>[] = [lastValueFrom(this.adminSettingsService.updateCompanySettingsAsync(this.companySettingsCopy))];
                    this.class !== 'igo' && requests.push(lastValueFrom(this.adminSettingsService.updateOriginatorSettingsAsync(this.settingsCopy)));
                    await Promise.all(requests);
                    this.recalculateData();
                } catch (error) {
                    this.onFailure(error);
                };
            } else {
                this.sharedModalsService.showFormValidationErrorsModal(this.translateService.instant('igo_portal.admin.settings'), this.form, { windowClass: this.class });
            };
        };
    };

    public onSelectInvoiceFrequency($event: MatSelectChange): void {
        this.settingsCopy.invoiceFrequency = $event && $event.value;
        this.settingsCopy.invoiceDay = 1;
    };

    public onSelectCulture($event: MatSelectChange): void {
        this.settingsCopy.culture = $event && $event.value;
    };

    public onSelectTimezone($event: MatSelectChange): void {
        this.settingsCopy.timeZone = $event && $event.value;
    };

    public onSelectWeekDay($event: MatSelectChange): void {
        this.settingsCopy.invoiceDay = $event && $event.value;
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.editing = false;
        this.sharedModalsService.showServerValidationErrorsModal(res, this.translateService.instant('igo_portal.admin.settings'));
    };

    private recalculateData = async (): Promise<void> => {
        try {
            const originatorSettings = this.getSettingsUrl && await lastValueFrom(this.adminSettingsService.getOriginatorSettingsObservable(this.getSettingsUrl));
            const companySettings = await lastValueFrom(this.adminSettingsService.getCompanySettingsObservable());
            this.initialiseData(originatorSettings, companySettings);
        } catch (error) {
            this.onFailure(error);
        };
    };
};