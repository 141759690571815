// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
    bottom: 30px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 100000;
}

.cookie-banner {
    background-color: rgb(248 248 248 / 90%);
    border-radius: 20px;
    margin: auto;
    max-width: 50%;
    padding: 30px;
    width: -moz-fit-content;
    width: fit-content;
}

.cookie-banner h4 {
    color: initial;
}

.cookie-banner a {
    color: initial;
    text-decoration: underline !important;
}

.cookie-grid {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.cookie-grid .cookie-item {
    display: flex;
    flex-direction: column;
}

.cookie-grid .cookie-item .cookie-name {
    margin-bottom: 0;
    text-wrap: nowrap;
}

.cookie-footer {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
}

.cookie-footer .expand {
    align-items: center;
    cursor: pointer;
    display: flex;
}

.cookie-footer .expand p {
    font-family: ZonaProBold;
}

.cookie-footer .buttons {
    display: flex;
    gap: 10px;
}

@media screen and (max-width: 1100px) {
    .cookie-banner {
        max-width: 95%;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/standalone/cookie-consent/cookie-consent.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,OAAO;IACP,kBAAkB;IAClB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,wCAAwC;IACxC,mBAAmB;IACnB,YAAY;IACZ,cAAc;IACd,aAAa;IACb,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,qCAAqC;AACzC;;AAEA;IACI,aAAa;IACb,SAAS;IACT,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[":host {\n    bottom: 30px;\n    left: 0;\n    position: absolute;\n    width: 100%;\n    z-index: 100000;\n}\n\n.cookie-banner {\n    background-color: rgb(248 248 248 / 90%);\n    border-radius: 20px;\n    margin: auto;\n    max-width: 50%;\n    padding: 30px;\n    width: fit-content;\n}\n\n.cookie-banner h4 {\n    color: initial;\n}\n\n.cookie-banner a {\n    color: initial;\n    text-decoration: underline !important;\n}\n\n.cookie-grid {\n    display: flex;\n    gap: 20px;\n    justify-content: space-between;\n}\n\n.cookie-grid .cookie-item {\n    display: flex;\n    flex-direction: column;\n}\n\n.cookie-grid .cookie-item .cookie-name {\n    margin-bottom: 0;\n    text-wrap: nowrap;\n}\n\n.cookie-footer {\n    align-items: flex-end;\n    display: flex;\n    justify-content: space-between;\n}\n\n.cookie-footer .expand {\n    align-items: center;\n    cursor: pointer;\n    display: flex;\n}\n\n.cookie-footer .expand p {\n    font-family: ZonaProBold;\n}\n\n.cookie-footer .buttons {\n    display: flex;\n    gap: 10px;\n}\n\n@media screen and (max-width: 1100px) {\n    .cookie-banner {\n        max-width: 95%;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
