import { AfterViewInit, Component, Input, ViewChild } from "@angular/core";
import { ActivityDisputesChartsComponent } from "./activity-disputes-charts/activity-disputes-charts.component";
import { ActivityDisputesTableCustomisation } from "./activity-disputes-table.customisation";
import { ActivityService, ChartsService, FilterService, SharedModalsService, UserService } from "src/services";
import { BookingView, DisputeView, GetAllDisputesResponseView } from '../../../models';
import { DateUtilities } from "src/shared/date.utilities";
import { GhostVSTableFilterComponent } from "src/shared/components/ghost-vs-table-filter/ghost-vs-table-filter.component";
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { IPortalClassTypes } from "src/interfaces/portal-class-types.interface";
import { ITableFooterItem } from 'src/shared/components/ghost-vs-table-footer/ghost-vs-table-footer.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { switchMap } from 'rxjs/operators';
import { Subject, lastValueFrom } from 'rxjs';
import { TableComponent, TableCustomisation } from "@autocab/ghost-vs-table";
import { TranslateService } from "@ngx-translate/core";

const dateFrom = new Date();
dateFrom.setMonth(dateFrom.getMonth() - 6);

@Component({
    selector: 'activity-disputes',
    templateUrl: './activity-disputes.component.html'
})

export class ActivityDisputesComponent implements AfterViewInit {
    @ViewChild(ActivityDisputesChartsComponent) public activityDisputesChartsComponent: ActivityDisputesChartsComponent;
    @ViewChild(GhostVSTableFilterComponent) public tableFilter: GhostVSTableFilterComponent;
    @ViewChild(TableComponent) public table: TableComponent;
    @Input() public class: IPortalClassTypes = 'default';
    @Input() public set disputeId(id: string) {
        !!id && !this.disputeModalRef?.componentInstance && this.loadDisputeById(id);
    };

    public activityDisputesResult: GetAllDisputesResponseView = null;
    public activityDisputesTableCustomisation: TableCustomisation<DisputeView> = ActivityDisputesTableCustomisation;
    public filterSearchTerm: string = '';
    public getDisputesUrl: string = '';
    public getDisputesUrlQueryParams: string = '';
    public hasPermission: boolean = true;
    public loading: boolean = false;
    public loadingModalData: boolean = false;
    public rows: DisputeView[] = [];
    public showCharts: boolean = true;
    public tempRows: DisputeView[] = [];
    public tableFooterItems: Array<ITableFooterItem>;

    //Payload
    public fromDate: Date;
    public toDate: Date;

    private onSearch$: Subject<DisputeView[]> = new Subject<DisputeView[]>();
    private disputeModalRef: NgbModalRef;

    public constructor(
        private activityService: ActivityService,
        private chartsService: ChartsService,
        private filterService: FilterService,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService,
        private userService: UserService
    ) { };

    public ngAfterViewInit(): void {
        this.getDisputesUrl = this.userService.getUserHalLink('GetDisputes')?.href;
        if (!this.getDisputesUrl) {
            this.hasPermission = false;
            return;
        };
        this.activityDisputesChartsComponent.activityDisputesLastMessageChartConfig.data = [];
        this.activityDisputesChartsComponent.activityDisputesStatusChartConfig.data = [];
        this.fromDate = DateUtilities.getMidnight(dateFrom);
        this.toDate = DateUtilities.getEndOfDay();
        this.setUrlQueryParams();

        this.onSearch$.pipe(switchMap(() => {
            this.loading = true;
            return this.activityService.getDisputesObservable(this.getDisputesUrl + '?' + this.getDisputesUrlQueryParams);
        })).subscribe({
            next: this.onInitialiseData,
            error: this.onFailure
        });

        setTimeout(() => {
            this.onSearch$.next(null);
        }, 100);

        let showCharts = this.chartsService.getShowPieChartsValue("activity-disputes");
        if (showCharts != undefined) {
            this.showCharts = showCharts;
        }
    };

    public getRowStyles = (row: DisputeView): string => {
        // Apply bold text if there is a last message and it was not sent by the originator/operator that the current user belongs to
        return row.lastMessage ? row.lastMessage.sentByCurrentUserPartner ? '' : 'font-family: \'ZonaProBold\' !important' : '';
    };

    public onChangeDateRange({ fromDate, toDate }): void {
        this.fromDate = fromDate ? DateUtilities.getMidnight(fromDate) : null;
        this.toDate = toDate ? DateUtilities.getEndOfDay(toDate) : null;
        this.setUrlQueryParams();
        this.fromDate && this.toDate && this.onSearch$.next(null);
    };

    public onFilterChange($event?: string): void {
        const rows = this.activityDisputesChartsComponent.selected.data.length ? this.activityDisputesChartsComponent.selected.data : this.tempRows;
        const filteredRows = this.filterService.filterGhostVSTableResults(false, this.filterSearchTerm, rows, this.activityDisputesTableCustomisation);
        this.table.setRowsCollection(filteredRows, filteredRows, false, 0).then(() => this.rows = filteredRows);
    };

    public onRowsChange(rows: DisputeView[]): void {
        this.rows = rows;
        this.filterSearchTerm && this.activityDisputesChartsComponent.updateFilter();
    };

    public async onSelect(): Promise<void> {
        if (!this.loadingModalData) {
            this.loadingModalData = true;
            try {
                this.loadDisputeById(this.activityDisputesTableCustomisation.selected.id);
            } catch (error) {
                this.onFailure(error);
            };
        };
    };

    public onToggleShowCharts(event: MatSlideToggleChange): void {
        this.chartsService.setShowPieChartsValue({ type: 'activity-disputes', value: event.checked });
    };

    private setUrlQueryParams(): void {
        this.getDisputesUrlQueryParams = new HttpParams()
            .set('from', DateUtilities.getMidnightUTC(this.fromDate).toISOString())
            .set('to', DateUtilities.getEndOfDayUTC(this.toDate).toISOString())
            .toString();
    }

    private async loadDisputeById(disputeId: string): Promise<void> {
        try {
            let dispute: DisputeView = await lastValueFrom(this.activityService.getDisputeByIdObservable(disputeId));
            const booking: BookingView = await lastValueFrom(this.activityService.getBookingByIdObservable(dispute.bookingId));
            dispute.booking = booking;
            dispute && this.showDisputeModal(dispute);
        } catch (error) {
            this.onFailure(error);
        };
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.loadingModalData = false;
        this.loading = false;
        this.sharedModalsService.showServerValidationErrorsModal(res, this.translateService.instant('igo_portal.activity.disputes'));
    };

    private onInitialiseData = (): void => {
        this.loading = false;
        this.activityDisputesResult = this.activityService.getDisputes();
        this.tempRows = [...this.activityDisputesResult?.disputes];
        this.activityService.filterDisputes(this.tempRows);
        this.activityDisputesChartsComponent.recalculateCharts();
        this.tableFooterItems = [{ key: 'global.tables.igo_portal.activity.disputes.total_disputes', description: this.tempRows.length }];
    };

    private showDisputeModal = (dispute: DisputeView) => {
        this.loadingModalData = false;
        this.disputeModalRef = this.sharedModalsService.showActivityDisputeModal(dispute, true, { windowClass: this.class });
        this.disputeModalRef.result.then((res: DisputeView) => this.updateRowById(res)).catch((res: DisputeView) => this.updateRowById(res));
    };

    private updateRowById(res: DisputeView): void {
        if (res instanceof DisputeView) {
            //Editing the Dispute returns the full dispute object
            if (this.rows.find(i => i.id == res.id)) {
                this.table.updateRow(res);
                Object.assign(this.tempRows.find(i => i.id == res.id), res);
            };
        };
        this.rows = [...this.tempRows];
        this.activityService.filterDisputes(this.tempRows);
        this.activityDisputesChartsComponent.recalculateCharts();
    };
};