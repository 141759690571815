import { Injectable } from '@angular/core';
import { ArrayUtilities } from 'src/shared/array.utilities';
import { Endpoints } from 'src/shared/endpoints';
import { GetAllRolesResponseApi, GetAllRolesResponseView, PermissionItemView, UserRoleApi, UserRolePayload, UserRoleView } from 'src/models';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { PermissionsService } from './permissions.service';
import { RequestService } from './request.service';

@Injectable({ providedIn: 'root' })
export class RolesService {
    public roles: GetAllRolesResponseView = null;

    public constructor(
        private permissionsService: PermissionsService,
        private http?: RequestService,
    ) { };

    public getRoles = (): GetAllRolesResponseView => this.roles;

    public createRoleAsync(url: string, role: UserRoleApi): Observable<UserRoleView> {
        return this.http.authenticatedPost<UserRoleApi>(url, new UserRolePayload(role), {}, 'global.request.creating_role').pipe(
            map(this.mapRoleToViewModel)
        );
    };

    public deleteRoleAsync(role: UserRoleView): Observable<string> {
        return this.http.authenticatedDelete<void>(role._links.DeleteRole.href, {}, 'global.request.deleting_role').pipe(
            map(res => role.id)
        );
    };

    public getRolesObservable(url?: string): Observable<void> {
        return this.permissionsService.getPermissionsObservable().pipe(
            switchMap(permissions => {
                return this.http.authenticatedGet<GetAllRolesResponseApi>(url || (Endpoints.API_URL + Endpoints.ROLES), {}, 'global.request.loading_roles').pipe(
                    map(res => {
                        const rolesView = res.roles.map(this.mapRoleToViewModel);
                        this.roles = new GetAllRolesResponseView({ roles: ArrayUtilities.sortAlphabetically(rolesView, 'name'), _links: res._links });
                    }),
                    catchError((err) => {
                        this.roles = null;
                        return throwError(err);
                    })
                );
            })
        );
    };

    public mapRoleToViewModel = (role: UserRoleApi): UserRoleView => {
        const allPermissions: PermissionItemView[] = this.permissionsService.getIndividualPermissions();
        let roleView = new UserRoleView(role as UserRoleView);
        roleView.totalPermissions = `${roleView.permissions.length}/${allPermissions.length}`;
        return roleView;
    };

    public updateRoleAsync(role: UserRoleView): Observable<UserRoleView> {
        return this.http.authenticatedPut<UserRoleApi>(role._links.UpdateRole.href, new UserRolePayload(role), {}, 'global.request.updating_role').pipe(
            map(this.mapRoleToViewModel)
        );
    };
};