import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'toggle-switch',
    styleUrls: [
        'toggle-switch.component.css'
    ],
    templateUrl: './toggle-switch.component.html'
})

export class ToggleSwitchComponent {
    @Input() public disabled: boolean;
    @Input() public model;
    @Input() public label;
    @Input() public noLabel: boolean = false;
    @Input() public key;
    @Input() public required;
    @Input() public tooltipText;
    @Input() public tooltipPlacement = 'top';
    @Input() public viewCustomisation;
    @Output() private modelChange: EventEmitter<any> = new EventEmitter<any>();

    public onChange(): void {
        this.model = !this.model;
        this.modelChange.next(this.model);
    };
};