import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AddressDetailsApi, CountryType } from '../../../models';
import { MatSelectChange } from '@angular/material/select';
import { NgForm } from '@angular/forms';
import { TypesService } from 'src/services';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'address-details-form',
    templateUrl: './address-details-form.component.html'
})

export class AddressDetailsFormComponent implements OnInit {
    @ViewChild('form') public form: NgForm;
    @Input() public address: AddressDetailsApi;
    @Input() public readonly: boolean = false;
    public countries: CountryType[] = [];

    public constructor(private typesService: TypesService) { };

    public async ngOnInit(): Promise<void> {
        await lastValueFrom(this.typesService.getCountryTypesObservable());
        this.countries = this.typesService.CountriesTypes;
    };

    public onSelectCountry($event: MatSelectChange): void {
        if (!this.readonly) {
            this.address.country = $event && $event.value;
        };
    };
};