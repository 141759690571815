import { DisputeLastMessageCellTplComponent } from 'src/shared/table-cell-templates/dispute-last-message-cell-template.component';
import { DisputeStatusCellTplComponent } from 'src/shared/table-cell-templates/dispute-status-cell-template.component';
import { DisputeView } from '../../../models';
import { TableCustomisation } from '@autocab/ghost-vs-table';
import { UserService } from '../../../services';

export const ActivityDisputesTableCustomisation: TableCustomisation<DisputeView> = {
    customisationKey: 'ActivityDisputesTableCustomisation',
    tableName: 'global.tables.igo_portal.activity.disputes.table_title',
    defaultOrderBy: ['-lastMessage.time'],
    defaultColumns: {
        raisedBy: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.raised_by',
            orderBy: 'raisedBy.partnerName',
            prop: 'raisedBy.partnerName',
            show: true
        },
        respondent: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.respondent',
            orderBy: 'respondent.name',
            prop: 'respondent.name',
            show: true
        },
        reason: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.reason',
            orderBy: 'reason',
            prop: 'reason',
            show: true
        },
        lastMessage: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.last_message',
            orderBy: 'lastMessage.time',
            prop: 'lastMessage.messageSenderAndTime',
            template: DisputeLastMessageCellTplComponent,
            show: true
        },
        status: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.status',
            orderBy: 'statusDateTime',
            prop: 'status',
            template: DisputeStatusCellTplComponent,
            show: true
        },
        raisedDateTime: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.raised_date',
            orderBy: 'raisedDateTimeView',
            prop: 'raisedDateTimeView',
            show: false
        },
        operatorName: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.operator_name',
            orderBy: 'operator.name',
            prop: 'operator.name',
            show: false
        },
        originatorName: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.originator_name',
            orderBy: 'originator.name',
            prop: 'originator.name',
            show: false
        },
        resolved: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.resolved',
            orderBy: 'resolved',
            prop: 'resolved',
            show: false
        },
        resolvedBy: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.resolved_by',
            orderBy: 'resolvedBy.partnerName',
            prop: 'resolvedBy.partnerName',
            show: false
        },
        resolvedByUser: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.resolved_by_user',
            orderBy: 'resolvedBy.userName',
            prop: 'resolvedBy.userName',
            show: false
        },
        resolvedComment: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.resolved_comment',
            orderBy: 'resolvedComment',
            prop: 'resolvedComment',
            show: false
        },
        resolvedDateTime: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.resolved_date',
            orderBy: 'resolvedDateTimeView',
            prop: 'resolvedDateTimeView',
            show: false
        },
        escalated: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.escalated',
            orderBy: 'escalated',
            prop: 'escalated',
            show: false
        },
        escalatedBy: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.escalated_by',
            orderBy: 'escalatedBy.partnerName',
            prop: 'escalatedBy.partnerName',
            show: false
        },
        escalatedByUser: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.escalated_by_user',
            orderBy: 'escalatedBy.userName',
            prop: 'escalatedBy.userName',
            show: false
        },
        escalatedComment: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.escalated_comment',
            orderBy: 'escalatedComment',
            prop: 'escalatedComment',
            show: false
        },
        escalatedDateTime: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.escalated_date',
            orderBy: 'escalatedDateTimeView',
            prop: 'escalatedDateTimeView',
            show: false
        },
        totalMessages: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.total_messages',
            orderBy: 'disputeMessages.length',
            prop: 'disputeMessages.length',
            show: false
        },

    }
};
UserService.registerCustomisation(ActivityDisputesTableCustomisation);