// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.option {
    align-items: center;
    border-bottom: 1px solid #e2e2e2;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    -webkit-user-select: none;
            user-select: none;
}

.option span {
    transition: all 0.2s ease-in-out;
}

.option:hover .stacked-icon {
    transform: translateX(10px);
    transition: all 0.3s ease;
}

::ng-deep .option:hover stacked-arrow-right-icons.stacked-icons-wrapper span.stacked-icons-wrapper i.stacked-icon.fa-arrow-right {
    transform: translateX(10px);
    transition: all 0.3s ease;
}`, "",{"version":3,"sources":["webpack://./src/app/portals/modals/company-originators-or-operators/company-originators-or-operators-modal.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gCAAgC;IAChC,eAAe;IACf,aAAa;IACb,8BAA8B;IAC9B,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,2BAA2B;IAC3B,yBAAyB;AAC7B;;AAEA;IACI,2BAA2B;IAC3B,yBAAyB;AAC7B","sourcesContent":[".option {\n    align-items: center;\n    border-bottom: 1px solid #e2e2e2;\n    cursor: pointer;\n    display: flex;\n    justify-content: space-between;\n    padding: 10px 0;\n    user-select: none;\n}\n\n.option span {\n    transition: all 0.2s ease-in-out;\n}\n\n.option:hover .stacked-icon {\n    transform: translateX(10px);\n    transition: all 0.3s ease;\n}\n\n::ng-deep .option:hover stacked-arrow-right-icons.stacked-icons-wrapper span.stacked-icons-wrapper i.stacked-icon.fa-arrow-right {\n    transform: translateX(10px);\n    transition: all 0.3s ease;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
