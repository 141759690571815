import { Injectable } from '@angular/core';
import { BookingStatusesResponseApi, CommonType, CountryType, CultureType, LookupType } from '../models/type.model';
import { Endpoints } from '../shared/endpoints';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RequestService } from './request.service';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class TypesService {
    public AddressTypes: LookupType[] = [];
    public AddressFormatTypes: LookupType[] = [];
    public AddressSearchSourceTypes: LookupType[] = [];
    public CountriesTypes: CountryType[] = [];
    public CultureTypes: CultureType[] = [];
    public InvoiceFrequencies: string[] = [];
    public MapTypes: LookupType[] = [];

    public DayOfWeekTypes: LookupType[] = [
        { type: 'Monday', description: 'Monday' },
        { type: 'Tuesday', description: 'Tuesday' },
        { type: 'Wednesday', description: 'Wednesday' },
        { type: 'Thursday', description: 'Thursday' },
        { type: 'Friday', description: 'Friday' },
        { type: 'Saturday', description: 'Saturday' },
        { type: 'Sunday', description: 'Sunday' },
    ];

    public DayOfWeekWithDayNumber: CommonType[] = [
        { id: 1, name: 'Monday' },
        { id: 2, name: 'Tuesday' },
        { id: 3, name: 'Wednesday' },
        { id: 4, name: 'Thursday' },
        { id: 5, name: 'Friday' },
        { id: 6, name: 'Saturday' },
        { id: 7, name: 'Sunday' },
    ];

    public static BookingsDateRangeTypes: LookupType[] = [
        { description: "Today", type: "today", },
        { description: "This week", type: "thisWeek", },
        { description: "Previous week", type: "previousWeek", },
        { description: "Last 7 days", type: "last7Days", },
        { description: "This month", type: "thisMonth", },
        { description: "Previous month", type: "previousMonth", },
        { description: "Last 30 days", type: "last30Days", },
        { description: "Last 3 months", type: "last3Months", },
        { description: "Last 12 months", type: "last12Months", },
        { description: "Custom", type: "custom", },
    ];

    public Months: LookupType[] = [
        { type: 'January', description: 'January' },
        { type: 'February', description: 'February' },
        { type: 'March', description: 'March' },
        { type: 'April', description: 'April' },
        { type: 'May', description: 'May' },
        { type: 'June', description: 'June' },
        { type: 'July', description: 'July' },
        { type: 'August', description: 'August' },
        { type: 'September', description: 'September' },
        { type: 'October', description: 'October' },
        { type: 'November', description: 'November' },
        { type: 'December', description: 'December' },
    ];

    public Occurrences: LookupType[] = [
        { type: 'First', description: 'First' },
        { type: 'Second', description: 'Second' },
        { type: 'Third', description: 'Third' },
        { type: 'Fourth', description: 'Fourth' },
        { type: 'Last', description: 'Last' }
    ];

    public constructor(
        private http: RequestService
    ) { };

    public getAddressTypesObservable(): Observable<void> {
        const cultureCode = StorageService.getItem('languageCode');
        return this.http.authenticatedGet<LookupType[]>(Endpoints.API_URL + Endpoints.TYPES._ADDRESS_TYPES + (cultureCode ? '?cultureCode=' + cultureCode : '')).pipe(
            map(types => {
                this.AddressTypes = types.map((type) => {
                    return new LookupType(type);
                });
            })
        );
    };

    public getCountryTypesObservable(): Observable<void> {
        return this.http.authenticatedGet<CountryType[]>(Endpoints.API_URL + Endpoints.TYPES._COUNTRIES, {}, 'global.request.loading_countries').pipe(
            map(res => {
                this.CountriesTypes = res.map(type => new CountryType(type));
            })
        );
    };

    public getBookingStatusesObservable(): Observable<BookingStatusesResponseApi[]> {
        return this.http.authenticatedGet<BookingStatusesResponseApi[]>(Endpoints.API_URL + Endpoints.TYPES._BOOKING_STATUSES, {}, 'global.request.loading_statuses');
    };

    public getCultureTypesObservable(): Observable<void> {
        return this.http.authenticatedGet<{ cultures: CultureType[]; }>(Endpoints.API_URL + Endpoints.TYPES._CULTURES, {}, 'global.request.loading_cultures').pipe(
            map(res => {
                this.CultureTypes = res.cultures.map(type => new CultureType(type));
            })
        );
    };

    public getInvoiceFrequenciesObservable(): Observable<void> {
        return this.http.authenticatedGet<string[]>(Endpoints.API_URL + Endpoints.TYPES._INVOICE_FREQUENCIES, {}, 'global.request.loading_invoice_frequencies').pipe(
            map(res => {
                this.InvoiceFrequencies = res;
            })
        );
    };

    public getMapTypesObservable(): Observable<void> {
        return this.http.authenticatedGet<LookupType[]>(Endpoints.API_URL + Endpoints.TYPES._MAP_TYPES).pipe(
            map(types => {
                this.MapTypes = types.map((type) => {
                    return new LookupType(type);
                });
            })
        );
    };
};
