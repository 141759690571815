// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body .confirmation-message {
    min-height: 80px;
    width: 100%;
    padding: 30px;
}`, "",{"version":3,"sources":["webpack://./src/modules/shared-modals/modals/unsaved-changes/unsaved-changes.modal.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;IACX,aAAa;AACjB","sourcesContent":[".modal-body .confirmation-message {\n    min-height: 80px;\n    width: 100%;\n    padding: 30px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
