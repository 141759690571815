import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ToastStylesEnum } from "src/enums/toast-styles.enum";

@Injectable({ providedIn: 'root' })
export class ToastService {
    public updateToast: Subject<{ message: string, style: string, timerMs: number; }> = new Subject();
    private timeout: any;

    public setToastMessage(message: string, style: string = ToastStylesEnum.SUCCESS, timerMs: number = 2000): void {
        this.timeout && clearTimeout(this.timeout);
        this.updateToast.next({ message, style, timerMs });
        this.timeout = setTimeout(() => {
            this.updateToast.next({ message: null, style, timerMs });
            clearTimeout(this.timeout);
        }, timerMs);
    };
}