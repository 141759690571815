import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class SnackbarService {
    private openTimeSeconds: number = 2;

    public constructor(
        private snackBar: MatSnackBar,
        private translateService: TranslateService
    ) { };

    public showSnackbar(title: string, openTimeSeconds?: number): void {
        const snackBarRef = this.snackBar.open(this.translateService.instant(title));
        setTimeout(() => {
            snackBarRef.dismiss();
        }, (openTimeSeconds || this.openTimeSeconds) * 1000);
    };
};