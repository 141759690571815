import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BookingView } from 'src/models';

@Component({
    selector: 'booking-status-cell',
    template: `<div>
        <span class="status-card {{class}}">{{status}}</span>
    </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [`
        :host { display: flex; flex-flow: column; height: 100%; justify-content: center; padding: 0 20px; width: 100%; }
        .status-card { border-radius: 5px; color: white; padding: 5px; }
    `]
})
export class BookingStatusCellTplComponent {
    @Input() public set row(item: BookingView) {
        if (item) {
            this.status = item.statusView.status;
            this.class = item.statusView.class;
            this.cd.detectChanges();
        };
    };
    public class: string = '';
    public status: string = '';
    public constructor(
        private cd: ChangeDetectorRef,
    ) { };
};