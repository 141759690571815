import { Injectable } from "@angular/core";
import { TableComponent } from "@autocab/ghost-vs-table";

@Injectable({ providedIn: 'root' })
export class GhostVSTableService {
    public constructor() { };

    public handleRowDelete<T>(res: string, table: TableComponent, rows: T[], tempRows: T[], key: string = 'id'): void {
        const row = rows.find(i => i[key] == res);
        if (row) {
            table.deleteRow(row);
            tempRows.splice(tempRows.findIndex(i => i[key] == row[key]), 1);
        };
    };
};