import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AdminUsersTableCustomisation } from "./admin-users-table.customisation";
import { CellClickEvent, TableComponent, TableCustomisation } from "@autocab/ghost-vs-table";
import { FilterService, GhostVSTableService, RolesService, SharedModalsService, UserService, UsersService } from "src/services";
import { GetAllUsersResponseView, iGoUserView } from '../../../models';
import { GhostVSTableFilterComponent } from "src/shared/components/ghost-vs-table-filter/ghost-vs-table-filter.component";
import { HttpErrorResponse } from "@angular/common/http";
import { IPortalClassTypes } from "src/interfaces/portal-class-types.interface";
import { ITableFooterItem } from './../ghost-vs-table-footer/ghost-vs-table-footer.component';
import { Subscription, lastValueFrom } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'admin-users',
    templateUrl: './admin-users.component.html'
})

export class AdminUsersComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(GhostVSTableFilterComponent) public tableFilter: GhostVSTableFilterComponent;
    @ViewChild(TableComponent) public table: TableComponent;
    @Input() public class: IPortalClassTypes = 'default';
    public adminUsersTableCustomisation: TableCustomisation<iGoUserView> = AdminUsersTableCustomisation;
    public filterSearchTerm: string = '';
    public getUsersUrl: string = '';
    public hasPermission: boolean = true;
    public loading: boolean = false;
    public loadingModalData: boolean = false;
    public rows: iGoUserView[] = [];
    public tempRows: iGoUserView[] = [];
    public tableFooterItems: Array<ITableFooterItem>;
    public usersResult: GetAllUsersResponseView = null;

    private clickEventSubscription: Subscription;

    public constructor(
        private filterService: FilterService,
        private ghostVSTableService: GhostVSTableService,
        private rolesService: RolesService,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService,
        private userService: UserService,
        private usersService: UsersService
    ) { };

    public ngOnInit(): void {
        this.getUsersUrl = this.userService.getUserHalLink('GetAllUsers')?.href;
        this.hasPermission = !!this.getUsersUrl;
        this.getUsersUrl && this.recalculateData();
    };

    public ngAfterViewInit(): void {
        this.table?.resetTableCustomisation();
        this.clickEventSubscription = this.table?.cellClick.subscribe({
            next: (clickEvent: CellClickEvent<iGoUserView>) => {
                clickEvent.event.stopPropagation();
                clickEvent.columnKey === 'delete' && this.onDelete(clickEvent.row);
            }
        });
    };

    public ngOnDestroy(): void {
        this.clickEventSubscription?.unsubscribe();
    };

    public async onCreateUser(): Promise<void> {
        if (!this.loadingModalData) {
            try {
                this.loadingModalData = true;
                await lastValueFrom(this.rolesService.getRolesObservable());
                this.loadingModalData = false;
                this.sharedModalsService.showCreateUserModal(this.usersResult._links, { windowClass: this.class }).result.then((res: iGoUserView) => {
                    this.addRowInTable(res);
                }).catch(() => { });
            } catch (error) {
                this.onFailure(error);
            };
        };
    };

    public onDelete(user: iGoUserView): void {
        this.sharedModalsService.showConfirmationModal(
            this.translateService.instant('global.modals.edit_user.delete_user.title'),
            this.translateService.instant('global.modals.edit_user.delete_user.message').replace('{0}', user.name || user.email),
            null, null, { windowClass: this.class }
        ).result.then(() => this.deleteUser(user)).catch(() => { });
    };

    public onFilterChange($event?: string): void {
        const filteredRows = this.filterService.filterGhostVSTableResults(false, this.filterSearchTerm, this.tempRows, this.adminUsersTableCustomisation);
        this.table.setRowsCollection(filteredRows, filteredRows, false, 0).then(() => this.rows = filteredRows);
    };

    public async onSelect(): Promise<void> {
        if (!this.loadingModalData) {
            this.loadingModalData = true;
            try {
                await lastValueFrom(this.rolesService.getRolesObservable());
                const user = await lastValueFrom(this.usersService.getUserByIdObservable(this.adminUsersTableCustomisation.selected.id));
                user && this.showEditUserModal(user);
            } catch (error) {
                this.onFailure(error);
            };
        };
    };

    public recalculateData(): void {
        this.loading = true;
        this.usersService.getUsersObservable(this.getUsersUrl).subscribe({
            next: this.onInitialiseData,
            error: this.onFailure
        });
    };

    private addRowInTable(res: iGoUserView): void {
        this.tempRows = this.tempRows.concat(res);
        this.rows = [...this.tempRows];
        this.table.setRowsCollection(this.tempRows, this.tempRows);
        this.filterSearchTerm && this.onFilterChange();
    };

    private deleteUser(user: iGoUserView): void {
        this.usersService.deleteUserAsync(user).subscribe({
            next: (id: string) => this.updateRowById(id),
            error: this.onFailure
        });
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.loading = false;
        this.loadingModalData = false;
        this.sharedModalsService.showServerValidationErrorsModal(res, this.translateService.instant('igo_portal.admin.users'));
    };
    private onInitialiseData = (): void => {
        this.loading = false;
        this.usersResult = this.usersService.getUsers();
        this.tempRows = [...this.usersResult.users];
        this.rows = [...this.tempRows];
        this.tableFooterItems = [{ key: 'global.tables.igo_portal.admin.users.total_users', description: this.tempRows.length }];

        if (this.tempRows.length) {
            !this.tempRows.some(i => i._links.DeleteUser) && this.table.hideColumn('delete');
        };
    };

    private showEditUserModal = (user: iGoUserView): void => {
        this.loadingModalData = false;
        this.sharedModalsService.showEditUserModal(user, { windowClass: this.class }).result.then((res: iGoUserView | string) => {
            this.updateRowById(res);
        }).catch(() => { });
    };

    private updateRowById(res: iGoUserView | string): void {
        if (res instanceof iGoUserView) {
            //Editing the User returns the full user object
            if (this.tempRows.find(i => i.id == res.id)) {
                this.table.updateRow(res);
                Object.assign(this.tempRows.find(i => i.id == res.id), res);
            };
        } else if (typeof res == 'string') {
            //Deleting the User returns the user ID
            this.ghostVSTableService.handleRowDelete<iGoUserView>(res, this.table, this.rows, this.tempRows);
        };
        this.rows = [...this.tempRows];
        this.filterSearchTerm && this.onFilterChange();
    };
};