// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        :host { align-items: center; display: flex; flex-flow: column; justify-content: center; height: 100%; width: 100%; }
        ::ng-deep .gvs-action-cell:hover stacked-arrow-right-icons.stacked-icons-wrapper span.stacked-icons-wrapper i.stacked-icon.fa-arrow-right {
            transform: translateX(10px);
            transition: 0.3s ease;
        }
        :host.gvs-action-cell stacked-arrow-right-icons { font-size: 20px; }
        :host.disabled { opacity: 0.5; cursor: not-allowed; }
    `, "",{"version":3,"sources":["webpack://./src/shared/table-cell-templates/access-company-profile-cell-template.component.ts"],"names":[],"mappings":";QACQ,QAAQ,mBAAmB,EAAE,aAAa,EAAE,iBAAiB,EAAE,uBAAuB,EAAE,YAAY,EAAE,WAAW,EAAE;QACnH;YACI,2BAA2B;YAC3B,qBAAqB;QACzB;QACA,kDAAkD,eAAe,EAAE;QACnE,iBAAiB,YAAY,EAAE,mBAAmB,EAAE","sourcesContent":["\n        :host { align-items: center; display: flex; flex-flow: column; justify-content: center; height: 100%; width: 100%; }\n        ::ng-deep .gvs-action-cell:hover stacked-arrow-right-icons.stacked-icons-wrapper span.stacked-icons-wrapper i.stacked-icon.fa-arrow-right {\n            transform: translateX(10px);\n            transition: 0.3s ease;\n        }\n        :host.gvs-action-cell stacked-arrow-right-icons { font-size: 20px; }\n        :host.disabled { opacity: 0.5; cursor: not-allowed; }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
