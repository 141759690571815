import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IViewCustomisation } from '../../../interfaces/custom-text.interface';
import { ColourView } from '../../../models';

@Component({
    selector: 'colour-picker-input',
    templateUrl: './colour-picker-input.component.html'
})

export class ColourPickerInputComponent {
    @Input() public config: IViewCustomisation;
    @Input() public for: string;
    @Input() public key: string;
    @Input() public set model(value) {
        this.colour = value ? new ColourView(value).pickerValue : 'rgb(252, 253, 245)';
    };
    @Output() private modelChange: EventEmitter<ColourView> = new EventEmitter<ColourView>();
    @Input() public required = false;
    @Input() public tooltipText = '';
    public colour: String = '';
    public presetColours = [
        '#730000',
        '#e14343',
        '#ed7013',
        '#ed9a1e',
        '#b6b42e',
        '#84d74b',
        '#2eb647',
        '#0dc0a2',
        '#0d8091',
        '#11b9f3',
        '#1b57a6',
        '#502fb6',
        '#a74fdb',
        '#c422a6',
        '#e362aa'
    ];

    public constructor() { };

    public onChange(): void {
        this.modelChange.emit(this.formatModel(this.colour));
    };

    private formatModel(model): ColourView {
        let colourView = new ColourView();
        colourView.pickerValue = model;
        return colourView;
    };
};