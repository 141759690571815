import { Component, EventEmitter, forwardRef, Input, Output, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TableComponent, SelectionType } from '@autocab/ghost-vs-table';
import { IPortalClassTypes } from 'src/interfaces/portal-class-types.interface';
import { Keys } from '../../../enums/keys.enum';
import { ObjectUtilities } from '../../object.utilities';

@Component({
    selector: 'ghost-vs-table-filter',
    templateUrl: './ghost-vs-table-filter.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => GhostVSTableFilterComponent),
        multi: true
    }],
    host: { class: 'input-group filter-input' }
})

export class GhostVSTableFilterComponent implements ControlValueAccessor {
    @ViewChild('input') public input: ElementRef;
    @HostListener('window:keydown', ['$event']) onWindowKeydown(event: KeyboardEvent) {
        if (event && event.ctrlKey && event.shiftKey && event.keyCode == Keys.F) {
            this.input.nativeElement.select();
            this.tableComponent.clearSelection(true);
        };
    };
    @Input() public class: IPortalClassTypes = 'default';
    @Input() public id: string = 'ghostVSTableFilter';
    @Input() public name: string = 'ghostVSTableFilter';
    @Input() public ngModel: string = '';
    @Input() public placeholder: string = '';
    @Input() public rows: any[];
    @Input() public selected: any[];
    @Input() public tableComponent: TableComponent;
    @Input() public tempRows: any[];
    @Input() public set value(value: string) {
        this._value = value;
        this.notifyValueChange();
        this.filterTableRows();
    };
    @Output() public onKeyup = new EventEmitter<KeyboardEvent>();

    private _value: string;

    public get value(): string { return this._value; };
    public tempSelected: any[] = [];

    public constructor() { };

    public filterTableRows(): void {
        this.tableComponent.setRowsCollection(this.rows, ObjectUtilities.filterTableRows(this.tempRows, this.value, this.tableComponent.customisation, false));
        if (this.selected) {
            this.tempSelected = [];
            this.selected.forEach(column => this.tableComponent.selectRow(column));
            this.selected && this.selected.forEach(row => {
                !this.tableComponent.customisation.selected[row[this.tableComponent.internalRowKey]] && this.tempSelected.push(row);
            });
        };
    };

    public notifyValueChange(): void {
        this.onChange && this.onChange(this.value);
    };

    public onChange: (value: string) => {};

    public onKeyUp(event: KeyboardEvent): void {
        event.keyCode == Keys.Delete && event.stopPropagation();
        if (event.keyCode === Keys.Enter) {
            this.tableComponent.clearSelection(true);
            this.tableComponent.scrollTop(0);
            this.tableComponent.selectionType === SelectionType.MultiClickRow ? this.tableComponent.activateRowByIndex(0, true) : this.tableComponent.selectActiveRowByIndex(0, true);
        };
    };

    public onTouched: () => {};

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    };

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    };

    public writeValue(value: string): void {
        this._value = value;
    };
};