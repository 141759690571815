import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sidebarRouteFilter',
    pure: false
})
export class SidebarRouteFilterPipe implements PipeTransform {
    public transform(items: any[]): any {
        if (!items) {
            return items;
        };

        return items.filter(item => !item.value.hideRoute);
    };
};