// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        :host { display: flex; flex-flow: column; height: 100%; justify-content: center; padding: 0 20px; width: 100%; }
        .status-card { background-color: grey; border-radius: 5px; color: white; padding: 5px; }
        .status-card.danger { background-color: #dc3545; }
        .status-card.success { background-color: #28a745; }
    `, "",{"version":3,"sources":["webpack://./src/shared/table-cell-templates/dispute-status-cell-template.component.ts"],"names":[],"mappings":";QACQ,QAAQ,aAAa,EAAE,iBAAiB,EAAE,YAAY,EAAE,uBAAuB,EAAE,eAAe,EAAE,WAAW,EAAE;QAC/G,eAAe,sBAAsB,EAAE,kBAAkB,EAAE,YAAY,EAAE,YAAY,EAAE;QACvF,sBAAsB,yBAAyB,EAAE;QACjD,uBAAuB,yBAAyB,EAAE","sourcesContent":["\n        :host { display: flex; flex-flow: column; height: 100%; justify-content: center; padding: 0 20px; width: 100%; }\n        .status-card { background-color: grey; border-radius: 5px; color: white; padding: 5px; }\n        .status-card.danger { background-color: #dc3545; }\n        .status-card.success { background-color: #28a745; }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
