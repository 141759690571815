import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'add-to-row-cell',
    template: `<i class="fal fa-plus {{disabled ? 'disabled' : ''}}"></i>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { '[class.disabled]': 'disabled' },
    styles: [`
        :host { align-items: center; display: flex; flex-flow: column; justify-content: center; height: 100%; width: 100%; }
        :host:not(.gvs-action-cell) { opacity: .5; }
        :host.gvs-action-cell:hover { background-color: rgb(42 42 42 / 20%) !important; transition: 0.2s ease-in-out; }
        :host.disabled { opacity: 0.5; cursor: not-allowed; }
    `]
})
export class AddToRowCellTplComponent {
    @Input() public set row(item: any) {
        if (item) {
            this.disabled = Boolean(item.disableDelete);
            this.cd.detectChanges();
        };
    };
    public disabled: boolean = false;
    public constructor(private cd: ChangeDetectorRef) { };
};