import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ArrayUtilities } from "src/shared/array.utilities";
import { BookingStatusesResponseApi, CXMarketplaceApi, GetBookingsPayload, GetConnectedCXMarketplacesResponseApi, PartialNameAndId } from "src/models";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MarketplacesService, SharedModalsService, TypesService, UserService } from "src/services";
import { MatSelectChange } from "@angular/material/select";
import { ObjectUtilities } from "src/shared/object.utilities";
import { Subscription, lastValueFrom } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'activity-bookings-filters',
    templateUrl: 'activity-bookings-filters.component.html',
    styles: [`
        :host {
            display: block;
            height: 100%;
            overflow: auto;
            padding: 0 40px 40px 40px;
            width: 100%;
        }
    `]
})
export class ActivityBookingsFiltersComponent implements OnInit {
    @Input() public class: string = '';
    @Input() public set filters(value: GetBookingsPayload) {
        this._filters = new GetBookingsPayload(value);
        this.filtersCopy = ObjectUtilities.deepCopy(this.filters);
    };
    @Input() public showOriginators: boolean = true;
    @Input() public showOperators: boolean = true;
    @Output() public reset: EventEmitter<GetBookingsPayload> = new EventEmitter<GetBookingsPayload>();
    @Output() public search: EventEmitter<GetBookingsPayload> = new EventEmitter<GetBookingsPayload>();
    @ViewChild('bookingReferenceInputField') public bookingReferenceInputField: ElementRef;

    public get filters(): GetBookingsPayload {
        return this._filters;
    };

    public bookingStatuses: BookingStatusesResponseApi[] = [];
    public currentPartnerType: string = '';
    public cxMarketplacesResult: GetConnectedCXMarketplacesResponseApi;
    public filtersCopy: GetBookingsPayload = new GetBookingsPayload();
    public form: UntypedFormGroup = null;
    public marketplaceFormControl: UntypedFormControl = new UntypedFormControl();
    public operatorsFormControl: UntypedFormControl = new UntypedFormControl();
    public originatorsFormControl: UntypedFormControl = new UntypedFormControl();
    public statusFormControl: UntypedFormControl = new UntypedFormControl();
    public selectedOperatorNames: string[];
    public selectedOriginatorNames: string[];
    public selectedStatusesNames: string[];

    //Multiselect Inputs
    public allMarketplaces: CXMarketplaceApi[] = [];
    public operatorsByMarketplace: PartialNameAndId[] = [];
    public originatorsByMarketplace: PartialNameAndId[] = [];

    private formSubscription: Subscription;
    private _filters: GetBookingsPayload;

    public constructor(
        private formBuilder: UntypedFormBuilder,
        private marketplacesService: MarketplacesService,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService,
        private typesService: TypesService
    ) {
        this.currentPartnerType = UserService.getPartnerTypeDetails().type;
    };

    public async ngOnInit(): Promise<void> {
        this.form = this.buildForm();
        this.bookingStatuses = await lastValueFrom(this.typesService.getBookingStatusesObservable());
        this.bookingStatuses && this.initialiseBookingStatusesMultiselect();
        this.cxMarketplacesResult = await lastValueFrom(this.marketplacesService.getConnectedCXMarketplacesObservable());
        this.cxMarketplacesResult?.marketplaces && this.initialiseMarketplaceSelection();
        this.currentPartnerType !== 'Originator' && this.cxMarketplacesResult?.agents && this.initialiseOriginatorMultiselect();
        this.currentPartnerType !== 'Operator' && this.cxMarketplacesResult?.vendors && this.initialiseOperatorMultiselect();
        this.formSubscription = this.form.valueChanges.subscribe({
            next: (value: any) => {
                this.filtersCopy.bookingReference = value['igo_portal.activity.bookings.booking_reference'];
                this.filtersCopy.dropoff = value['igo_portal.activity.bookings.destination'];
                this.filtersCopy.pickup = value['igo_portal.activity.bookings.pickup'];
                this.filtersCopy.passengerName = value['igo_portal.activity.bookings.passenger_name'];
                this.filtersCopy.passengerEmail = value['igo_portal.activity.bookings.passenger_email'];
            }
        });
    };

    public ngOnDestroy(): void {
        this.formSubscription?.unsubscribe();
    };

    public onReset(): void {
        this.form.reset();
        const defaultFilters = new GetBookingsPayload();
        this.filtersCopy.marketplaceId = null;
        this.filtersCopy.operatorIds = [];
        this.filtersCopy.originatorIds = [];
        this.filtersCopy.statuses = [];
        this.reset.emit(this.filtersCopy);
    };

    public onSearch(): void {
        if (this.form.valid) {
            this.search.emit(this.filtersCopy);
        } else {
            this.sharedModalsService.showFormValidationErrorsModal(this.translateService.instant('global.side_panel.filters'), this.form);
        };
    };

    public onSelectMarketplace($event: MatSelectChange): void {
        this.filtersCopy.marketplaceId = $event.value;
        this.cxMarketplacesResult?.agents && this.initialiseOriginatorMultiselect();
        this.cxMarketplacesResult?.vendors && this.initialiseOperatorMultiselect();
    };

    public onSelectOperators($event: MatSelectChange): void {
        this.filtersCopy.operatorIds = $event.value;
        this.selectedOperatorNames = this.filtersCopy.operatorIds.map(i => this.operatorsByMarketplace.find(x => x.id == i)?.name);
    };

    public onSelectOriginators($event: MatSelectChange): void {
        this.filtersCopy.originatorIds = $event.value;
        this.selectedOriginatorNames = this.filtersCopy.originatorIds.map(i => this.originatorsByMarketplace.find(x => x.id == i)?.name);
    };

    public onSelectStatuses($event: MatSelectChange): void {
        this.filtersCopy.statuses = $event.value;
        this.selectedStatusesNames = this.filtersCopy.statuses.map(i => this.bookingStatuses.find(x => x.Value == i)?.Name);
    };

    private buildForm(): UntypedFormGroup {
        return this.formBuilder.group({
            'igo_portal.activity.bookings.marketplace': this.marketplaceFormControl,
            'igo_portal.activity.bookings.operators': this.operatorsFormControl,
            'igo_portal.activity.bookings.originators': this.originatorsFormControl,
            'igo_portal.activity.bookings.booking_reference': [this.filtersCopy.bookingReference],
            'igo_portal.activity.bookings.destination': [this.filtersCopy.dropoff],
            'igo_portal.activity.bookings.pickup': [this.filtersCopy.pickup],
            'igo_portal.activity.bookings.passenger_name': [this.filtersCopy.passengerName],
            'igo_portal.activity.bookings.passenger_email': [this.filtersCopy.passengerEmail],
            'igo_portal.activity.bookings.statuses': this.statusFormControl,
        });
    };

    private initialiseMarketplaceSelection(): void {
        this.allMarketplaces = this.cxMarketplacesResult?.marketplaces && ArrayUtilities.sortAlphabetically(this.cxMarketplacesResult.marketplaces, 'name');
    };

    private initialiseOperatorMultiselect(): void {
        if (this.filtersCopy.marketplaceId) {
            this.filtersCopy.operatorIds = [];
            this.operatorsFormControl.setValue([]);
            const operatorsByMarketplace: string[] = [...this.cxMarketplacesResult.marketplaces.find(i => i.name == this.filtersCopy.marketplaceId).vendors];
            this.operatorsByMarketplace = operatorsByMarketplace.map(i => this.cxMarketplacesResult.vendors.find(x => x.id == i));
        } else {
            this.operatorsByMarketplace = [...this.cxMarketplacesResult.vendors];
        };
        this.operatorsByMarketplace = ArrayUtilities.sortAlphabetically(this.operatorsByMarketplace, this.class === 'igo' ? 'displayName' : 'name');
        this.selectedOperatorNames = this.filtersCopy.operatorIds.map(i => this.operatorsByMarketplace.find(x => x.id == i)?.name);
    };

    private initialiseOriginatorMultiselect(): void {
        if (this.filtersCopy.marketplaceId) {
            this.filtersCopy.originatorIds = [];
            this.originatorsFormControl.setValue([]);
            const originatorsByMarketplace: string[] = [...this.cxMarketplacesResult.marketplaces.find(i => i.name == this.filtersCopy.marketplaceId).agents];
            this.originatorsByMarketplace = originatorsByMarketplace.map(i => this.cxMarketplacesResult.agents.find(x => x.id == i));
        } else {
            this.originatorsByMarketplace = [...this.cxMarketplacesResult.agents];
        };
        this.originatorsByMarketplace = ArrayUtilities.sortAlphabetically(this.originatorsByMarketplace, this.class === 'igo' ? 'displayName' : 'name');
        this.selectedOriginatorNames = this.filtersCopy.originatorIds.map(i => this.originatorsByMarketplace.find(x => x.id == i)?.name);
    };

    private initialiseBookingStatusesMultiselect(): void {
        this.bookingStatuses = ArrayUtilities.sortAlphabetically(this.bookingStatuses, 'Name');
    };
};