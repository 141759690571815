import { DatePipe } from '@angular/common';
import { Input, Pipe, PipeTransform } from '@angular/core';
import { SettingsService } from '../../services/settings.service';

@Pipe({ name: 'genericDatePipe', pure: false })
export class GenericDatePipe implements PipeTransform {
    @Input() public format: string = 'shortDate';
    
    public transform(value: string): string {
        return new DatePipe(SettingsService.LanguageCode).transform(value, this.format);
    };
};