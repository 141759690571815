import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { ToastService } from "src/services";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'toast',
    templateUrl: 'toast.component.html',
    styleUrls: ['toast.component.css']
})
export class ToastComponent implements OnInit, OnDestroy {
    public dismissed: boolean = true;
    public message: string = null;
    public style: string = '';
    private clearMessageTimeout: any;
    private subscription: Subscription;

    public constructor(
        private toastService: ToastService,
        private translateService: TranslateService
    ) { };

    public ngOnInit(): void {
        this.subscription = this.toastService.updateToast.subscribe({
            next: ({ message, style, timerMs }) => {
                this.style = style;
                if (message) {
                    this.message = this.translateService.instant(message);
                    this.dismissed = false;
                } else {
                    this.dismissed = true;
                    this.clearMessageTimeout = setTimeout(() => {
                        this.message = null;
                        clearTimeout(this.clearMessageTimeout);
                    }, timerMs);
                };
            }
        });
    };

    public ngOnDestroy(): void {
        this.clearMessageTimeout && clearTimeout(this.clearMessageTimeout);
        this.subscription?.unsubscribe();
    };

    public onDismiss(): void {
        this.toastService.setToastMessage(null);
    };
};