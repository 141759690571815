import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GhostVSTableDropdownComponent } from '../ghost-vs-table-dropdown/ghost-vs-table-dropdown.component';
import { GhostVSTableFilterComponent } from './ghost-vs-table-filter.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToggleSwitchModule } from '../toggle-switch/toggle-switch.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        NgbDropdownModule,
        TranslateModule,
        ToggleSwitchModule
    ],
    declarations: [
        GhostVSTableFilterComponent,
        GhostVSTableDropdownComponent,
    ],
    exports: [
        GhostVSTableFilterComponent,
        GhostVSTableDropdownComponent
    ]
})
export class GhostVSTableFilterModule { };