import { Component, Input, OnInit } from '@angular/core';
import { CurrencyGlobalPipe } from './../../pipes/currency.pipe';
import { IAreaChartConfig } from './../../../interfaces/charts.interface';

const currencyPipe = new CurrencyGlobalPipe();
@Component({
    selector: 'ghost-area-chart',
    templateUrl: './ghost-area-chart.component.html',
    styleUrls: ['./ghost-area-chart.component.css']
})
export class GhostAreaChartComponent implements OnInit {
    @Input() public config: IAreaChartConfig;

    public constructor() {
    };

    ngOnInit(): void {
    };

    onSelect(event) {
    }

}
