import { Component, EventEmitter, HostListener, Output } from '@angular/core';

@Component({
    selector: 'hold-to-delete-button',
    styleUrls: ['hold-to-delete-button.component.css'],
    templateUrl: './hold-to-delete-button.component.html'
})

export class HoldToDeleteButtonComponent {
    @HostListener('mouseup', ['$event']) private mouseUp(e: any) {
        this.totalButtonClicks += 1;
        if (this.totalButtonClicks >= 3) {
            this.buttonText = 'global.button.hold_to_delete';
        };
    };
    @Output() public onDelete: EventEmitter<void> = new EventEmitter<void>();
    public buttonText: string = 'global.button.delete';
    public progress: number = 0;
    public totalButtonClicks: number = 0;

    public holdToDeleteHandler($event): void {
        this.progress = $event / 10;
        if (this.progress > 100) {
            this.onDelete.emit();
        };
    };
};