// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
    height: 100%;
    width: 100%;
}

.ghost-grouped-bar-chart {
    height: 80%;
    margin-top: 25px;
    width: 35%;
    float: left;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/ghost-grouped-bar-chart/ghost-grouped-bar-chart.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,UAAU;IACV,WAAW;AACf","sourcesContent":[":host {\n    height: 100%;\n    width: 100%;\n}\n\n.ghost-grouped-bar-chart {\n    height: 80%;\n    margin-top: 25px;\n    width: 35%;\n    float: left;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
