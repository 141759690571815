// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form {
    padding: 30px 30px 0 30px;
}

.map-wrapper {
    height: calc(100% - 95px);
    position: relative;
}`, "",{"version":3,"sources":["webpack://./src/modules/shared-modals/modals/region/region.modal.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;AACtB","sourcesContent":["form {\n    padding: 30px 30px 0 30px;\n}\n\n.map-wrapper {\n    height: calc(100% - 95px);\n    position: relative;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
