import { Injectable } from '@angular/core';
import { ArrayUtilities } from 'src/shared/array.utilities';
import { CreateiGoUserApi, GetAllUsersResponseApi, GetAllUsersResponseView, iGoUserApi, iGoUserView, SetupIGoUserApi } from '../models/user.model';
import { Endpoints } from '../shared/endpoints';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RequestService } from './request.service';
import { RolesService } from './roles.service';

@Injectable({ providedIn: 'root' })
export class UsersService {
    public users: GetAllUsersResponseView = null;

    public constructor(
        private http: RequestService,
        private rolesService: RolesService
    ) { };

    public getUsers = (): GetAllUsersResponseView => this.users;

    public createUserAsync(url: string, user: iGoUserView): Observable<iGoUserView> {
        return this.http.authenticatedPost<iGoUserApi>(url, new CreateiGoUserApi(user), {}, 'global.request.creating_user').pipe(
            map(this.mapUserToViewModel)
        );
    };

    public deleteUserAsync(user: iGoUserView): Observable<string> {
        return this.http.authenticatedDelete<void>(user._links.DeleteUser.href, {}, 'global.request.deleting_user').pipe(
            map(res => user.id)
        );
    };

    public getUserByIdObservable(userId: string): Observable<iGoUserView> {
        return this.http.authenticatedGet<iGoUserApi>(Endpoints.API_URL + Endpoints.USERS._USERS + `/${userId}`, {}, 'global.request.loading_user').pipe(
            map(this.mapUserToViewModel)
        );
    };

    public getUsersObservable(url: string): Observable<void> {
        return this.http.authenticatedGet<GetAllUsersResponseApi>(url, {}, 'global.request.loading_users').pipe(
            map(res => {
                const usersView = res.users.map(this.mapUserToViewModel);
                this.users = new GetAllUsersResponseView({ users: ArrayUtilities.sortAlphabetically(usersView, 'name'), _links: res._links });
            })
        );
    };

    public mapUserToViewModel = (user: iGoUserApi): iGoUserView => {
        let userView = new iGoUserView(user as iGoUserView);
        const roles = this.rolesService.getRoles()?.roles || [];
        if (roles.length) {
            userView.rolesView = userView.roles.map(i => {
                return roles.find(role => role.name == i);
            }).filter(Boolean);
        };
        return userView;
    };

    public setupUserAsync(userId: string, user: SetupIGoUserApi): Observable<iGoUserView> {
        return this.http.authenticatedPut<iGoUserApi>(Endpoints.API_URL + Endpoints.USERS._USERS + `/${userId}` + Endpoints.USERS._SETUP, new SetupIGoUserApi(user), {}, 'global.request.creating_user').pipe(
            map(this.mapUserToViewModel)
        );
    };

    public sendRegistrationEmailAsync(userId: string): Observable<void> {
        return this.http.authenticatedPost(Endpoints.API_URL + Endpoints.USERS._USERS + `/${userId}` + Endpoints.USERS._SEND_REGISTRATION_EMAIL, {}, {}, 'global.request.sending_email');
    };

    public updateUserAsync(user: iGoUserView): Observable<iGoUserView> {
        return this.http.authenticatedPut<iGoUserApi>(user._links.UpdateDetails.href, new iGoUserApi(user), {}, 'global.request.updating_user').pipe(
            map(this.mapUserToViewModel)
        );
    };

    public updateUserRolesAsync(user: iGoUserView): Observable<iGoUserView> {
        return this.http.authenticatedPut<iGoUserApi>(user._links.UpdateUserRoles.href, { roles: user.roles }, {}, 'global.request.updating_user_roles').pipe(
            map(this.mapUserToViewModel)
        );
    };
};