import { Injectable } from '@angular/core';
import { ChangeUserPasswordPayload, UserApi, UserView } from '../models/user.model';
import { ColumnPartial, TableCustomisation } from '@autocab/ghost-vs-table';
import { Dictionary } from '../models/dictionary.model';
import { Endpoints } from '../shared/endpoints';
import { GainsightService } from './gainsight.service';
import { HalLinkApi, iGoCompanyOperatorView, iGoCompanyOriginatorView } from 'src/models';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RequestService } from './request.service';
import { StorageService } from './storage.service';

declare const aptrinsic: any;

@Injectable({ providedIn: 'root' })
export class UserService {
    static currentUser: UserView = {} as UserView;
    public currentUserImage: string = null;
    public static customisations: Dictionary<TableCustomisation<ColumnPartial>> = {};

    public constructor(
        private http: RequestService
    ) { };

    public getCurrentUser = (): UserView => UserService.currentUser;

    public changeUserPasswordAsync(payload: ChangeUserPasswordPayload): Observable<void> {
        return this.http.authenticatedPut(Endpoints.API_URL + Endpoints.USERS._USERS + Endpoints.USERS._PASSWORD, new ChangeUserPasswordPayload(payload), {}, 'global.request.changing_password');
    };

    public getCurrentUserObservable(): Observable<void> {
        return this.http.authenticatedGet<UserApi>(Endpoints.API_URL + Endpoints.USERS._USERS + Endpoints.USERS._ME, {}, 'global.request.loading_user_details').pipe(
            map(user => {
                UserService.currentUser = new UserView(user as UserView);
                UserService.updateGainsightUserInfo();
            })
        );
    };

    public static getPartnerTypeDetails(): { companyId: string; id: string, type: string; } {
        const operator: iGoCompanyOperatorView = JSON.parse(sessionStorage.getItem('operator')) || StorageService.getItem('operator');
        const originator: iGoCompanyOriginatorView = JSON.parse(sessionStorage.getItem('originator')) || StorageService.getItem('originator');

        if (operator) {
            return { companyId: operator.companyId, id: operator.id, type: 'Operator' };
        } else if (originator) {
            if (originator.global) {
                return { companyId: originator.companyId, id: originator.id, type: 'IGo' };
            } else {
                return { companyId: originator.companyId, id: originator.id, type: 'Originator' };
            };
        };
    };

    public getUserHalLink(halLinkKey: string): HalLinkApi | undefined {
        return this.getCurrentUser()._links[halLinkKey];
    };

    public static registerCustomisation(customisation: TableCustomisation<any>) {
        UserService.customisations[customisation.customisationKey] = customisation;
    };

    public setCurrentUser(user: UserApi): void {
        UserService.currentUser = new UserView(user as UserView);
    };

    public static updateGainsightUserInfo(): void {
        const { companyId, companyName, isOperator, isOriginator } = GainsightService.getGainsightCompanyDetails();
        //Passing user and company data to Gainsight
        if (aptrinsic) {
            aptrinsic("identify", {
                //User Fields,
                email: UserService.currentUser.email,
                id: UserService.currentUser.id,
                isComplete: UserService.currentUser.isComplete,
                firstName: UserService.currentUser.firstName,
                lastName: UserService.currentUser.lastName,
                isAutocabUser: UserService.currentUser.isIgo ? 'Yes' : 'No',
                companyId,
                companyName,
                isOperator: isOperator ? 'Yes' : 'No',
                isOriginator: isOriginator ? 'Yes' : 'No'
            }, {
                //Account Fields
                companyId,
                companyName,
                id: companyId,
                name: companyName,
                isOperator: isOperator ? 'Yes' : 'No',
                isOriginator: isOriginator ? 'Yes' : 'No'
            });
        };
    };
};