import { OnInit, Component, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { AccountTransactionExportPayload, CornicheAccountDetailsView, CornicheAccountTransactionView, GetAccountTransactionPayload } from "src/models";
import { CornicheService, DownloadService, ModalService, SharedModalsService, UserService } from "src/services";
import { DateUtilities } from "src/shared/date.utilities";
import { HttpErrorResponse } from "@angular/common/http";
import { ITableFooterItem } from "src/shared/components/ghost-vs-table-footer/ghost-vs-table-footer.component";
import { GhostVSTableFilterComponent } from "src/shared/components/ghost-vs-table-filter/ghost-vs-table-filter.component";
import { TransactionNotesModalComponent } from "./modals/transaction-notes-modal/transaction-notes.modal";
import { AccountTransactionsTableCustomisation } from "./account-transactions-table.customisation";
import { TableComponent, TableCustomisation } from "@autocab/ghost-vs-table";
import { TranslateService } from "@ngx-translate/core";
import { lastValueFrom } from "rxjs";

type ExportFileType = 'csv' | 'pdf';

@Component({
    selector: 'account-transactions',
    templateUrl: './account-transactions.component.html',
    styleUrls: ['./account-transactions.component.css']
})
export class AccountTransactionsComponent implements OnInit {
    @ViewChild(GhostVSTableFilterComponent) public tableFilter: GhostVSTableFilterComponent;
    @ViewChild(TableComponent) public table: TableComponent;
    @Input() public account: CornicheAccountDetailsView = null;
    @Input() public class: string = '';
    @Output() onGoBack: EventEmitter<boolean> = new EventEmitter();
    public filters: GetAccountTransactionPayload = new GetAccountTransactionPayload();
    public exportTransactionsPayload: AccountTransactionExportPayload;
    public exportCSVTransactionsApiUrl: string;
    public exportPDFTransactionsApiUrl: string;
    public hideNextPageButton: boolean = false;
    public loading: boolean = false;
    public exportingCSV: boolean = false;
    public exportingPDF: boolean = false;
    public rows: CornicheAccountTransactionView[] = [];
    public showPageSizeDropDown: boolean = true;
    public showCountSpinner: boolean = false;
    public showLoadingMoreSpinner: boolean = false;
    public tableFooterItems: Array<ITableFooterItem>;
    public transactionsTableCustomisation: TableCustomisation<CornicheAccountTransactionView> = AccountTransactionsTableCustomisation;

    public constructor(
        private cornicheService: CornicheService,
        private downloadService: DownloadService,
        private modalService: ModalService,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService,
        private userService: UserService
    ) { };

    public async ngOnInit(): Promise<void> {
        await DateUtilities.getDateRange('last30Days').then(dateRange => {
            this.filters.filter.from = dateRange.from;
            this.filters.filter.to = dateRange.to;
        });
        this.exportCSVTransactionsApiUrl = this.userService.getUserHalLink('ExportCornicheTransactions')?.href;
        this.exportPDFTransactionsApiUrl = this.userService.getUserHalLink('ExportTransactionsPdf')?.href;
        this.filters.currencyCode = this.account.currency;
        this.recalculateData(true);
    };

    public recalculateData = async (reset: boolean = false): Promise<void> => {
        try {
            if (reset) {
                this.loading = true;
                this.filters.filter.offset = 0;
            };
            this.showCountSpinner = Boolean(reset);
            this.showLoadingMoreSpinner = !Boolean(reset);
            const accountTransactionsUrl = this.userService.getUserHalLink('GetCornicheTransactions')?.href;
            if (accountTransactionsUrl) {
                const accountTransactions = await lastValueFrom(this.cornicheService.getCornicheAccountTransactionObservable(this.filters, accountTransactionsUrl));
                this.onInitialiseData(accountTransactions);
            };
        } catch (error) {
            this.onFailure(error);
        };
    };

    public onChangeDateRange({ fromDate, toDate }): void {
        this.filters.filter.from = fromDate ? DateUtilities.getMidnight(fromDate) : null;
        this.filters.filter.to = toDate ? DateUtilities.getEndOfDay(toDate) : null;
        this.filters = new GetAccountTransactionPayload(this.filters);
        this.filters.filter.from && this.filters.filter.to && this.recalculateData(true);
    };

    public async onExport(type: ExportFileType) {
        this.exportTransactionsPayload = new AccountTransactionExportPayload(this.filters);
        let apiUrl: string | null;

        // Determine the appropriate API URL based on the type parameter
        if (type === 'csv' && this.exportCSVTransactionsApiUrl) {
            apiUrl = this.exportCSVTransactionsApiUrl;
        } else if (type === 'pdf' && this.exportPDFTransactionsApiUrl) {
            apiUrl = this.exportPDFTransactionsApiUrl;
        }

        if (!this.loading && !this.showLoadingMoreSpinner && apiUrl) {
            this.exportingCSV = type === 'csv';
            this.exportingPDF = type === 'pdf';
            try {
                const result = await lastValueFrom(this.cornicheService.getTransactionsExportObservable(apiUrl, this.exportTransactionsPayload));
                if (type === 'csv') {
                    result && this.downloadService.downloadFile({ fileName: this.account.name + ' Transactions' }, result);
                    this.exportingCSV = false;
                } else {
                    result && this.downloadService.downloadPDF(this.account.name + ' Transactions.pdf', result);
                    this.exportingPDF = false;
                }
            } catch (error) {
                this.exportingCSV = false;
                this.exportingPDF = false;
                this.onFailure(error);
            }
        }
    }


    public async onSelect(): Promise<void> {
        if (this.transactionsTableCustomisation.selected.notes) {
            this.showNotes();
        }
    };

    public onSelectPageSize(value: number) {
        this.filters.filter.count = value;
    };

    public getRowStyles = (row: CornicheAccountTransactionView): string => {
        return row.amount > 0 ? 'color: green !important;' : 'color: red !important;';
    };

    private onInitialiseData = (res: CornicheAccountTransactionView[]): void => {
        this.loading = false;
        this.showLoadingMoreSpinner = false;
        this.showCountSpinner = false;

        if (this.filters.filter.offset != 0) {
            this.rows = this.rows.concat(res);
        } else {
            this.rows = [...res];
        }
        this.tableFooterItems = [{ key: 'global.tables.operator_portal_account.transactions.table_title', description: this.rows.length }];

        // Update hideNextPageButton based on the number of results and the selected page size
        this.hideNextPageButton = res.length < this.filters.filter.count;
        this.filters.filter.offset = this.rows.length;
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.loading = false;
        this.sharedModalsService.showServerValidationErrorsModal(res, this.translateService.instant('operator_portal.accounts.accounts'));
    };

    private showNotes(): void {
        const modalRef = this.modalService.open(TransactionNotesModalComponent, { size: 'sm', windowClass: this.class });
        modalRef.componentInstance.class = this.class;
        modalRef.componentInstance.description = this.transactionsTableCustomisation.selected.description;
        modalRef.componentInstance.notes = this.transactionsTableCustomisation.selected.notes;
        modalRef.componentInstance.title = this.translateService.instant('operator_portal.accounts.account.transactions_notes');
        modalRef.result.then(() => { }).catch(() => { });
    };
};
