import { Component, Input, OnInit } from '@angular/core';
import { ChartsService } from '../../../services';
import { IChartColorScheme, IGroupedBarChartConfig } from '../../../interfaces/charts.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'ghost-grouped-bar-chart',
    styleUrls: [
        'ghost-grouped-bar-chart.component.css'
    ],
    templateUrl: './ghost-grouped-bar-chart.component.html'
})

export class GhostGroupedBarChartComponent implements OnInit {
    @Input() public set config(value: IGroupedBarChartConfig) {
        this._config = value;
        if (value) {
            if (value.data && value.data.length) {
                for (let i = 0; i < value.data.length; i++) {
                    this._config.data[i].name = this.translateService.instant(this._config.data[i].name);
                    for (let x = 0; x < value.data[i].series.length; x++) {
                        this._config.data[i].series[x].name = this.translateService.instant(this._config.data[i].series[x].name);
                    };
                };
            };
            this._config.xAxisLabel = this._config && this._config.xAxisLabel ? this.translateService.instant(this._config.xAxisLabel) : '';
            this._config.yAxisLabel = this._config && this._config.yAxisLabel ? this.translateService.instant(this._config.yAxisLabel) : '';
        };
    };
    public multiColourScheme: IChartColorScheme;
    private _config: IGroupedBarChartConfig = null;

    public constructor(
        private chartService: ChartsService,
        private translateService: TranslateService
    ) { };

    public ngOnInit(): void {
        this.multiColourScheme = this.chartService.multiColourScheme;
    };

    public get config(): IGroupedBarChartConfig {
        return this._config;
    };
};