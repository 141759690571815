import { Injectable } from '@angular/core';
import { PortalItem } from '../models';

@Injectable({ providedIn: 'root' })
export class PortalsService {
    public portals: PortalItem[] = [
        {
            class: 'originator',
            description: '',
            name: 'portals.originator_portal',
            options: [],
            route: '/originatorportal/network',
            selected: false,
        },
        {
            class: 'operator',
            description: '',
            name: 'portals.operator_portal',
            options: [],
            route: '/operatorportal/activity',
            selected: false,
        }
    ];

    public constructor() { };
};