import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'ghost-timepicker',
    styleUrls: ['ghost-timepicker.component.css'],
    templateUrl: './ghost-timepicker.component.html'
})

export class GhostTimepickerComponent implements OnInit, OnChanges {
    @ViewChild('dayInput') public dayInput: HTMLInputElement;
    @ViewChild('hourInput') public hourInput: HTMLInputElement;
    @ViewChild('minuteInput') public minuteInput: HTMLInputElement;
    @ViewChild('secondInput') public secondInput: HTMLInputElement;
    @Input() public set model(value) {
        this.time = value ? new String(value) : '00:00:00';
    };
    @Input() public disabled: boolean = false;
    @Input() public showDays: boolean = false;
    @Input() public showHours: boolean = true;
    @Input() public showSeconds: boolean = true;
    @Input() public timeSelection: boolean = true;
    @Input() public dayStep: number = 1;
    @Input() public hourStep: number = 1;
    @Input() public maxDays: number = null;
    @Input() public minuteStep: number = 1;
    @Input() public secondStep: number = 1;
    @Output() private modelChange: EventEmitter<String> = new EventEmitter<String>();

    public digitArray: number[] = Array(10).fill(0).map((e, i) => i);
    public dayString: string;
    public hourString: string;
    public minuteString: string;
    public secondString: string;
    public time: String = '00:00:00';
    private day: number;
    private hour: number;
    private minute: number;
    private second: number;

    public ngOnInit(): void {
        this.getTimeValues();
        this.getTimeStrings();
    };

    public ngOnChanges(): void {
        this.getTimeValues();
        this.getTimeStrings();
    };

    public getTimeStrings(): void {
        this.getDayString();
        this.getHourStringTimes();
        this.getMinuteStringTimes();
        this.getSecondStringTimes();
    };

    public getTimeValues(): void {
        const timeSections: string[] = this.time.split(':');
        if (this.showDays) {
            this.day = parseInt(timeSections[0]);
        };
        this.hour = this.showDays ? parseInt(timeSections[1]) : this.showHours ? parseInt(timeSections[0]) : 0;
        this.minute = this.showDays ? parseInt(timeSections[2]) : parseInt(timeSections[1]);
        if (this.showSeconds) {
            this.second = this.showDays ? parseInt(timeSections[3]) : parseInt(timeSections[2]);
        };
    };

    public decrementDay(): void {
        this.day = (this.day - this.dayStep > 0) ? this.day - this.dayStep : 0;
        this.getDayString();
        this.updateModel();
    };

    public decrementHour(): void {
        if (this.hour === 0) {
            this.hour = 24 - this.hourStep;
            if (this.showDays && this.day > 0) {
                this.decrementDay();
            };
        } else if (this.hour % this.hourStep !== 0) {
            this.hour = this.roundDownToNearestStep(this.hour, this.hourStep, 24);
        } else {
            this.hour = this.hour - this.hourStep;
        };
        this.getHourStringTimes();
        this.updateModel();
    };

    public decrementMinute(): void {
        if (this.minute === (0 + this.minuteStep)) {
            this.minute = 0;
        } else if (this.minute === 0) {
            this.minute = 60 - this.minuteStep;
            this.decrementHour();
        } else if (this.minute % this.minuteStep !== 0) {
            this.minute = this.roundDownToNearestStep(this.minute, this.minuteStep, 60);
        } else {
            this.minute = this.minute - this.minuteStep;
        };
        this.getMinuteStringTimes();
        this.updateModel();
    };

    public decrementSecond(): void {
        if (this.second === (0 + this.secondStep)) {
            this.second = 0;
        } else if (this.second === 0) {
            this.second = 60 - this.secondStep;
            this.decrementMinute();
        } else if (this.second % this.secondStep !== 0) {
            this.second = this.roundDownToNearestStep(this.second, this.secondStep, 60);
        } else {
            this.second = this.second - this.secondStep;
        };
        this.getSecondStringTimes();
        this.updateModel();
    };

    public getDayString(): void {
        this.dayString = String(this.day);
    };

    public getHourStringTimes(): void {
        this.hourString = this.digitArray.indexOf(this.hour) >= 0 ? "0" + String(this.hour) : String(this.hour);
    };

    public getMinuteStringTimes(): void {
        this.minuteString = this.digitArray.indexOf(this.minute) >= 0 ? "0" + String(this.minute) : String(this.minute);
    };

    public getSecondStringTimes(): void {
        this.secondString = this.digitArray.indexOf(this.second) >= 0 ? "0" + String(this.second) : String(this.second);
    };

    public incrementDay(): void {
        if (this.maxDays) {
            this.day = (this.day + this.dayStep <= this.maxDays) ? this.day  + this.dayStep : this.maxDays;
        } else {
            this.day = this.day + this.dayStep;
        };
        this.getDayString();
        this.updateModel();
    };

    public incrementHour(): void {
        if (this.hour === (24 - this.hourStep)) {
            this.hour = 0;
            if (this.showDays) {
                this.incrementDay();
            };
        } else if (this.hour % this.hourStep !== 0) {
            this.hour = this.roundUpToNearestStep(this.hour, this.hourStep, 24);
            if (this.hour == 0) {
                this.incrementDay();
            };
        } else {
            this.hour = this.hour + this.hourStep;
        };
        this.getHourStringTimes();
        this.updateModel();
    };

    public incrementMinute(): void {
        if (this.minute === (60 - this.minuteStep)) {
            this.minute = 0;
            this.incrementHour();
        } else if (this.minute % this.minuteStep !== 0) {
            this.minute = this.roundUpToNearestStep(this.minute, this.minuteStep, 60);
            if (this.minute == 0) {
                this.incrementHour();
            };
        } else {
            this.minute = this.minute + this.minuteStep;
        };
        this.getMinuteStringTimes();
        this.updateModel();
    };

    public incrementSecond(): void {
        if (this.second === (60 - this.secondStep)) {
            this.second = 0;
            this.incrementMinute();
        } else if (this.second % this.secondStep !== 0) {
            this.second = this.roundUpToNearestStep(this.second, this.secondStep, 60);
            if (this.second == 0) {
                this.incrementMinute();
            };
        } else {
            this.second = this.second + this.secondStep;
        };
        this.getSecondStringTimes();
        this.updateModel();
    };

    public onChange(event: KeyboardEvent): void {
        if ((<HTMLInputElement>event.target).name === "hour") {
            this.hour = (<HTMLInputElement>event.target).value === "" ? 0 : parseInt(this.hourString);
            this.getHourStringTimes();
        } else if ((<HTMLInputElement>event.target).name === "minute") {
            this.minute = (<HTMLInputElement>event.target).value === "" ? 0 : parseInt(this.minuteString);
            this.getMinuteStringTimes();
        } else if ((<HTMLInputElement>event.target).name === "second") {
            if ((<HTMLInputElement>event.target).value === "") {
                this.second = 0;
            } else {
                this.second = parseInt(this.secondString);
            };
            this.getSecondStringTimes();
        };
        this.updateModel();
    };

    public onFocus(event): void {
        event.target.setSelectionRange(0, 0);
        event.target.focus();
        event.target.setSelectionRange(0, 2);
    };

    public roundDownToNearestStep(num, step, limit): number {
        const newVal = Math.floor(num / step) * step;
        return newVal == limit ? 0 : newVal;
    };

    public roundUpToNearestStep(num, step, limit): number {
        const newVal = Math.ceil(num / step) * step;
        return newVal == limit ? 0 : newVal;
    };

    public updateModel(): void {
        this.time = (this.showDays ? this.dayString + ':' : '') + (this.hourString ? this.hourString + ':' : '') + this.minuteString + (this.showSeconds ? ':' + this.secondString : '');
        this.modelChange.next(this.time);
    };
};