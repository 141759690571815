import { GhostVSTableModuleConfig } from "@autocab/ghost-vs-table";
export const GhostVSTableConfig: GhostVSTableModuleConfig  = {
    body: {
        classes: {
            row: 'gvst-row',
        },
        rowHeight: 70
    },
    header: {
        height: 90
    },
    overlays: {
        globalOverlayClasses: {
            header: 'gvst-header gvs-overlay-header',
            content: 'gvst-content gvs-overlay-content',
            footer: 'gvst-footer gvs-overlay-footer'
        },
        customise: {
            classes: {
                closeBtn: 'btn btn-outline-primary float-right me-2',
                resetTableBtn: 'btn btn-outline-primary me-2'
            },
            height: 680,
            width: 680
        },
        export: {
            classes: {
                closeBtn: 'btn btn-outline-primary float-right me-2',
                exportBtn: 'btn btn-outline-primary float-right me-2'
            },
            height: 380
        },
        headerContextMenu: {
            class: 'gvst-header-menu',
            height: 245,
            width: 230
        },
        print: {
            classes: {
                closeBtn: 'btn btn-outline-primary float-right me-2',
                printBtn: 'btn btn-outline-primary float-right me-2'
            },
            height: 660
        },
        renameColumn: {
            classes: {
                closeBtn: 'btn btn-outline-primary float-right me-2',
                okBtn: 'btn btn-outline-primary float-right me-2'
            },
            height: 240
        }
    }
};