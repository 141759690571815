import { CornicheAccountTransactionView } from 'src/models';
import { TableCustomisation } from '@autocab/ghost-vs-table';
import { UserService } from 'src/services';

export const AccountTransactionsTableCustomisation: TableCustomisation<CornicheAccountTransactionView> = {
    customisationKey: 'AccountTransactionsTableCustomisation',
    tableName: 'global.tables.operator_portal_account.transactions.table_title',
    defaultOrderBy: ['-date'],
    defaultColumns: {
        date: {
            width: 15,
            name: 'global.tables.operator_portal_account.transactions.table.date',
            orderBy: 'date',
            prop: 'dateView',
            show: true
        },
        description: {
            width: 45,
            name: 'global.tables.operator_portal_account.transactions.table.description',
            orderBy: 'description',
            prop: 'description',
            show: true
        },
        amountCurrency: {
            width: 20,
            name: 'global.tables.operator_portal_account.transactions.table.amount_currency',
            orderBy: 'amount',
            prop: 'amountCurrency',
            show: true
        },
        balanceCurrency: {
            width: 20,
            name: 'global.tables.operator_portal_account.transactions.table.balance_currency',
            orderBy: 'balance',
            prop: 'balanceCurrency',
            show: true
        },
        notes: {
            width: 15,
            name: 'global.tables.operator_portal_account.transactions.table.notes',
            orderBy: 'notes',
            prop: 'notes',
            show: false
        },
    }
};

UserService.registerCustomisation(AccountTransactionsTableCustomisation);