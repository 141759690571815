import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class iGoPortalActivationService implements CanActivate {

    public constructor(
        private userService: UserService,
    ) { }

    public canActivate = (val): boolean | Observable<boolean> => {
        return this.userService.getCurrentUser().isIgo;
    };
};