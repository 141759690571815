// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        .disputes-charts ghost-pie-chart .ghost-pie-legend .items .item .item-value { color: white !important; }
    `, "",{"version":3,"sources":["webpack://./src/shared/components/activity-disputes/activity-disputes-charts/activity-disputes-charts.component.ts"],"names":[],"mappings":";QACQ,8EAA8E,uBAAuB,EAAE","sourcesContent":["\n        .disputes-charts ghost-pie-chart .ghost-pie-legend .items .item .item-value { color: white !important; }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
