// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
}

.transactions-header {
    display: flex;
    flex: 1 1 auto !important;
    justify-content: space-between;
}

.table-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem !important;
}

.exporting-buttons {
    display: flex;
    gap: 15px;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/account-transactions/account-transactions.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;IACd,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,SAAS;AACb","sourcesContent":[":host {\n    display: flex;\n    flex: 1 1 auto;\n    flex-direction: column;\n}\n\n.transactions-header {\n    display: flex;\n    flex: 1 1 auto !important;\n    justify-content: space-between;\n}\n\n.table-options {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin: 0.5rem !important;\n}\n\n.exporting-buttons {\n    display: flex;\n    gap: 15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
