import { AfterViewInit, Component, HostListener, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { Clipboard } from '@angular/cdk/clipboard';
import { CoordinateApi, LatLng, OperatorMapAreaView } from "src/models";
import { IPortalClassTypes } from "src/interfaces/portal-class-types.interface";
import { Keys } from "src/enums/keys.enum";
import { DownloadService, MapboxService, SharedModalsService, StorageService } from "src/services";
import MapboxDraw, { DrawCreateEvent, DrawDeleteEvent, DrawSelectionChangeEvent, DrawUpdateEvent } from '@mapbox/mapbox-gl-draw';
import mapboxgl from 'mapbox-gl';
import { SnackbarService } from "src/services/snackbar.service";
import styles from "src/shared/assets/json/polygon-map-styles.json";
import * as turf from '@turf/turf';
@Component({
    selector: 'operator-coverage-builder-map',
    styleUrls: ['operator-coverage-builder-map.component.css'],
    templateUrl: './operator-coverage-builder-map.component.html'
})

export class OperatorCoverageBuilderMapComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @HostListener('keydown', ['$event']) private onKeyDown(event: KeyboardEvent) {
        this.shiftKeyPressed = event.keyCode == Keys.Shift ? true : false;
    };
    @HostListener('keyup', ['$event']) private onKeyUp(event: KeyboardEvent) {
        this.shiftKeyPressed = event.keyCode == Keys.Shift ? false : true;
    };
    @Input() public cabExchangeVendorId: string;
    @Input() public canDraw: boolean = true;
    @Input() public class: IPortalClassTypes = 'default';
    @Input() public coordinate: CoordinateApi;
    @Input() public mapId: string = 'map';
    @Input() public operatorName: string;
    @Input() public set operatorCoverageCopy(value: OperatorMapAreaView[]) {
        this._operatorCoverageCopy = value;
    };

    public get operatorCoverageCopy(): OperatorMapAreaView[] {
        return this._operatorCoverageCopy;
    };

    public draw = null;
    public pointHasSnapped: boolean = false;

    private map: mapboxgl.Map;
    private marker: mapboxgl.Marker;
    private pointSnappedTimer: any;
    private shiftKeyPressed: boolean = false;
    public originatorPortalUser: boolean = false;

    private _operatorCoverageCopy: OperatorMapAreaView[];

    public constructor(
        private clipboard: Clipboard,
        private mapboxService: MapboxService,
        private sharedModalsService: SharedModalsService,
        private snackbarService: SnackbarService,
        private downloadService: DownloadService
    ) { };

    public ngOnInit(): void {
        if (StorageService.getItem('originator')) {
            this.originatorPortalUser = true;
        }
    }

    public ngAfterViewInit(): void {
        this.draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: Boolean(this.canDraw),
                trash: Boolean(this.canDraw)
            },
            userProperties: true,
            styles: styles
        });

        this.buildMap();

        // Adding a new polygon
        this.map.on('draw.create', (e: DrawCreateEvent): void => {
            if (this.canDraw) {
                const generatedAreaId = e.features[0].id;
                let newCoverageArea = new OperatorMapAreaView({ ...new OperatorMapAreaView(), coordinates: [] });
                newCoverageArea.id = generatedAreaId.toString();
                newCoverageArea.coordinates = e.features[0].geometry['coordinates'][0].map((coords: number[]) => {
                    let latLng = new LatLng({ lat: coords[1], lng: coords[0] });
                    return this.checkNearestVerticesAndSnapToPoint(latLng, newCoverageArea);
                });
                this.operatorCoverageCopy.push(newCoverageArea);
            };
        });

        // Deleting a polygon using the draw tools
        this.map.on('draw.delete', (e: DrawDeleteEvent): void => {
            for (let i = 0; i < e.features.length; i++) {
                let featureId = e.features[i].id;
                let index = this.operatorCoverageCopy.findIndex(coverageArea => coverageArea.id == featureId);
                if (index >= 0) {
                    this.operatorCoverageCopy.splice(index, 1);
                };
            };
        });

        // Updating a polygon
        this.map.on('draw.update', (e: DrawUpdateEvent): void => {
            const coverageAreaIndex = this.operatorCoverageCopy.findIndex(coverageArea => coverageArea.id == e.features[0].id); //Index of the coverage area we edited
            const newCoverageArea = e.features[0].geometry['coordinates'][0].map(coords => new LatLng({ lat: coords[1], lng: coords[0] })); // The feature from the map event, mapped to an array of LatLng coordinates

            // Did every point on the polygon move? (i.e. was the polygon dragged?)
            const hasMovedAllPoints = (): boolean => {
                const movedCoords = this.operatorCoverageCopy[coverageAreaIndex].coordinates.filter(x => newCoverageArea.findIndex(i => i.lat == x.lat && i.lng == x.lng) < 0);
                return newCoverageArea.length - movedCoords.length <= 1; // Sometimes polygons include the same coords for the first & last point, so the length could be higher by 1
            };

            // If we can draw on the map, and we're editing coordinates, and we didn't move all of them
            if (this.canDraw && e.action == 'change_coordinates' && !hasMovedAllPoints()) {
                this.operatorCoverageCopy[coverageAreaIndex].coordinates = e.features[0].geometry['coordinates'][0].map(coords => {
                    const latLng = new LatLng({ lat: coords[1], lng: coords[0] }); // Map the feature coordinates to an array of LatLng coordinates
                    return this.checkNearestVerticesAndSnapToPoint(latLng, this.operatorCoverageCopy[coverageAreaIndex]); // Snap the points together with any nearby polygon vertices
                });
                this.drawMapAreaAsPolygon(this.operatorCoverageCopy[coverageAreaIndex], false);
            } else {
                this.redrawMapAreas(this.operatorCoverageCopy); // The polygon was moved. Revert back to the original positions.
            };
        });

        // Selecting a polygon
        this.map.on('draw.selectionchange', (e: DrawSelectionChangeEvent): void => {
            if (e.features.length > 0) {
                let hasSelectedPoint = e.points?.[0]?.geometry?.type == 'Point';
                this.onSelectMapArea(e.features, hasSelectedPoint);
            } else {
                this.onSelectMapArea();
            };
        });
    };

    public ngOnChanges(): void {
        if (this.map) {
            if (this.validCoordinate()) {
                this.setMarker();
            } else {
                if (this.marker) {
                    this.marker.remove();
                    this.marker = null;
                };
            };
            this.moveCenter();
        };
    };

    public ngOnDestroy(): void {
        this.map.remove();
    };

    public fitMapBoundsToAllFeatures(): void {
        const allFeatures = this.draw.getAll().features;
        const allCoordinates = allFeatures.map(i => i.geometry['coordinates'][0]).flat();
        const bounds = allCoordinates.reduce((bounds, coord) => {
            return bounds.extend(coord);
        }, new mapboxgl.LngLatBounds(allCoordinates[0], allCoordinates[0]));
        this.map.fitBounds(bounds, {
            offset: [0, 0],
            padding: 100
        });
    };

    public onCopyAllCoverageAreasAsJson(): void {
        this.clipboard.copy(JSON.stringify(this.operatorCoverageCopy));
        this.snackbarService.showSnackbar('global.modals.copied');
    };

    public onCopyCoverageAreaAsJson(): void {
        const coverageAreas = this.operatorCoverageCopy.filter(i => i.selected);
        if (coverageAreas.length > 0) {
            this.clipboard.copy(JSON.stringify(coverageAreas.map(i => new OperatorMapAreaView(i))));
            this.snackbarService.showSnackbar('global.modals.copied');
        };
    };

    public onDownloadCoverageAreaAsGeoJson(): void {
        const coverageAreasAsGeoJson = { "type": "FeatureCollection", "features": [] };

        this.operatorCoverageCopy.forEach(coverage => {
            const coordinates = [];
            let geoJson = {};
            const coverageArea = [...coverage.coordinates].reverse();
            coverageArea.forEach(coords => {
                coordinates.push(Object.values(coords).reverse());
            });
            coordinates.push(coordinates[0]);
            geoJson['type'] = "Feature";
            geoJson['geometry'] = {
                "type": "Polygon", "coordinates": [coordinates]
            };
            geoJson['properties'] = { "name": this.cabExchangeVendorId ? (this.operatorName + ` (${this.cabExchangeVendorId})`) : this.operatorName };
            coverageAreasAsGeoJson.features.push(geoJson);
        });
        this.downloadService.downloadFile({ fileName: this.operatorName }, JSON.stringify(coverageAreasAsGeoJson), 'geojson');
    };

    public onDeleteMapArea(coverageArea: OperatorMapAreaView, index: number): void {
        this.draw.delete(coverageArea.id);
        this.operatorCoverageCopy.splice(index, 1);
    };

    public onFocusMapArea(coverageArea: OperatorMapAreaView): void {
        this.operatorCoverageCopy.forEach(i => i.selected = false);
        coverageArea.selected = true;
        this.moveToCentreOfMapArea(coverageArea.id);
        this.draw.changeMode('simple_select', { featureIds: [coverageArea.id] });
    };

    public onShowImportAsJsonModal(): void {
        this.sharedModalsService.showParseJsonInputModal({ windowClass: this.class }).result.then((coverageAreas: OperatorMapAreaView[]) => {
            const allAreas = [...this.operatorCoverageCopy, ...[coverageAreas].flat()];
            this.operatorCoverageCopy = allAreas.reduce((uniqueCoverageAreas, area) => uniqueCoverageAreas.find(i => i.id == area.id) ? uniqueCoverageAreas : [...uniqueCoverageAreas, area], []);
            this.redrawMapAreas(this.operatorCoverageCopy);
        }).catch(() => { });
    };

    public moveCenter(coordinate?: CoordinateApi): void {
        if (coordinate?.latitude && coordinate?.longitude) {
            this.map.flyTo({
                center: [coordinate.longitude, coordinate.latitude],
                offset: [0, 0],
                speed: 3,
                zoom: 12
            });
        };
    };

    public moveToCentreOfMapArea(mapAreaId: string): void {
        const coordinates = this.draw.get(mapAreaId).geometry['coordinates'][0];
        const bounds = coordinates.reduce((bounds, coord) => {
            return bounds.extend(coord);
        }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
        this.map.fitBounds(bounds, {
            offset: [0, 0],
            padding: 200
        });
    };

    public redrawMapAreas(coverageAreas: OperatorMapAreaView[]): void {
        coverageAreas.forEach(coverageArea => {
            this.drawMapAreaAsPolygon(coverageArea, false);
        });
        this.fitMapBoundsToAllFeatures();
    };

    public resize(): void {
        this.map.resize();
    };

    public validCoordinate = (): boolean => Boolean(this.coordinate.latitude && this.coordinate.longitude);

    private buildMap(): void {
        this.map = new mapboxgl.Map({
            center: [this.coordinate.longitude, this.coordinate.latitude],
            container: this.mapId,
            style: (<any>this.mapboxService.AUTOCAB_STYLE),
            zoom: 12
        });
        this.map.addControl(new mapboxgl.NavigationControl());
        this.map.addControl(this.draw);
        this.map.dragRotate.disable();
    };

    private checkNearestVerticesAndSnapToPoint(coords: LatLng, coverageArea: OperatorMapAreaView): LatLng {
        let features = this.draw.getAll().features;
        if (features.find(feature => feature.id == coverageArea.id)) {
            features.splice(features.findIndex(feature => feature.id == coverageArea.id), 1);
        };
        // For each point on the selected polygon, find the nearest point on all other polygons
        for (let i = 0; i < features.length; i++) {
            let polygon = turf.polygon(features[i].geometry['coordinates']);
            let vertices = turf.explode(polygon);
            let closestVertex = turf.nearest(turf.point([coords.lng, coords.lat]), vertices);
            let positionOfCoordinates = this.map.project({ lng: turf.point([coords.lng, coords.lat]).geometry.coordinates[1], lat: turf.point([coords.lng, coords.lat]).geometry.coordinates[0] });
            let positionOfNearestPoint = this.map.project({ lng: closestVertex.geometry.coordinates[1], lat: closestVertex.geometry.coordinates[0] });
            // If a point on the selected polygon has been dragged to within a 7px radius of another polygon's vertex, snap the points together
            if (Math.abs(Math.round(positionOfNearestPoint.x) - positionOfCoordinates.x) <= 7 && Math.abs(Math.round(positionOfNearestPoint.y) - positionOfCoordinates.y) <= 7) {
                if (coords.lat !== closestVertex.geometry.coordinates[1] && coords.lng !== closestVertex.geometry.coordinates[0]) {
                    this.showPointHasSnapped();
                };
                coords.lat = closestVertex.geometry.coordinates[1];
                coords.lng = closestVertex.geometry.coordinates[0];
                break;
            };
        };
        return coords;
    };

    private drawMapAreaAsPolygon(coverageArea: OperatorMapAreaView, moveToMapArea?: boolean): void {
        let coords: number[][] = coverageArea.coordinates.map(coord => [coord.lng, coord.lat]);
        // If the last coordinates in the map area are not the same as the first coordinates, add them to make a complete polygon that starts and ends on the same point
        if (coords[coords.length - 1][0] !== coords[0][0] && coords[coords.length - 1][1] !== coords[0][1]) {
            coords.push([coverageArea.coordinates[0].lat, coverageArea.coordinates[0].lng]);
        };
        this.draw.add({
            id: coverageArea.id,
            type: "Feature",
            properties: {},
            geometry: {
                type: "Polygon",
                coordinates: [coords],
            }
        });
        if (moveToMapArea) {
            setTimeout(() => {
                this.moveToCentreOfMapArea(coverageArea.id);
            }, 50);
        };
    };

    private onSelectMapArea(polygons?: any[], hasSelectedPoint?: boolean): void {
        this.operatorCoverageCopy.forEach(i => i.selected = false);
        if (polygons?.length) {
            polygons.forEach(polygon => {
                let operatorCoverageArea = this.operatorCoverageCopy.find(i => i.id == polygon.id);
                if (operatorCoverageArea) {
                    operatorCoverageArea.selected = true;
                    const element = document.getElementById(operatorCoverageArea.id);
                    element && element.scrollIntoView();
                };
            });
        };
    };

    private setMarker(): void {
        this.marker && this.marker.setLngLat([this.coordinate.longitude, this.coordinate.latitude]);
    };

    private showPointHasSnapped() {
        this.pointHasSnapped = false;
        setTimeout(() => {
            clearTimeout(this.pointSnappedTimer);
            this.pointHasSnapped = true;
            this.pointSnappedTimer = setTimeout(() => {
                this.pointHasSnapped = false;
            }, 5000);
        }, 250);
    };
};