import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'remove-row-cell',
    template: `<i class="fal fa-times-circle"></i>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [`
        :host { align-items: center; display: flex; flex-flow: column; justify-content: center; height: 100%; width: 100%; }
        :host:not(.gvs-action-cell) { opacity: .5; }
        :host.gvs-action-cell:hover { background-color: rgb(42 42 42 / 20%) !important; transition: 0.2s ease-in-out; }
    `]
})
export class RemoveRowCellTplComponent {
    @Input() public set row(item: any) {
        if (item) {
            this.cd.detectChanges();
        };
    };
    public constructor(private cd: ChangeDetectorRef) { };
};