import { Injectable } from '@angular/core';
import {
    CXMarketplaceApi,
    CXMarketplaceView,
    CreateCXMarketplacePayload,
    GetAllCXMarketplacesResponseApi,
    GetAllCXMarketplacesResponseView,
    GetConnectedCXMarketplacesResponseApi,
    UpdateCXMarketplacePayload,
} from 'src/models/marketplace.model';
import { Endpoints } from 'src/shared/endpoints';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RequestService } from './request.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class MarketplacesService {
    public constructor(
        private http: RequestService
    ) { };

    public createCXMarketplaceAsync(url: string, payload: CreateCXMarketplacePayload): Observable<CXMarketplaceApi> {
        payload.agentId = UserService.getPartnerTypeDetails().id;
        return this.http.authenticatedPost<CXMarketplaceApi>(url, new CreateCXMarketplacePayload(payload), {}, 'global.request.creating_marketplace').pipe(
            map(res => new CXMarketplaceApi(res))
        );
    };

    public getConnectedCXMarketplacesObservable(): Observable<GetConnectedCXMarketplacesResponseApi> {
        return this.http.authenticatedGet<GetConnectedCXMarketplacesResponseApi>(Endpoints.API_URL + Endpoints.MARKETPLACES._CX_MARKETPLACES + Endpoints.MARKETPLACES._CONNECTED, {}, 'global.request.loading_connected_marketplaces').pipe(
            map(res => new GetConnectedCXMarketplacesResponseApi(res))
        );
    };

    public getCXMarketplacesObservable(url: string): Observable<GetAllCXMarketplacesResponseView> {
        return this.http.authenticatedGet<GetAllCXMarketplacesResponseApi>(url, {}, 'global.request.loading_marketplaces').pipe(
            map(res => new GetAllCXMarketplacesResponseView(res as GetAllCXMarketplacesResponseView))
        );
    };

    public getCXMarketplaceByNameObservable(marketplace: string): Observable<CXMarketplaceView> {
        return this.http.authenticatedGet<CXMarketplaceApi>(Endpoints.API_URL + Endpoints.MARKETPLACES._CX_MARKETPLACES + `/${marketplace}`, {}, 'global.request.loading_marketplaces').pipe(
            map(res => new CXMarketplaceView(res as CXMarketplaceView))
        );
    };

    public updateMarketplaceAsync(marketplace: CXMarketplaceView): Observable<CXMarketplaceView> {
        return this.http.authenticatedPut<CXMarketplaceApi>(marketplace._links.UpdateCabExchangeMarketplace.href, new UpdateCXMarketplacePayload(marketplace), {}, 'global.request.updating_marketplace').pipe(
            map(res => new CXMarketplaceView(res as CXMarketplaceView))
        );
    };
};