import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { SettingsService } from '../../services';

@Pipe({ name: 'genericDateTimePipe', pure: false })
export class GenericDateTimePipe implements PipeTransform {
    public transform(value: string, format?: string): string {
        let date = new DatePipe(SettingsService.LanguageCode).transform(value, 'shortDate');
        let time = new DatePipe(SettingsService.LanguageCode).transform(value, format || 'mediumTime');
        return date && time ? date + ' ' + time : '';
    };
};