import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from "@angular/core";
import { ActivationEnd, Router } from "@angular/router";
import { ArrayUtilities } from "src/shared/array.utilities";
import { CellClickEvent, TableComponent, TableCustomisation } from "@autocab/ghost-vs-table";
import { CreateCXConnectionModalComponent } from "src/app/igo-portal/igo-network/modals/create-connection/create-cx-connection.modal";
import { CXConnectionView, CXMarketplaceView, CXVendorView, GetAllCXMarketplacesResponseView } from "src/models";
import { CXMarketplacesTableCustomisation } from "./cx-marketplaces-table.customisation";
import { EditCxMarketplaceModalComponent } from "src/modules/shared-modals/modals/edit-cx-marketplace/edit-cx-marketplace.modal";
import { filter } from "rxjs/operators";
import { FilterService, iGoCompaniesService, MarketplacesService, ModalService, SharedModalsService, ToastService, UserService } from "src/services";
import { HttpErrorResponse } from "@angular/common/http";
import { ITableFooterItem } from './../ghost-vs-table-footer/ghost-vs-table-footer.component';
import { IPortalClassTypes } from "src/interfaces/portal-class-types.interface";
import { Subscription, lastValueFrom } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'cx-marketplaces',
    templateUrl: './cx-marketplaces.component.html'
})
export class CXMarketplacesComponent implements AfterViewInit, OnDestroy {
    @ViewChild(TableComponent) public table: TableComponent;
    @Input() public class: IPortalClassTypes = 'primary';
    public cxMarketplacesResult: GetAllCXMarketplacesResponseView = null;
    public cxMarketplacesTableCustomisation: TableCustomisation<CXMarketplaceView> = CXMarketplacesTableCustomisation;
    public filterSearchTerm: string = '';
    public getMarketplacesUrl: string = '';
    public hasPermission: boolean = true;
    public loading: boolean = false;
    public loadingModalData: boolean = false;
    public rows: CXMarketplaceView[] = [];
    public tableFooterItems: Array<ITableFooterItem>;
    public tempRows: CXMarketplaceView[] = [];
    private clickEventSubscription: Subscription;

    public constructor(
        private filterService: FilterService,
        private iGoCompaniesService: iGoCompaniesService,
        private marketplacesService: MarketplacesService,
        private modalService: ModalService,
        private router: Router,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService,
        private toastService: ToastService,
        private userService: UserService,
    ) {
        this.router.events.pipe(
            filter(event => event instanceof ActivationEnd && event.snapshot.children.length == 0)
        ).subscribe({
            next: (event: ActivationEnd) => {
                const data = event.snapshot && event.snapshot.data;
                if (data) {
                    this.class = data.portalClass || 'igo';
                };
            }
        });
    };

    public ngAfterViewInit(): void {
        this.clickEventSubscription = this.table?.cellClick.subscribe({
            next: (clickEvent: CellClickEvent<CXMarketplaceView>) => {
                clickEvent.event.stopPropagation();
                clickEvent.columnKey === 'plus' && this.onCreateConnection(clickEvent.row);
            }
        });
        this.getMarketplacesUrl = this.userService.getUserHalLink('GetAllCabExchangeMarketplaces')?.href;
        this.hasPermission = !!this.getMarketplacesUrl;
        this.getMarketplacesUrl && this.initialise();
    };

    public ngOnDestroy(): void {
        this.clickEventSubscription?.unsubscribe();
    };

    public onCreateMarketplace(): void {
        this.sharedModalsService.showCreateCXMarketplaceModal(this.cxMarketplacesResult._links).result.then((res: CXMarketplaceView) => {
            this.addRowInTable(res);
        }).catch(() => { });
    };

    public onFilterChange($event?: string): void {
        const filteredRows = this.filterService.filterGhostVSTableResults(false, this.filterSearchTerm, this.tempRows, this.cxMarketplacesTableCustomisation);
        this.table.setRowsCollection(filteredRows, filteredRows, false, 0).then(() => this.rows = filteredRows);
    };

    public async onSelect(): Promise<void> {
        try {
            const [marketplace, { vendors }, { agents }] = await Promise.all([
                lastValueFrom(this.marketplacesService.getCXMarketplaceByNameObservable(this.cxMarketplacesTableCustomisation.selected.name)),
                lastValueFrom(this.iGoCompaniesService.getAllCXOperatorsObservable()),
                lastValueFrom(this.iGoCompaniesService.getAllCXOriginatorsObservable()),
            ]);
            const modalRef = this.modalService.open(EditCxMarketplaceModalComponent, { size: 'lg', windowClass: 'igo' });
            modalRef.componentInstance.allOperators = ArrayUtilities.sortAlphabetically(vendors, 'displayName');
            modalRef.componentInstance.allOriginators = ArrayUtilities.sortAlphabetically(agents, 'displayName');
            modalRef.componentInstance.marketplace = marketplace;
            modalRef.result.then(res => {
                this.updateRowById(res);
            }).catch(() => { });
        } catch (error) {
            this.onFailure(error);
        };
    };

    private addRowInTable(res: CXMarketplaceView): void {
        this.tempRows = this.tempRows.concat(res);
        this.rows = [...this.tempRows];
        this.table.setRowsCollection(this.tempRows, this.tempRows);
        this.filterSearchTerm && this.onFilterChange();
    };

    private initialise(): void {
        this.recalculateData();
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.loading = false;
        this.loadingModalData = false;
        this.sharedModalsService.showServerValidationErrorsModal(res, this.translateService.instant('igo_portal.marketplaces.marketplaces'));
    };

    private onInitialiseData = (res: GetAllCXMarketplacesResponseView): void => {
        this.loading = false;
        this.cxMarketplacesResult = res;
        this.tempRows = [...this.cxMarketplacesResult.marketplaces];
        this.rows = [...this.tempRows];
        this.class !== 'igo' && this.table.hideColumn('plus');
        this.tableFooterItems = [{ key: 'igo_portal.marketplaces.table.total_marketplaces', description: this.tempRows.length }];
    };

    private recalculateData(): void {
        this.loading = true;
        this.marketplacesService.getCXMarketplacesObservable(this.getMarketplacesUrl).subscribe({
            next: this.onInitialiseData,
            error: this.onFailure
        });
    };

    private async onCreateConnection(marketplace: CXMarketplaceView) {
        if (!this.loadingModalData && this.cxMarketplacesResult._links.AddCabExchangeMarketplaceOperator) {
            this.loadingModalData = true;
            try {
                const { vendors } = await lastValueFrom(this.iGoCompaniesService.getAllCXOperatorsObservable());
                this.showCreateConnectionModal(vendors, marketplace);
            } catch (error) {
                this.onFailure(error);
            };
        };
    };

    private showCreateConnectionModal = (vendors: CXVendorView[], marketplace: CXMarketplaceView): void => {
        this.loadingModalData = false;
        const modalRef = this.modalService.open(CreateCXConnectionModalComponent, { size: 'sm', windowClass: 'igo' });
        modalRef.componentInstance.allOperators = vendors;
        modalRef.componentInstance.payloadView.name = marketplace.name;
        modalRef.componentInstance.showMarketplace = false;
        modalRef.result.then((res: CXConnectionView[]) => {
            const totalNewConnections = res.length;
            const successMessage = this.translateService.instant(totalNewConnections == 1 ? 'igo_portal.network.create_connection.new_connection' : 'igo_portal.network.create_connection.new_connections');
            this.toastService.setToastMessage(`${totalNewConnections} ${successMessage}`);
        }).catch(() => { });
    };

    private updateRowById(res: CXMarketplaceView): void {
        if (this.rows.find(i => i.name == res.name)) {
            this.table.updateRow(res);
            Object.assign(this.tempRows.find(i => i.name == res.name), res);
        };
        this.rows = [...this.tempRows];
        this.filterSearchTerm && this.onFilterChange();
    };
};
