import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IPortalClassTypes } from 'src/interfaces/portal-class-types.interface';

export interface ITableFooterItem {
    key: string;
    description: string | number;
};

@Component({
    selector: 'ghost-vs-table-footer',
    templateUrl: 'ghost-vs-table-footer.component.html',
})

export class GhostVSTableFooterComponent {
    @Input() public class: IPortalClassTypes;
    @Input() public hideNextPageButton: boolean = false;
    @Input() public items: Array<ITableFooterItem>;
    @Input() public showCountSpinner: boolean = false;
    @Input() public showLoadingMoreSpinner: boolean = false;
    @Input() public showPageSizeDropDown: boolean = false;
    @Output() public onLoadMore: EventEmitter<any> = new EventEmitter();
    @Output() public onPageSizeUpdate: EventEmitter<any> = new EventEmitter();

    public pageSizes = [25, 50, 100, 200];
    public selectedPageSize: number = 100;

    public onClickNextPage() {
        !this.showLoadingMoreSpinner && this.onLoadMore.emit();
    };

    public onSelectPageSize(value: number) {
        value && this.onPageSizeUpdate.emit(value);
    };
};