// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        .form-group-section-header.text-center > div { justify-content: center; }
    `, "",{"version":3,"sources":["webpack://./src/app/standalone/form-group-section-header/form-group-section-header.component.ts"],"names":[],"mappings":";QACQ,+CAA+C,uBAAuB,EAAE","sourcesContent":["\n        .form-group-section-header.text-center > div { justify-content: center; }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
