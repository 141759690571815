// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header.form-errors-header,
.modal.submodal .modal-header.form-errors-header {
    background-color: var(--bs-danger);
}

.modal-header.form-errors-header .close,
.modal.submodal .modal-header.form-errors-header .close {
    color: white;
}

.modal-header.form-errors-header .modal-title {
    border-bottom: 2px solid rgb(var(--primary-white));
    color: rgb(var(--primary-white));
}

.modal-body.form-errors-body {
    background-color: rgb(var(--tertiary-white));
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    padding: 0;
}

.modal-body .validation-messages {
    color: var(--bs-danger);
    max-height: 600px;
    min-height: 105px;
    overflow-y: auto;
    padding: 20px 20px 30px 20px;
    width: 100%;
}

.modal-body .validation-messages .error {
    border-bottom: 1px solid var(--bs-danger);
    display: block;
    padding: 10px 0;
}

.modal-body .validation-messages .server-error {
    border-bottom: 1px solid var(--bs-danger);
    padding: 10px 0;
}

.modal-body .validation-messages .server-error .error-message {
    white-space: pre-wrap;
}

.modal-body .validation-messages .label-text {
    font-family: ZonaProBold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/styles/validation.css"],"names":[],"mappings":"AAAA;;IAEI,kCAAkC;AACtC;;AAEA;;IAEI,YAAY;AAChB;;AAEA;IACI,kDAAkD;IAClD,gCAAgC;AACpC;;AAEA;IACI,4CAA4C;IAC5C,+BAA+B;IAC/B,gCAAgC;IAChC,UAAU;AACd;;AAEA;IACI,uBAAuB;IACvB,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;IAChB,4BAA4B;IAC5B,WAAW;AACf;;AAEA;IACI,yCAAyC;IACzC,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,yCAAyC;IACzC,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,wBAAwB;IACxB,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".modal-header.form-errors-header,\n.modal.submodal .modal-header.form-errors-header {\n    background-color: var(--bs-danger);\n}\n\n.modal-header.form-errors-header .close,\n.modal.submodal .modal-header.form-errors-header .close {\n    color: white;\n}\n\n.modal-header.form-errors-header .modal-title {\n    border-bottom: 2px solid rgb(var(--primary-white));\n    color: rgb(var(--primary-white));\n}\n\n.modal-body.form-errors-body {\n    background-color: rgb(var(--tertiary-white));\n    border-bottom-left-radius: 1rem;\n    border-bottom-right-radius: 1rem;\n    padding: 0;\n}\n\n.modal-body .validation-messages {\n    color: var(--bs-danger);\n    max-height: 600px;\n    min-height: 105px;\n    overflow-y: auto;\n    padding: 20px 20px 30px 20px;\n    width: 100%;\n}\n\n.modal-body .validation-messages .error {\n    border-bottom: 1px solid var(--bs-danger);\n    display: block;\n    padding: 10px 0;\n}\n\n.modal-body .validation-messages .server-error {\n    border-bottom: 1px solid var(--bs-danger);\n    padding: 10px 0;\n}\n\n.modal-body .validation-messages .server-error .error-message {\n    white-space: pre-wrap;\n}\n\n.modal-body .validation-messages .label-text {\n    font-family: ZonaProBold;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
