// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
    color: white;
    height: -moz-fit-content;
    height: fit-content;
    pointer-events: none;
    position: absolute;
    right: 20px;
    top: 120px;
    width: 250px;
    z-index: 9999;
}

:host .toast-content {
    background-color: transparent;
    border-radius: 5px;
    border-bottom-right-radius: 20px;
    padding: 10px;
}

:host .toast-content.danger {
    background-color: #dc3545;
}

:host .toast-content.info {
    background-color: #0dcaf0;
    color: black;
}

:host .toast-content.success {
    background-color: #198754;
}

:host .toast-content.warning {
    background-color: #ffc107;
    color: black;
}

:host .toast-content i {
    pointer-events: initial;
}

:host .toast-content.show {
    animation: slideIn 0.5s forwards;
}

:host .toast-content.hide {
    animation: slideOut 0.5s forwards;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/toast/toast.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,wBAAmB;IAAnB,mBAAmB;IACnB,oBAAoB;IACpB,kBAAkB;IAClB,WAAW;IACX,UAAU;IACV,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,gCAAgC;IAChC,aAAa;AACjB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":[":host {\n    color: white;\n    height: fit-content;\n    pointer-events: none;\n    position: absolute;\n    right: 20px;\n    top: 120px;\n    width: 250px;\n    z-index: 9999;\n}\n\n:host .toast-content {\n    background-color: transparent;\n    border-radius: 5px;\n    border-bottom-right-radius: 20px;\n    padding: 10px;\n}\n\n:host .toast-content.danger {\n    background-color: #dc3545;\n}\n\n:host .toast-content.info {\n    background-color: #0dcaf0;\n    color: black;\n}\n\n:host .toast-content.success {\n    background-color: #198754;\n}\n\n:host .toast-content.warning {\n    background-color: #ffc107;\n    color: black;\n}\n\n:host .toast-content i {\n    pointer-events: initial;\n}\n\n:host .toast-content.show {\n    animation: slideIn 0.5s forwards;\n}\n\n:host .toast-content.hide {\n    animation: slideOut 0.5s forwards;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
