import { Injectable } from '@angular/core';
import { LoadingService } from './loading.service';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HttpInterceptorHandlerService {

    private cancePendingHTTPRequests$ = new Subject<void>();

    public constructor(private loadingService: LoadingService) { };

    // Cancel Pending HTTP calls
    public cancelPendingRequests() {
        this.loadingService.display({ show: false });
        this.cancePendingHTTPRequests$.next(null);
    };

    public onCancelPendingRequests() {
        return this.cancePendingHTTPRequests$.asObservable();
    };
}