// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 25px;
}

.stacked-icons-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
}

.stacked-icons-wrapper .stacked-icon {
    position: absolute;
    transform: translateX(0px);
    transition: all 0.3s ease;
}

.stacked-icons-wrapper .child-icon {
    position: absolute;
    opacity: 0.4;
}`, "",{"version":3,"sources":["webpack://./src/app/standalone/stacked-arrow-left-icons/stacked-arrow-left-icons.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,0BAA0B;IAC1B,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[":host {\n    align-items: center;\n    display: flex;\n    height: 100%;\n    justify-content: center;\n    position: relative;\n    width: 25px;\n}\n\n.stacked-icons-wrapper {\n    align-items: center;\n    display: flex;\n    justify-content: center;\n}\n\n.stacked-icons-wrapper .stacked-icon {\n    position: absolute;\n    transform: translateX(0px);\n    transition: all 0.3s ease;\n}\n\n.stacked-icons-wrapper .child-icon {\n    position: absolute;\n    opacity: 0.4;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
