// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inner-sidebar {
    background-color: rgb(var(--igo-dark-grey));
    box-shadow: 0px 15px 30px 0px rgba(var(--igo-dark-grey), 0.3);
    color: rgb(var(--primary-white));
    font-family: ZonaProLight, Arial, Helvetica, sans-serif;
    font-size: 14px;
    height: 100%;
    position: fixed;
    width: 225px;
}

.inner-sidebar ul {
    padding-left: 0;
    padding-top: 70px;
}

.inner-sidebar li {
    cursor: pointer;
    list-style-type: none;
}

.inner-sidebar li a {
    color: rgb(var(--primary-white));
    display: block;
    padding: 30px;
    text-decoration: none;
}

.inner-sidebar .active-link {
    background-color: rgba(255, 255, 255, 0.25);
    color: rgb(var(--primary-white));
    font-family: ZonaProBold, Arial, Helvetica, sans-serif;
}

.inner-sidebar .active-link .active-link-icon {
    float: right;
    margin-top: 3px;
}

.inner-sidebar-content {
    height: 100%;
    margin-left: 225px;
    width: calc(100% - 225px);
    overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/styles/innersidebar.css"],"names":[],"mappings":"AAAA;IACI,2CAA2C;IAG3C,6DAA6D;IAC7D,gCAAgC;IAChC,uDAAuD;IACvD,eAAe;IACf,YAAY;IACZ,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,gCAAgC;IAChC,cAAc;IACd,aAAa;IACb,qBAAqB;AACzB;;AAEA;IACI,2CAA2C;IAC3C,gCAAgC;IAChC,sDAAsD;AAC1D;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;AACpB","sourcesContent":[".inner-sidebar {\n    background-color: rgb(var(--igo-dark-grey));\n    -moz-box-shadow: 0px 15px 30px 0px rgba(var(--igo-dark-grey), 0.3);\n    -webkit-box-shadow: 0px 15px 30px 0px rgba(var(--igo-dark-grey), 0.3);\n    box-shadow: 0px 15px 30px 0px rgba(var(--igo-dark-grey), 0.3);\n    color: rgb(var(--primary-white));\n    font-family: ZonaProLight, Arial, Helvetica, sans-serif;\n    font-size: 14px;\n    height: 100%;\n    position: fixed;\n    width: 225px;\n}\n\n.inner-sidebar ul {\n    padding-left: 0;\n    padding-top: 70px;\n}\n\n.inner-sidebar li {\n    cursor: pointer;\n    list-style-type: none;\n}\n\n.inner-sidebar li a {\n    color: rgb(var(--primary-white));\n    display: block;\n    padding: 30px;\n    text-decoration: none;\n}\n\n.inner-sidebar .active-link {\n    background-color: rgba(255, 255, 255, 0.25);\n    color: rgb(var(--primary-white));\n    font-family: ZonaProBold, Arial, Helvetica, sans-serif;\n}\n\n.inner-sidebar .active-link .active-link-icon {\n    float: right;\n    margin-top: 3px;\n}\n\n.inner-sidebar-content {\n    height: 100%;\n    margin-left: 225px;\n    width: calc(100% - 225px);\n    overflow-y: auto;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
