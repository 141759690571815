// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h6 {
    color: rgb(var(--primary-white));
    font-family: ZonaProBold;
    letter-spacing: initial;
    text-transform: initial;
}

p {
    font-family: ZonaProLight;
}

.form-group {
    margin-left: auto;
    width: 300px;
}

.document {
    align-items: center;
    border: 1px solid #FFFFFF;
    border-radius: 100px;
    box-shadow: 12px 10px 21px #00000029;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.document.disabled {
    opacity: 0.5;
}

.document.selected {
    box-shadow: 12px 10px 21px #00000029;
    transition: all 0.3s ease-in-out;
}

.document .description {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
}

.document .details {
    min-width: max-content;
    padding: 25px 50px 25px 50px;
}

.document .details p {
    font-family: ZonaProRegular;
}

mat-label {
    color: rgb(var(--primary-white));
}`, "",{"version":3,"sources":["webpack://./src/modules/shared-modals/modals/document-selection/document-selection.modal.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,wBAAwB;IACxB,uBAAuB;IACvB,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,oBAAoB;IACpB,oCAAoC;IACpC,eAAe;IACf,gCAAgC;AACpC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,oCAAoC;IACpC,gCAAgC;AACpC;;AAEA;IACI,oBAAoB;IACpB,gBAAgB;IAChB,4BAA4B;IAC5B,qBAAqB;AACzB;;AAEA;IACI,sBAAsB;IACtB,4BAA4B;AAChC;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":["h6 {\n    color: rgb(var(--primary-white));\n    font-family: ZonaProBold;\n    letter-spacing: initial;\n    text-transform: initial;\n}\n\np {\n    font-family: ZonaProLight;\n}\n\n.form-group {\n    margin-left: auto;\n    width: 300px;\n}\n\n.document {\n    align-items: center;\n    border: 1px solid #FFFFFF;\n    border-radius: 100px;\n    box-shadow: 12px 10px 21px #00000029;\n    cursor: pointer;\n    transition: all 0.3s ease-in-out;\n}\n\n.document.disabled {\n    opacity: 0.5;\n}\n\n.document.selected {\n    box-shadow: 12px 10px 21px #00000029;\n    transition: all 0.3s ease-in-out;\n}\n\n.document .description {\n    display: -webkit-box;\n    overflow: hidden;\n    -webkit-box-orient: vertical;\n    -webkit-line-clamp: 5;\n}\n\n.document .details {\n    min-width: max-content;\n    padding: 25px 50px 25px 50px;\n}\n\n.document .details p {\n    font-family: ZonaProRegular;\n}\n\nmat-label {\n    color: rgb(var(--primary-white));\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
