// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        :host { display: flex; flex-flow: column; justify-content: center; height: 100%; padding: 0 20px; width: -moz-fit-content; width: fit-content; }
        :host.gvs-action-cell i { font-size: 20px; }
        :host.disabled { opacity: 0.5; cursor: not-allowed; }
        :host .status-box { align-items: center; display: flex; padding: 12px 30px; white-space: pre-line; }
    `, "",{"version":3,"sources":["webpack://./src/shared/table-cell-templates/test-emulator-update-status-cell-template.component.ts"],"names":[],"mappings":";QACQ,QAAQ,aAAa,EAAE,iBAAiB,EAAE,uBAAuB,EAAE,YAAY,EAAE,eAAe,EAAE,uBAAkB,EAAlB,kBAAkB,EAAE;QACtH,0BAA0B,eAAe,EAAE;QAC3C,iBAAiB,YAAY,EAAE,mBAAmB,EAAE;QACpD,oBAAoB,mBAAmB,EAAE,aAAa,EAAE,kBAAkB,EAAE,qBAAqB,EAAE","sourcesContent":["\n        :host { display: flex; flex-flow: column; justify-content: center; height: 100%; padding: 0 20px; width: fit-content; }\n        :host.gvs-action-cell i { font-size: 20px; }\n        :host.disabled { opacity: 0.5; cursor: not-allowed; }\n        :host .status-box { align-items: center; display: flex; padding: 12px 30px; white-space: pre-line; }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
