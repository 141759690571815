// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-container,
.ghost-area-chart {
    height: 100%;
    position: relative;
    width: 100%;
}

.model-color {
    border-radius: 3px;
    height: 12px;
    margin-right: 10px;
    width: 12px;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/ghost-area-chart/ghost-area-chart.component.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;IACZ,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,WAAW;AACf","sourcesContent":[".chart-container,\n.ghost-area-chart {\n    height: 100%;\n    position: relative;\n    width: 100%;\n}\n\n.model-color {\n    border-radius: 3px;\n    height: 12px;\n    margin-right: 10px;\n    width: 12px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
