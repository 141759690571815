// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-outline-danger {
    background: linear-gradient(90deg, rgb(255 0 0 / 40%), rgb(255 0 0 / 40%));
    background-repeat: no-repeat;
    transition: 0.1s all linear;
}

.btn-outline-danger:hover {
    color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #dc3545;
    background-color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/hold-to-delete-button/hold-to-delete-button.component.css"],"names":[],"mappings":"AAAA;IACI,0EAA0E;IAC1E,4BAA4B;IAC5B,2BAA2B;AAC/B;;AAEA;IACI,cAAc;AAClB;;AAEA;;;IAGI,cAAc;IACd,sBAAsB;AAC1B","sourcesContent":[".btn-outline-danger {\n    background: linear-gradient(90deg, rgb(255 0 0 / 40%), rgb(255 0 0 / 40%));\n    background-repeat: no-repeat;\n    transition: 0.1s all linear;\n}\n\n.btn-outline-danger:hover {\n    color: #dc3545;\n}\n\n.btn-outline-danger:not(:disabled):not(.disabled).active,\n.btn-outline-danger:not(:disabled):not(.disabled):active,\n.show>.btn-outline-danger.dropdown-toggle {\n    color: #dc3545;\n    background-color: #fff;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
