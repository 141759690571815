// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        :host { display: flex; flex-flow: column; height: 100%; justify-content: center; padding: 0 20px; width: 100%; }
        :host:not(.gvs-action-cell) { opacity: .5; }
    `, "",{"version":3,"sources":["webpack://./src/shared/table-cell-templates/boolean-cell-template.component.ts"],"names":[],"mappings":";QACQ,QAAQ,aAAa,EAAE,iBAAiB,EAAE,YAAY,EAAE,uBAAuB,EAAE,eAAe,EAAE,WAAW,EAAE;QAC/G,8BAA8B,WAAW,EAAE","sourcesContent":["\n        :host { display: flex; flex-flow: column; height: 100%; justify-content: center; padding: 0 20px; width: 100%; }\n        :host:not(.gvs-action-cell) { opacity: .5; }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
