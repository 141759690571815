// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ngb-popover-window.popover.validation-popover {
    animation: fadeIn 0.2s;
    font-family: inherit;
    font-size: 14px;
    max-width: unset;
    background-color: #dc3545;
}

ngb-popover-window.popover.validation-popover .arrow::after, .bs-popover-top .arrow::after {
    border-top-color: #dc3545;
}

ngb-popover-window.popover.validation-popover .popover-body {
    background-color: #dc3545;
    border: none;
    border-radius: .3rem;
    color: white;
    padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/styles/popover.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,oBAAoB;IACpB,YAAY;IACZ,YAAY;AAChB","sourcesContent":["ngb-popover-window.popover.validation-popover {\n    animation: fadeIn 0.2s;\n    font-family: inherit;\n    font-size: 14px;\n    max-width: unset;\n    background-color: #dc3545;\n}\n\nngb-popover-window.popover.validation-popover .arrow::after, .bs-popover-top .arrow::after {\n    border-top-color: #dc3545;\n}\n\nngb-popover-window.popover.validation-popover .popover-body {\n    background-color: #dc3545;\n    border: none;\n    border-radius: .3rem;\n    color: white;\n    padding: 5px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
