import { Injectable } from '@angular/core';
import { Endpoints } from '../shared/endpoints';
import {
    GetAllRegionsResponseView,
    GetAllRegionsResponseApi,
    RegionApi,
    RegionView,
} from '../models/region.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RequestService } from './request.service';

@Injectable({ providedIn: 'root' })
export class RegionsService {
    public regions: GetAllRegionsResponseView = null;

    public constructor(
        private http: RequestService,
    ) { };

    public getRegions = (): GetAllRegionsResponseView => this.regions;

    public createRegionAsync(region: RegionView): Observable<RegionView> {
        return this.http.authenticatedPost<RegionApi>(Endpoints.API_URL + Endpoints.REGIONS, new RegionApi(region), {}, 'global.request.creating_region').pipe(
            map(res => new RegionView(res as RegionView))
        );
    };

    public deleteRegionAsync(regionId: string): Observable<string> {
        return this.http.authenticatedDelete<void>(Endpoints.API_URL + Endpoints.REGIONS + `/${regionId}`, {}, 'global.request.deleting_region').pipe(
            map(res => regionId)
        );
    };

    public getRegionByIdObservable(regionId: string): Observable<RegionView> {
        return this.http.authenticatedGet<RegionApi>(Endpoints.API_URL + Endpoints.REGIONS + `/${regionId}`, {}, 'global.request.loading_region').pipe(
            map(res => new RegionView(res as RegionView))
        );
    };

    public getAllRegionsObservable(): Observable<void> {
        return this.http.authenticatedGet<GetAllRegionsResponseApi>(Endpoints.API_URL + Endpoints.REGIONS, {}, 'global.request.loading_regions').pipe(
            map(res => {
                this.regions = new GetAllRegionsResponseView(res as GetAllRegionsResponseView);
            })
        );
    };

    public updateRegionAsync(region: RegionView): Observable<RegionView> {
        return this.http.authenticatedPut<RegionApi>(region._links.UpdateRegion.href, new RegionApi(region), {}, 'global.request.updating_region').pipe(
            map(res => new RegionView(res as RegionView))
        );
    };
};