export class ArrayUtilities {
    public static deepAccessUsingString = (obj, key) => {
        if (obj && key) {
            return key.split('.').reduce((nestedObject, key) => {
                if (nestedObject && key in nestedObject) {
                    return nestedObject[key];
                };
                return undefined;
            }, obj);
        };
        return null;
    };

    public static filterMultipleProperties<T, K extends keyof T>(array: T[], props: K[], string: string): T[] {
        let filtered: T[] = [];
        let filterString = string.toLowerCase();
        for (let i = 0; i < array.length; i++) {
            for (let j = 0; j < props.length; j++) {
                let val = ArrayUtilities.deepAccessUsingString(array[i], props[j]);
                if ((val || val === 0) && val.toString().toLowerCase().indexOf(filterString) !== -1 || !filterString) {
                    filtered.push(array[i]);
                    break;
                };
            };
        };
        return filtered;
    };

    public static sortAlphabetically(array: any[], prop?: string): any[] {
        if (array && array.length) {
            return array.sort((a, b) => {
                let nameA = prop ? a[prop].toLowerCase() : a.toLowerCase();
                let nameB = prop ? b[prop].toLowerCase() : b.toLowerCase();
                if (nameA < nameB) {
                    return -1;
                };
                if (nameA > nameB) {
                    return 1;
                };
                return 0;
            });
        };
        return [];
    };

    public static moveElementUp<T>(array: T[], index: number): T[] {
        return this.moveElement(array, index, true);
    };

    public static moveElementDown<T>(array: T[], index: number): T[] {
        return this.moveElement(array, index, false);
    };

    private static moveElement<T>(array: T[], index: number, up: boolean): T[] {
        return [
            ...array.slice(0, index - 1 + (up ? 0 : 1)),
            array[index + (up ? 0 : 1)],
            array[index - 1 + (up ? 0 : 1)],
            ...array.slice(index + 1 + (up ? 0 : 1)),
        ];
    };
};