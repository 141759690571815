import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToggleSwitchComponent } from './toggle-switch.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbTooltipModule
    ],
    declarations: [
        ToggleSwitchComponent
    ],
    exports: [
        ToggleSwitchComponent
    ]
})
export class ToggleSwitchModule { };