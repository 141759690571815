// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fa-clipboard {
    font-size: 22px;
}

#reportErrorWebchatLauncher {
    position: sticky;
}`, "",{"version":3,"sources":["webpack://./src/modules/shared-modals/modals/server-validation-errors/server-validation-errors.modal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".fa-clipboard {\n    font-size: 22px;\n}\n\n#reportErrorWebchatLauncher {\n    position: sticky;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
