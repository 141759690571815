import { Injectable, OnDestroy } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpErrorResponse,
    HttpEvent,
    HttpResponse
} from '@angular/common/http';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { HttpInterceptorHandlerService } from '../services/http-interceptor-handler.service';
import { LoadingService } from './loading.service';
import { Router, ActivationEnd } from '@angular/router';
import { Observable, Subscription, throwError } from 'rxjs';
import { TabsetService } from './tabset.service';
import { ToastService } from './toast.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './user.service';

@Injectable()
export class HttpInterceptorService implements OnDestroy, HttpInterceptor {
    private requests: HttpRequest<any>[] = [];
    private routerSubscription: Subscription = null;
    private tabSubscription: Subscription = null;

    public constructor(
        private httpInterceptorHandlerService: HttpInterceptorHandlerService,
        private loadingService: LoadingService,
        private router: Router,
        private tabsetService: TabsetService,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {
        this.routerSubscription = this.router.events.subscribe(event => {
            if (event instanceof ActivationEnd) {
                // Cancel all pending HTTP requests when the route is changed
                this.requests = [];
                this.httpInterceptorHandlerService.cancelPendingRequests();
            };
        });

        this.tabSubscription = this.tabsetService.changeTab.subscribe(event => {
            // Cancel all pending HTTP requests when the tab is changed
            this.requests = [];
            this.httpInterceptorHandlerService.cancelPendingRequests();
        });
    };

    public removeRequest(req: HttpRequest<any>): void {
        const i = this.requests.indexOf(req);
        i >= 0 && this.requests.splice(i, 1);

        if (this.requests.length > 0) {
            // Set the loading bar message to the value of the first request in the list
            this.loadingService.display({ show: true, text: this.requests[0].headers.get('LoadingMessage') });
        } else {
            // Hide the loading bar if there are no pending requests
            this.loadingService.display({ show: false });
        };
    };

    public intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<any>> {
        let modifiedReq: HttpRequest<T> = null;
        const partnerDetails = UserService.getPartnerTypeDetails();

        if (partnerDetails?.type && partnerDetails?.id && !req.url.includes('routing3.autocab.net')) {
            // The routing API cannot accept any custom headers
            modifiedReq = req.clone({
                headers: req.headers.append('PartnerType', partnerDetails.type).append('PartnerId', partnerDetails.id)
            });
        } else {
            modifiedReq = req.clone();
        };

        // Add the request to the list and show the loading bar
        this.requests.push(modifiedReq);
        this.loadingService.display({ show: true, text: req.headers.get('LoadingMessage') });

        return next.handle(modifiedReq).pipe(
            takeUntil(this.httpInterceptorHandlerService.onCancelPendingRequests()),
            catchError((error: HttpErrorResponse) => {
                // Remove the request from the list as it has returned an error
                this.removeRequest(modifiedReq);

                if (error && error.status == 403) {
                    return throwError(this.translateService.instant('global.http.permission_denied'));
                } else {
                    return throwError(error.error || this.translateService.instant('global.http.something_went_wrong'));
                };
            }),
            tap((event) => {
                if (event instanceof HttpResponse && event.ok) {
                    modifiedReq.method === 'PUT' && this.toastService.setToastMessage('global.http.saved_successfully');
                    modifiedReq.method === 'DELETE' && this.toastService.setToastMessage('global.http.deleted_successfully');
                };
            }),
            finalize(() => {
                // Request completes, errors, or is cancelled
                this.removeRequest(modifiedReq);
            })
        );
    };

    public ngOnDestroy(): void {
        this.routerSubscription?.unsubscribe();
        this.tabSubscription?.unsubscribe();
    };
}