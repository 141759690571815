import { Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IBookingModalConfig } from 'src/modules/shared-modals/modals/activity-booking/activity-booking.modal';
import { GetBookingsPayload } from 'src/models';
import { lastValueFrom } from 'rxjs';
import { SharedModalsService, ActivityService } from 'src/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'transaction-notes-modal',
    templateUrl: './transaction-notes.modal.html',
})
export class TransactionNotesModalComponent implements OnInit {
    @Input() public class: string = '';
    @Input() public description: string = '';
    @Input() public notes: string = '';
    @Input() public title: string = '';

    public formattedNotes: string = '';
    public loading: boolean = false;
    public filters: GetBookingsPayload = new GetBookingsPayload();

    constructor(
        private activityService: ActivityService,
        public notesModal: NgbActiveModal,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService,
    ) { }

    public ngOnInit(): void {
        this.formattedNotes = this.formatNotes(this.notes);
    };

    public async onViewBooking() {
        if (this.getBookingReferenceFromTransaction()) {
            this.loading = true;
            try {
                const { bookings } = await lastValueFrom(this.activityService.getBookingsObservable(this.filters));
                bookings[0] && this.showBookingModal(bookings[0]);
                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.onFailure(error);
            };
        }
    };

    public showBookingModal(booking: any): void {
        const config: IBookingModalConfig = {
            booking: booking,
            showDisputeOption: false,
            showDisputesTab: false,
            showInvoicesTab: false
        };
        this.sharedModalsService.showActivityBookingModal(config, { windowClass: 'operator' });
    };

    private formatNotes(notes: string): string {
        return notes.replace(/\n/g, '<br>').replace(/ /g, '&nbsp;');
    };

    private getBookingReferenceFromTransaction(): boolean {
        const regex = /\w\d+-\w\d+-\d+/g;
        const matches = this.description.match(regex);
        if (matches && matches.length > 0) {
            this.filters.bookingReference = matches[0];
        }
        return this.filters.bookingReference ? true : false;
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.loading = false;
        this.sharedModalsService.showServerValidationErrorsModal(res, this.translateService.instant('operator_portal.accounts.account.transactions_notes.view_booking'));
    };

}