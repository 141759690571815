import { ActivatedRouteSnapshot } from '@angular/router';
import { NoAccessComponent } from 'src/app/standalone/no-access/no-access.component';

const canActivateFn = (activatedRoute: ActivatedRouteSnapshot): boolean => {
    if (activatedRoute.routeConfig) {
        if (!activatedRoute.routeConfig.data) {
            activatedRoute.routeConfig.data = {};
        };
        if (!activatedRoute.parent.data) {
            activatedRoute.parent.data = {};
        };
        const canAccessAbstractParent = !activatedRoute.routeConfig.data.isParentAbstract || (activatedRoute.routeConfig.data.isParentAbstract && !activatedRoute.parent.data.permission);
        if (activatedRoute.routeConfig.data.permission) {
            if (!canAccessAbstractParent && !activatedRoute.routeConfig.data.isNoAccessComponentActive) {
                if (!activatedRoute.routeConfig.data.originalComponent) {
                    activatedRoute.routeConfig.data.originalComponent = activatedRoute.routeConfig.component;
                };
                activatedRoute.routeConfig.component = NoAccessComponent;
                activatedRoute.routeConfig.data.isNoAccessComponentActive = true;
            } else if (canAccessAbstractParent && activatedRoute.routeConfig.data.originalComponent && activatedRoute.routeConfig.data.isNoAccessComponentActive) {
                activatedRoute.routeConfig.component = activatedRoute.routeConfig.data.originalComponent;
                activatedRoute.routeConfig.data.isNoAccessComponentActive = false;
            };
        };
    };
    return true;
};