import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CreditNoteView, InvoiceView } from 'src/models';

@Component({
    selector: 'send-email-cell',
    template: `<i [attr.class]="iconClass"></i>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {'[class.disabled]':'disabled'},
    styles: [`
        :host { align-items: center; display: flex; flex-flow: column; justify-content: center; height: 100%; width: 100%; }
        :host.gvs-action-cell:hover { background-color: rgb(42 42 42 / 20%) !important; transition: 0.2s ease-in-out; }
        :host.gvs-action-cell i { font-size: 20px; }
        :host.disabled { opacity: 0.5; cursor: not-allowed; }
    `]
})
export class SendEmailCellTplComponent {
    @Input() public set row(item: CreditNoteView | InvoiceView) {
        if (item) {
            this.sendingEmail = Boolean(item.sendingEmail);
            this.disabled = this.sendingEmail || (!item._links.EmailCreditNote && !item._links.SendIGoInvoiceEmail && !item._links.SendOriginatorInvoiceEmail);
            this.iconClass = `fal ${this.sendingEmail ? 'fa-spinner-third fa-spin' : 'fa-envelope'}`;
            this.cd.detectChanges();
        };
    };
    public sendingEmail: boolean = false;
    public disabled: boolean = false;
    public iconClass: string = '';
    public constructor(private cd: ChangeDetectorRef) { };
};