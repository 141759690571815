import { CornicheWithdrawFundsPayload } from './../models/corniche.model';
import { Injectable } from '@angular/core';
import { NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, NgForm } from '@angular/forms';

import {
    BankAccountInfoView,
    BookingView,
    CXConnectionView,
    DateRangeTemplateView,
    DisputeView,
    iGoCompanyOperatorView,
    iGoCompanyOriginatorView,
    iGoUserView,
    InvoiceView,
    RegionView,
    TestEmulatorTestRuleView,
    UserRoleView
} from 'src/models';
import { IFormValidationErrorsModalConfig, FormValidationErrorsModalComponent } from 'src/modules/shared-modals/modals/form-validation-errors/form-validation-errors.modal';
import { IHalLink } from 'src/interfaces/hal-link.interface';
import { IServerValidationErrorsModalConfig, ServerValidationErrorsModalComponent } from 'src/modules/shared-modals/modals/server-validation-errors/server-validation-errors.modal';
import { ServerValidationError } from '../models/server-validation-error.model';

import { ModalService } from './modal.service';

import { ActivityBookingModalComponent, IBookingModalConfig } from 'src/modules/shared-modals/modals/activity-booking/activity-booking.modal';
import { ActivityDisputeModalComponent } from 'src/modules/shared-modals/modals/activity-dispute/activity-dispute.modal';
import { ActivityDisputeReasonModalComponent } from 'src/modules/shared-modals/modals/activity-dispute-reason/activity-dispute-reason.modal';
import { AlertModalComponent, IAlertModalConfig } from 'src/modules/shared-modals/modals/alert/alert.modal';
import { BankAccountInformationModalComponent } from 'src/modules/shared-modals/modals/bank-account-information/bank-account-information.modal';
import { ConfirmationModalComponent, IConfirmationModalConfig } from 'src/modules/shared-modals/modals/confirmation/confirmation.modal';
import { CreateCXMarketplaceModalComponent } from 'src/modules/shared-modals/modals/create-cx-marketplace/create-cx-marketplace.modal';
import { CreateUserModalComponent } from 'src/modules/shared-modals/modals/create-user/create-user.modal';
import { CurrentUserProfileModalComponent } from 'src/modules/shared-modals/modals/current-user-profile/current-user-profile.modal';
import { CXConnectionModalComponent } from 'src/modules/shared-modals/modals/cx-connection/cx-connection.modal';
import { DateRangeTemplateDatesModalComponent } from 'src/modules/shared-modals/modals/date-range-template-dates/date-range-template-dates.modal';
import { DisputeReasonModalComponent, IDisputeReasonModalConfig } from 'src/modules/shared-modals/modals/dispute-reason/dispute-reason.modal';
import { DisputeStatusModalComponent } from 'src/modules/shared-modals/modals/dispute-status/dispute-status.modal';
import { ConfirmWithdrawTransactionModalComponent } from '../modules/shared-modals/modals/confirm-withdraw-transaction/confirm-withdraw-transaction.modal';
import { EditUserModalComponent } from 'src/modules/shared-modals/modals/edit-user/edit-user.modal';
import { InvoiceModalComponent } from 'src/modules/shared-modals/modals/invoice/invoice.modal';
import { ISelectionModalConfig, SelectionModalComponent } from 'src/modules/shared-modals/modals/selection/selection.modal';
import { ISendEmailConfig, SendEmailModalComponent } from 'src/modules/shared-modals/modals/send-email/send-email.modal';
import { IWarningModalConfig, WarningModalComponent } from 'src/modules/shared-modals/modals/warning/warning.modal';
import { ParseJsonInputModalComponent } from 'src/modules/shared-modals/modals/parse-json-input/parse-json-input.modal';
import { RegionModalComponent } from 'src/modules/shared-modals/modals/region/region.modal';
import { SendResetPasswordLinkModalComponent } from 'src/modules/shared-modals/modals/send-reset-password-link/send-reset-password-link.modal';
import { TestEmulatorTestRuleModalComponent } from 'src/modules/shared-modals/modals/test-emulator-test-rule/test-emulator-test-rule.modal';
import { UnsavedChangesModalComponent } from 'src/modules/shared-modals/modals/unsaved-changes/unsaved-changes.modal';
import { UserRoleModalComponent } from 'src/modules/shared-modals/modals/user-role/user-role.modal';
import { TestRegionNameModalComponent } from 'src/modules/shared-modals/modals/test-region-name/test-region-name.modal';

@Injectable({ providedIn: 'root' })
export class SharedModalsService {
    public constructor(
        private modalService: ModalService
    ) { };

    public showActivityBookingModal(config: IBookingModalConfig, options?: Partial<NgbModalConfig>): NgbModalRef {
        const modalRef = this.modalService.open(ActivityBookingModalComponent, { size: 'lg', windowClass: `${options && options.windowClass} wide-modal` });
        modalRef.componentInstance.config = config;
        modalRef.componentInstance.windowClass = options && options.windowClass;
        return modalRef;
    };

    public showActivityDisputeModal(dispute: DisputeView, showViewBookingButton: boolean, options?: Partial<NgbModalConfig>): NgbModalRef {
        const modalRef = this.modalService.open(ActivityDisputeModalComponent, { size: 'lg', windowClass: `${options && options.windowClass}` });
        modalRef.componentInstance.dispute = dispute;
        modalRef.componentInstance.showViewBookingButton = showViewBookingButton;
        modalRef.componentInstance.windowClass = options && options.windowClass;
        return modalRef;
    };

    public showActivityDisputeReasonsModal(booking: BookingView, options?: Partial<NgbModalConfig>): NgbModalRef {
        const modalRef = this.modalService.open(ActivityDisputeReasonModalComponent, { size: 'sm', windowClass: `${options && options.windowClass}` });
        modalRef.componentInstance.booking = booking;
        modalRef.componentInstance.windowClass = options && options.windowClass;
        return modalRef;
    };

    public showAlertModal(title: string, message: string, options?: Partial<NgbModalConfig>): NgbModalRef {
        const modalRef = this.modalService.open(AlertModalComponent, { size: 'sm', windowClass: `${options && options.windowClass}` });
        modalRef.componentInstance.config = { message, title } as IAlertModalConfig;
        return modalRef;
    };

    public showBankAccountInformationModal(partner: iGoCompanyOriginatorView | iGoCompanyOperatorView, bankAccountInfo?: BankAccountInfoView): NgbModalRef {
        const createUrl = partner instanceof iGoCompanyOriginatorView ? partner._links.CreateOriginatorBankAccountInformation?.href : partner._links.CreateOperatorBankAccountInformation?.href;
        const updateUrl = partner instanceof iGoCompanyOriginatorView ? partner._links.UpdateOriginatorBankAccountInformation?.href : partner._links.UpdateOperatorBankAccountInformation?.href;
        const modalRef = this.modalService.open(BankAccountInformationModalComponent, { size: 'sm', windowClass: 'igo' });
        modalRef.componentInstance.bankAccountInfo = bankAccountInfo;
        modalRef.componentInstance.createUrl = createUrl;
        modalRef.componentInstance.partner = partner;
        modalRef.componentInstance.updateUrl = updateUrl;
        return modalRef;
    };

    public showConfirmationModal(title: string, message: string, confirmButtonText?: string, dismissButtonText?: string, options?: Partial<NgbModalConfig>) {
        const modalRef = this.modalService.open(ConfirmationModalComponent, { size: 'sm', windowClass: `${options && options.windowClass}` });
        modalRef.componentInstance.config = { message, title, confirmButtonText, dismissButtonText } as IConfirmationModalConfig;
        return modalRef;
    };

    public showCXConnectionModal(connection: CXConnectionView, options?: Partial<NgbModalConfig>): NgbModalRef {
        const modalRef = this.modalService.open(CXConnectionModalComponent, { size: 'lg', windowClass: `${options && options.windowClass}` });
        modalRef.componentInstance.connection = connection;
        modalRef.componentInstance.windowClass = options && options.windowClass;
        return modalRef;
    };

    public showCreateCXMarketplaceModal(_links: IHalLink, options?: Partial<NgbModalConfig>): NgbModalRef {
        const modalRef = this.modalService.open(CreateCXMarketplaceModalComponent, { size: 'sm', windowClass: `${options && options.windowClass}` });
        modalRef.componentInstance._links = _links;
        return modalRef;
    };

    public showCreateUserModal(_links: IHalLink, options?: Partial<NgbModalConfig>): NgbModalRef {
        const modalRef = this.modalService.open(CreateUserModalComponent, { size: 'sm', windowClass: `${options && options.windowClass}` });
        modalRef.componentInstance._links = _links;
        return modalRef;
    };

    public showCurrentUserProfileModal(options?: Partial<NgbModalConfig>): NgbModalRef {
        const modalRef = this.modalService.open(CurrentUserProfileModalComponent, { size: 'sm', windowClass: `${options && options.windowClass}` });
        modalRef.componentInstance.windowClass = options && options.windowClass;
        return modalRef;
    };

    public showDateRangeTemplateDatesModal(dateRangeTemplate: DateRangeTemplateView, options?: Partial<NgbModalConfig>): NgbModalRef {
        const modalRef = this.modalService.open(DateRangeTemplateDatesModalComponent, { size: 'sm', windowClass: `${options && options.windowClass}` });
        modalRef.componentInstance.dateRangeTemplate = dateRangeTemplate;
        return modalRef;
    };

    public showDisputeReasonModal(config: IDisputeReasonModalConfig, options?: Partial<NgbModalConfig>): NgbModalRef {
        const modalRef = this.modalService.open(DisputeReasonModalComponent, { size: 'sm', windowClass: `${options && options.windowClass}` });
        modalRef.componentInstance.config = config;
        modalRef.componentInstance.windowClass = options && options.windowClass;
        return modalRef;
    };

    public showDisputeStatusModal(dispute: DisputeView, options?: Partial<NgbModalConfig>): NgbModalRef {
        const modalRef = this.modalService.open(DisputeStatusModalComponent, { size: 'sm', windowClass: `${options && options.windowClass}` });
        modalRef.componentInstance.dispute = dispute;
        modalRef.componentInstance.windowClass = options && options.windowClass;
        return modalRef;
    };

    public showEditUserModal(user: iGoUserView, options?: Partial<NgbModalConfig>): NgbModalRef {
        const modalRef = this.modalService.open(EditUserModalComponent, { size: 'sm', windowClass: `${options && options.windowClass}` });
        modalRef.componentInstance.user = user;
        modalRef.componentInstance.windowClass = options && options.windowClass;
        return modalRef;
    };

    public showFormValidationErrorsModal = (title: string, form: NgForm | UntypedFormGroup, options?: Partial<NgbModalConfig>): NgbModalRef => {
        const modalRef = this.modalService.open(FormValidationErrorsModalComponent, { size: 'sm', windowClass: `${options && options.windowClass}` });
        modalRef.componentInstance.config = { form, title } as IFormValidationErrorsModalConfig;
        return modalRef;
    };

    public showConfirmWithdrawTransactionModal(withdrawFundsDetails: Partial<CornicheWithdrawFundsPayload>, options?: Partial<NgbModalConfig>): NgbModalRef {
        const modalRef = this.modalService.open(ConfirmWithdrawTransactionModalComponent, { backdrop: 'static', size: 'sm', windowClass: `${options && options.windowClass}` });
        modalRef.componentInstance.withdrawFundsDetails = withdrawFundsDetails;
        modalRef.componentInstance.windowClass = options && options.windowClass;
        return modalRef;
    };

    public showInvoiceModal(invoice: InvoiceView, options?: Partial<NgbModalConfig>): NgbModalRef {
        const modalRef = this.modalService.open(InvoiceModalComponent, { size: 'lg', windowClass: `${options && options.windowClass}` });
        modalRef.componentInstance.invoice = invoice;
        modalRef.componentInstance.windowClass = options && options.windowClass;
        return modalRef;
    };

    public showParseJsonInputModal(options?: Partial<NgbModalConfig>): NgbModalRef {
        const modalRef = this.modalService.open(ParseJsonInputModalComponent, { size: 'sm', windowClass: `${options && options.windowClass}` });
        modalRef.componentInstance.windowClass = options && options.windowClass;
        return modalRef;
    };

    public showRegionModal(region?: RegionView, isTestEmulatorRegion?: boolean, options?: Partial<NgbModalConfig>): NgbModalRef {
        const modalRef = this.modalService.open(RegionModalComponent, { size: 'lg', windowClass: `${options && options.windowClass}` });
        modalRef.componentInstance.isTestEmulatorRegion = isTestEmulatorRegion;
        modalRef.componentInstance.region = region;
        modalRef.componentInstance.windowClass = options && options.windowClass;
        return modalRef;
    };

    public showSendEmailModal(config: ISendEmailConfig, options?: Partial<NgbModalConfig>): NgbModalRef {
        const modalRef = this.modalService.open(SendEmailModalComponent, { size: 'sm', windowClass: `${options && options.windowClass}` });
        modalRef.componentInstance.config = config;
        modalRef.componentInstance.windowClass = options && options.windowClass;
        return modalRef;
    };

    public showSendResetPasswordLinkModal(): NgbModalRef {
        return this.modalService.open(SendResetPasswordLinkModalComponent, { size: 'sm' });
    };

    public showServerValidationErrorsModal(error: any, title?: string): NgbModalRef {
        let errors: ServerValidationError[] = [];
        if (error && error.errorMessages) {
            errors = error.errorMessages.map(item => new ServerValidationError({
                detail: item.messages.join(".\n"),
                title: 'Server Error'
            }));
        } else if ((error?.error?.errors && error.error.errors[""]) || (error?.errors && error.errors[""])) {
            let errorsList = (error?.error?.errors && error.error.errors[""]) || (error?.errors && error.errors[""]);
            errors = errorsList.map(item => new ServerValidationError({
                detail: item,
                title: 'Server Error'
            }));
        } else if (error && error.errors) {
            for (var key in error.errors) {
                if (error.errors.hasOwnProperty(key)) {
                    errors.push(new ServerValidationError({
                        detail: error.errors[key],
                        title: 'Server Error'
                    }));
                };
            };
            if (!errors.length) {
                errors.push(new ServerValidationError({
                    detail: error.detail,
                    title: 'Server Error'
                }));
            };
        } else if (typeof error == 'string') {
            errors.push(new ServerValidationError({
                detail: error,
                title: 'Server Error'
            }));
        } else if (error.length && typeof error[0] == 'string') {
            errors = error.map(item => new ServerValidationError({
                detail: item,
                title: 'Server Error'
            }));
        } else if (error instanceof Error) {
            errors.push(new ServerValidationError({
                detail: error.message,
                title: 'Server Error'
            }));
        };
        const modalRef = this.modalService.open(ServerValidationErrorsModalComponent, { size: 'sm', windowClass: 'submodal' });
        modalRef.componentInstance.config = {
            errors,
            instance: error.instance,
            title
        } as IServerValidationErrorsModalConfig;
        return modalRef;
    };

    public showSelectionModal(config: ISelectionModalConfig, options?: Partial<NgbModalConfig>): NgbModalRef {
        const modalRef = this.modalService.open(SelectionModalComponent, options ? options : { size: 'sm', windowClass: 'submodal' });
        modalRef.componentInstance.config = config;
        return modalRef;
    };

    public showTestEmulatorTestRuleModal(testRule: TestEmulatorTestRuleView): NgbModalRef {
        const modalRef = this.modalService.open(TestEmulatorTestRuleModalComponent, { size: 'lg', windowClass: 'originator wide-modal' });
        modalRef.componentInstance.testRule = testRule;
        return modalRef;
    };

    public showTestRegionNameModal(testRegions: RegionView[], region?: RegionView): NgbModalRef {
        const modalRef = this.modalService.open(TestRegionNameModalComponent, { size: 'sm', windowClass: `originator` });
        modalRef.componentInstance.region = region;
        modalRef.componentInstance.testRegions = testRegions;
        return modalRef;
    };

    public showWarningModal(title, message, options?: Partial<NgbModalConfig>): NgbModalRef {
        const modalRef = this.modalService.open(WarningModalComponent, { size: 'sm', windowClass: `${options && options.windowClass}` });
        modalRef.componentInstance.config = { message, title } as IWarningModalConfig;
        return modalRef;
    };

    public showUnsavedChangesModal(): NgbModalRef {
        const modalRef = this.modalService.open(UnsavedChangesModalComponent, { size: 'sm', windowClass: 'submodal' });
        modalRef.componentInstance.config = {
            message: 'global.modals.unsaved_changes.message',
            title: 'global.modals.unsaved_changes.title'
        } as IConfirmationModalConfig;
        return modalRef;
    };

    public showUserRoleModal(_links: IHalLink, role?: UserRoleView, options?: Partial<NgbModalConfig>): NgbModalRef {
        const modalRef = this.modalService.open(UserRoleModalComponent, { size: 'sm', windowClass: `${options && options.windowClass}` });
        modalRef.componentInstance.role = role;
        modalRef.componentInstance.windowClass = options && options.windowClass;
        modalRef.componentInstance._links = _links;
        return modalRef;
    };
};