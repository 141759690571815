import { environment } from "src/environments/environment";

export class Endpoints {
    private static _API_URL = environment.apiUrl;
    public static set API_URL(url: string) {
        Endpoints._API_URL = url;
    };
    public static get API_URL(): string {
        return Endpoints._API_URL;
    };
    public static TYPES = {
        _ADDRESS_TYPES: '/api/addresstypes',
        _BOOKING_STATUSES: '/Api/Types/BookingStatuses',
        _COUNTRIES: '/Api/Types/Countries',
        _CULTURES: '/Api/Types/Cultures',
        _CURRENCY_CODES: '/Api/Types/CurrencyCodes',
        _INVOICE_FREQUENCIES: '/Api/Types/InvoiceFrequencies',
        _MAP_TYPES: '/api/ghostcompanion/v1/MapType',
    };
    public static ADDRESSES: string = '/api/ghostcompanion/v1/addresses';
    public static AUTH = {
        _AUTH: '/Api/Auth',
        _AZURE_LOGIN: '/Login/Azure',
        _LOGIN: '/Login',
        _RENEW: '/Renew',
    };
    public static BANK_ACCOUNT: string = '/BankAccount';
    public static BOOKINGS = {
        _BOOKINGS: '/Api/Bookings',
        _BY_INVOICE: '/ByInvoice',
        _COUNT: '/Count',
        _FIND: '/Find'
    };
    public static COMPANY_SETTINGS: string = '/Api/CompanySettings';
    public static COMPANIES = {
        _COMPANIES: '/Api/Company',
        _RETURN_USER_ACCESS: '/ReturnUserAccess'
    };
    public static CONNECTIONS = {
        _ADD_CX_CONNECTION: '/Api/CabExchange/Marketplace/AddConnection'
    };
    public static DATE_RANGE: string = '/Api/DateRange';
    public static DISCOVER_OPERATORS: string = '/Api/Operator/Discover';
    public static DISPUTES = {
        _DISPUTES: '/Api/Disputes'
    };
    public static GOOGLE_ADDRESSES_DETAILS: string = '/api/autocomplete/details';
    public static GOOGLE_ADDRESSES: string = '/api/autocomplete';
    public static GPS_TRACKS: string = '/api/vehiclegpstracks';
    public static INVOICES = {
        _IGO: '/IGo',
        _INVOICES: '/Api/Invoice',
        _ORIGINATOR: '/Originator'
    };
    public static MARKETPLACES = {
        _CONNECTED: '/Connected',
        _CX_MARKETPLACES: '/Api/CabExchange/Marketplace',
    };
    public static OPERATORS = {
        _ALL: '/All/Find',
        _ALL_CX_OPERATORS: '/GetAllCabExchangeOperators',
        _AS_IGO: '/AsIGo',
        _AS_ORIGINATOR: '/AsOriginator',
        _BENEFICIARY_INFORMATION: '/BeneficiaryInformation',
        _CONNECTED: '/GetConnected',
        _CONTACT_DETAILS: '/ContactDetails',
        _COUNT: '/All/Count',
        _CX_OPERATOR: '/Api/CabExchange/Operator',
        _LOGO: '/Logo',
        _OPERATORS: '/Api/Operator',
        _OPERATOR_ANALYTICS: '/Api/Analytics',
    };
    public static ORIGINATORS = {
        _AS_IGO: '/AsIGo',
        _AS_OPERATOR: '/AsOperator',
        _CONNECTED: '/GetConnected',
        _CX_ORIGINATOR: '/Api/CabExchange/Originator',
        _LOGO: '/Logo',
        _ORIGINATORS: '/Api/Originator',
    };
    public static OPERATOR_SETTINGS: string = '/Api/OperatorSettings';
    public static ORIGINATOR_SETTINGS: string = '/Api/OriginatorSettings';

    public static PERMISSIONS: string = '/Api/Permissions';
    public static REGIONS: string = '/Api/Region';
    public static ROLES: string = '/Api/Roles';
    public static ROUTING = [
        'https://routing3.autocab.net/shortest/route/v1/driving/',
        '?overview=full&alternatives=false&steps=true&continue_straight=false'
    ];
    public static SIGNALR = {
        _DISPUTE_CHAT: '/SignalR/DisputeChat',
    };
    public static TABLEAU_TOKEN: string = '/Api/Tableau/Token';
    public static TEST_EMULATOR = {
        _BOOKINGS: '/Api/TestBooking',
        _COMPLETED_BOOKINGS: '/Completed',
        _GENERATE: '/Generate',
        _REGIONS: '/Api/TestEmulatorMapArea',
        _START: '/Start',
        _STATS: '/Api/TestEmulatorStats',
        _STOP: '/Stop',
        _TEST: '/Api/TestEmulator',
    };
    public static USERS = {
        _ME: '/Me',
        _PASSWORD: '/Password',
        _PASSWORD_RESET: '/PasswordReset',
        _SEND_PASSWORD_RESET: '/SendPasswordReset',
        _SEND_REGISTRATION_EMAIL: '/SendRegistrationEmail',
        _SETUP: '/Setup',
        _USERS: '/Api/Users',
    };
};
