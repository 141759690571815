import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SpinnerService {
    public status: Subject<boolean> = new Subject<boolean>();
    public loading: boolean = false;

    public display(value: boolean): void {
        this.status.next(this.loading = value);
    };
};