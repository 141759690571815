// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-wrapper {
    height: 100%;
    position: relative;
}

.map {
    border-bottom-left-radius: 2rem;
    bottom: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/region-builder-map/region-builder-map.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,+BAA+B;IAC/B,SAAS;IACT,YAAY;IACZ,kBAAkB;IAClB,MAAM;IACN,WAAW;AACf","sourcesContent":[".map-wrapper {\n    height: 100%;\n    position: relative;\n}\n\n.map {\n    border-bottom-left-radius: 2rem;\n    bottom: 0;\n    height: 100%;\n    position: absolute;\n    top: 0;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
