// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        .reason { font-kerning: none; font-size: 14px !important };
    `, "",{"version":3,"sources":["webpack://./src/modules/shared-modals/modals/dispute-status/dispute-status.modal.ts"],"names":[],"mappings":";QACQ,UAAU,kBAAkB,EAAE,2BAA2B,CAAA","sourcesContent":["\n        .reason { font-kerning: none; font-size: 14px !important };\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
