import { AfterViewInit, Component, EventEmitter, inject, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { ChartUtilities } from '../../../../shared/chart.utilities';
import { ChartsService } from '../../../../services';
import { IChartFilterChange, IPieChartConfig, ISelectedChart } from '../../../../interfaces/charts.interface';
import { DisputeView } from '../../../../models';

@Component({
    selector: 'activity-disputes-charts',
    templateUrl: 'activity-disputes-charts.component.html',
    encapsulation: ViewEncapsulation.None,
    styles: [`
        .disputes-charts ghost-pie-chart .ghost-pie-legend .items .item .item-value { color: white !important; }
    `]
})

export class ActivityDisputesChartsComponent implements OnInit, AfterViewInit {
    @Input() public showCharts: boolean;
    @Output() filterChange: EventEmitter<IChartFilterChange<DisputeView>> = new EventEmitter<IChartFilterChange<DisputeView>>();
    @Output() rowsChange: EventEmitter<DisputeView[]> = new EventEmitter<DisputeView[]>();

    private chartService = inject(ChartsService);

    public selectedChartType: string = this.chartService.activityDisputesChartConfig.all.extra;

    public activityDisputesLastMessageChartConfig: IPieChartConfig;
    public activityDisputesStatusChartConfig: IPieChartConfig;

    public rows: DisputeView[] = [];
    public tempRows: DisputeView[] = [];

    public selected: ISelectedChart<DisputeView> = {
        name: this.chartService.activityDisputesChartConfig.all.name,
        data: this.chartService.activityDisputesChartConfig.all.data,
        extra: this.chartService.activityDisputesChartConfig.all.extra
    };

    public ngOnInit(): void {
        this.recalculateCharts();
    };

    public ngAfterViewInit(): void {
        ChartUtilities.setActiveAdvancedPieChartLabel(this.selectedChartType);
    };

    public recalculateCharts(): void {
        this.activityDisputesStatusChartConfig = {
            colours: {
                domain: ['grey', '#dc3545', '#28a745']
            },
            data: [
                this.chartService.activityDisputesChartConfig.open,
                this.chartService.activityDisputesChartConfig.escalated,
                this.chartService.activityDisputesChartConfig.resolved
            ],
            title: 'igo_portal.activity.disputes.charts.status'
        };

        this.activityDisputesLastMessageChartConfig = {
            colours: {
                domain: ['#28a745', '#dc3545']
            },
            data: [
                this.chartService.activityDisputesChartConfig.lastMessageIsSent,
                this.chartService.activityDisputesChartConfig.lastMessageIsReceived
            ],
            title: 'igo_portal.activity.disputes.charts.last_message'
        };

        this.selected = this.chartService.activityDisputesChartConfig[this.selected?.extra || 'all'];

        this.tempRows = [...this.chartService.activityDisputesChartConfig[this.selected.extra].data];
        this.rows = this.chartService.activityDisputesChartConfig[this.selected.extra].data;
        this.rowsChange.emit(this.rows);
    };

    public onSelectGraph(event: ISelectedChart<DisputeView>): void {
        this.selected = this.selectedChartType == event.extra ? {
            name: 'All',
            data: [],
            extra: 'all',
        } : event;
        this.setRows(this.selected.extra);
        this.updateFilter();
    };

    public setRows(extra: string): void {
        this.selectedChartType = extra;
        this.tempRows = [...this.chartService.activityDisputesChartConfig[extra].data];
        this.rows = this.chartService.activityDisputesChartConfig[extra].data;
    };

    public updateFilter(): void {
        this.filterChange.emit({
            chartData: this.selected,
            rows: this.rows,
            tempRows: this.tempRows
        });
    };
};