import { Injectable } from "@angular/core";

type Cookie = 'igo_cookieconsent' | '_ga'; // A list of possible cookies

@Injectable({ providedIn: 'root' })
export class CookieService {
    public constructor() { };

    public hasConsented = (): boolean => this.getCookie('igo_cookieconsent') == 'allow'; // Did the user allow cookies?
    public hasResponded = (): boolean => !!this.getCookie('igo_cookieconsent'); // Did the user save their cookie preference?

    public deleteCookie(cookieName: Cookie): void {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        const expires = 'expires=' + date.toUTCString();
        document.cookie = `${cookieName}= ; ${expires}`;
    };

    public getCookie(cookieName: Cookie): string {
        const name = cookieName + "=";
        const cookiesList = document.cookie.split(';');

        const cookie = cookiesList.find(c => c.includes(name));
        const cookieValue = cookie ? cookie.split('=')[1] : '';

        return cookieValue;
    };

    public setCookie(cookieName: Cookie, cookieValue: string, expiryDays: number = 365): void {
        const date = new Date();
        date.setTime(date.getTime() + (expiryDays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
    };
};