import { Injectable } from '@angular/core';
import { OperatorDocumentView } from 'src/models';
import { RequestService } from './request.service';

@Injectable({ providedIn: 'root' })
export class OperatorDocumentsService {
    public documents: OperatorDocumentView[] = [
        new OperatorDocumentView({ id: '0', approved: true, name: 'Sample Document 1', description: 'This is a sample document description.', expiryDate: new Date(), marketplaces: 2, added: true }),
        new OperatorDocumentView({ id: '1', approved: false, name: 'Sample Document 2', description: 'This is a sample document description.', expiryDate: new Date(), marketplaces: 5, added: false }),
        new OperatorDocumentView({ id: '2', approved: true, name: 'Sample Document 3', description: 'This is a sample document description.', expiryDate: new Date(), marketplaces: 1, added: false })
    ];

    public constructor(
        private http: RequestService
    ) { };
};