// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        :host {
            background-color: white;
            border-radius: 80px 0 0 0;
            box-shadow: -12px -12px 30px #00000029;
            display: flex;
            flex-direction: column;
            height: calc(100% - 150px);
            overflow: hidden;
            position: fixed;
            right: 0;
            bottom: 0;
            transform: translateX(500px);
            width: 400px;
            z-index: 2;
        }

        :host.show {
            animation: slideIn .5s forwards;
        }

        :host .heading {
            padding: 80px 40px 40px 40px;
        }
    `, "",{"version":3,"sources":["webpack://./src/app/standalone/side-panel-wrapper/side-panel-wrapper.component.ts"],"names":[],"mappings":";QACQ;YACI,uBAAuB;YACvB,yBAAyB;YACzB,sCAAsC;YACtC,aAAa;YACb,sBAAsB;YACtB,0BAA0B;YAC1B,gBAAgB;YAChB,eAAe;YACf,QAAQ;YACR,SAAS;YACT,4BAA4B;YAC5B,YAAY;YACZ,UAAU;QACd;;QAEA;YACI,+BAA+B;QACnC;;QAEA;YACI,4BAA4B;QAChC","sourcesContent":["\n        :host {\n            background-color: white;\n            border-radius: 80px 0 0 0;\n            box-shadow: -12px -12px 30px #00000029;\n            display: flex;\n            flex-direction: column;\n            height: calc(100% - 150px);\n            overflow: hidden;\n            position: fixed;\n            right: 0;\n            bottom: 0;\n            transform: translateX(500px);\n            width: 400px;\n            z-index: 2;\n        }\n\n        :host.show {\n            animation: slideIn .5s forwards;\n        }\n\n        :host .heading {\n            padding: 80px 40px 40px 40px;\n        }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
