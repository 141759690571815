import { Component, Input, forwardRef, ElementRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { AddressService } from '../../../services';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { GoogleAddressApi, GoogleAddressView } from '../../../models';
import { Observable, empty } from 'rxjs';

@Component({
    selector: 'address-selector',
    templateUrl: './address-selector.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AddressSelectorComponent),
            multi: true
        }
    ]
})

export class AddressSelectorComponent implements ControlValueAccessor {
    @ViewChild('input') public input: ElementRef;
    @Input() public address = null;
    @Input() public companyId = null;
    @Input() public id = '';
    @Input() public name = '';
    @Input() public placeholder = '';
    @Input() public onAddressClear = () => { this.address = null; };
    @Input() public onAddressSelection = (address, index?) => { };
    @Input() public required = false;
    public loading = false;

    private changed = new Array<(value: any) => void>();
    private touched = new Array<() => void>();

    public constructor(
        private addressService: AddressService
    ) { };

    public addressIsValid = (): boolean => (this.address && this.address.length > 1) ? true : false;

    public clearAddress(): void {
        this.onAddressClear();
        this.input.nativeElement.focus();
    };

    public registerOnChange(fn: (value: any) => void): void {
        this.changed.push(fn);
    };

    public registerOnTouched(fn: () => void): void {
        this.touched.push(fn);
    };

    public touch(): void {
        this.touched.forEach(f => f());
    };

    public writeValue(value: any): void {
        this.address = value;
    };

    public googleAutocompleteResultFormatter = (result: GoogleAddressApi): string => result.description;

    public googleAutocompleteInputFormatter = (result: GoogleAddressView): string => result.text;

    public onGoogleAddressSelection($event: NgbTypeaheadSelectItemEvent): void {
        this.loading = true;
        this.addressService.getGooglePlaceByIdObservable($event.item.place_id, $event.item.description, (googleAddress: GoogleAddressView) => {
            this.loading = false;
            this.address = googleAddress;
            this.onAddressSelection(googleAddress);
        });
    };

    public searchForGoogleAddress = (text: Observable<string>): any => {
        return text.pipe(debounceTime(300), tap(() => this.loading = true), switchMap(term => {
            this.loading = term.length ? true : false;
            return term.length ? this.addressService.getGoogleAddressesObservable(term).pipe() : empty() 
        }), tap(() => this.loading = false));
    };

    public onSelectInput(): void {
        this.input.nativeElement.select();
    };
};