// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        :host { align-items: flex-start; display: flex; flex-flow: column; justify-content: center; height: 100%; width: 100%; }
        :host.gvs-action-cell:hover { background-color: rgba(149, 38, 84, 0.2) !important; }
        :host .logo-cell { background-position: center; background-repeat: no-repeat; background-size: contain; margin-left: 20px; position: absolute; width: 50px; }
    `, "",{"version":3,"sources":["webpack://./src/shared/table-cell-templates/logo-cell-template.component.ts"],"names":[],"mappings":";QACQ,QAAQ,uBAAuB,EAAE,aAAa,EAAE,iBAAiB,EAAE,uBAAuB,EAAE,YAAY,EAAE,WAAW,EAAE;QACvH,8BAA8B,mDAAmD,EAAE;QACnF,mBAAmB,2BAA2B,EAAE,4BAA4B,EAAE,wBAAwB,EAAE,iBAAiB,EAAE,kBAAkB,EAAE,WAAW,EAAE","sourcesContent":["\n        :host { align-items: flex-start; display: flex; flex-flow: column; justify-content: center; height: 100%; width: 100%; }\n        :host.gvs-action-cell:hover { background-color: rgba(149, 38, 84, 0.2) !important; }\n        :host .logo-cell { background-position: center; background-repeat: no-repeat; background-size: contain; margin-left: 20px; position: absolute; width: 50px; }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
