import { Component, Input, OnDestroy, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { CoordinateApi, BookingView } from '../../../models';
import { MapboxService, IGhostMap, ICircleRadius } from '../../../services';

import mapboxgl from "mapbox-gl";

@Component({
    selector: 'ghost-map',
    styleUrls: ['ghost-map.component.css'],
    templateUrl: './ghost-map.component.html'
})

export class GhostMapComponent implements AfterContentInit, OnDestroy {
    @Input() public set coordinate(value: CoordinateApi) {
        if (value && this.coordinate && value !== this.coordinate) {
            this.setDefaultMapCoordinates(value, true);
            if (this.radius) {
                this.radius.coordinates = [value.longitude, value.latitude];
                this.setCircleRadius(this.radius);
            };
        };
        this._coordinate = value;
    };
    @Input() public booking: BookingView;
    @Input() public canMoveRouteMarkers: boolean;
    @Input() public set radius(value: ICircleRadius) {
        if (value && this.radius && value !== this.radius) {
            this.setCircleRadius(value);
        };
        this._radius = value;
    };
    @Input() public editable: boolean;
    @Output() onCoordinateChange: EventEmitter<CoordinateApi> = new EventEmitter<CoordinateApi>();

    public _coordinate: CoordinateApi;
    public _radius: ICircleRadius;

    public map: IGhostMap;
    public mapId: string = Math.random().toFixed(5);
    private marker: mapboxgl.Marker;

    public constructor(
        private mapboxService: MapboxService,
    ) { };

    public get coordinate(): CoordinateApi {
        return this._coordinate;
    };

    public get radius(): ICircleRadius {
        return this._radius;
    };

    public ngAfterContentInit(): void {
        setTimeout(() => {
            this.map = this.mapboxService.getDisplayMap(this.mapId, () => {
                if (this.booking) {
                    this.setBookingMap(this.booking);
                };
                if (this.coordinate && this.coordinate.latitude && this.coordinate.longitude) {
                    this.setDefaultMapCoordinates(null, true);
                };
                if (this.radius) {
                    this.setCircleRadius(this.radius);
                };
                this.addClickEvent();
            });
            this.map.instance.addControl(new mapboxgl.NavigationControl());
        });
    };

    public ngOnDestroy(): void {
        this.map.removeInstance();
    };

    public addClickEvent(): void {
        this.map.instance.on('click', (event) => {
            if (this.editable) {
                this.coordinate.latitude = event.lngLat.lat;
                this.coordinate.longitude = event.lngLat.lng;
                this.setMarker();
                this.onCoordinateChange.emit(this.coordinate);
            };
        });
    };

    public setBookingMap(booking: BookingView | Partial<BookingView>): void {
        this.mapboxService.setBookingMap(booking as BookingView, this.map, false, this.canMoveRouteMarkers);
    };

    public validCoordinate = (): boolean => (this.coordinate.latitude != 0 && this.coordinate.longitude != 0);

    private setDefaultMapCoordinates(coordinates?: CoordinateApi, centerMap?: boolean): void {
        this.setMarker(coordinates);
        if (centerMap) {
            this.mapboxService.setCentreOfMapByCoordinate(this.map, coordinates || this.coordinate);
        };
    };

    private setCircleRadius(radius: ICircleRadius): void {
        this.mapboxService.addCircleRadius(this.map, radius);
    };

    private initMarker(): void {
        if (!this.validCoordinate()) {
            return;
        } else {    
            this.marker = new mapboxgl.Marker().setLngLat([this.coordinate.longitude, this.coordinate.latitude]).addTo(this.map.instance);
        };
    };

    private setMarker(coordinates?: CoordinateApi): void {
        if (this.map.genericMarker) {
            this.map.genericMarker.remove();
        };
        const coords = coordinates || this.coordinate;
        this.marker ? this.marker.setLngLat([coords.longitude, coords.latitude]) : this.initMarker();
    };
};