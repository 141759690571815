import { Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class TitleCustomStrategyService extends TitleStrategy {
    updateTitle(snapshot: RouterStateSnapshot): void {
        let title = this.buildTitle(snapshot);
        if (title) {
            this.title.setTitle(`iGo Portal - ${title}`);
        }
    }

    constructor(private title: Title) {
        super();
    }
}
