import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DisputeView } from 'src/models';
import { GenericDatePipe } from '../pipes/generic-date.pipe';

const datePipeShort = new GenericDatePipe();
datePipeShort.format = 'short';
@Component({
    selector: 'dispute-status-cell',
    template: `<div>
        <span class="status-card {{class}}">{{status}}</span> {{'global.modals.activity_dispute.on' | translate}} {{statusUpdatedDate}}
    </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [`
        :host { display: flex; flex-flow: column; height: 100%; justify-content: center; padding: 0 20px; width: 100%; }
        .status-card { background-color: grey; border-radius: 5px; color: white; padding: 5px; }
        .status-card.danger { background-color: #dc3545; }
        .status-card.success { background-color: #28a745; }
    `]
})
export class DisputeStatusCellTplComponent {
    @Input() public set row(item: DisputeView) {
        if (item) {
            if (item.status.toLowerCase() == 'resolved') {
                this.class = 'success';
                this.status = this.translateService.instant('global.modals.activity_dispute.resolved');
            } else if (item.status.toLowerCase() == 'escalated') {
                this.class = 'danger';
                this.status = this.translateService.instant('global.modals.activity_dispute.escalated');
            } else if (item.status.toLowerCase() == 'open') {
                this.class = '';
                this.status = this.translateService.instant('global.modals.activity_dispute.opened');
            };
            this.statusUpdatedDate = datePipeShort.transform(String(item.statusDateTime));
            this.cd.detectChanges();
        };
    };
    public class: string = '';
    public status: string = '';
    public statusUpdatedDate: string = '';
    public constructor(
        private cd: ChangeDetectorRef,
        private translateService: TranslateService
    ) { };
};