import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AdminRolesTableCustomisation } from "./admin-roles-table.customisation";
import { CellClickEvent, TableComponent, TableCustomisation } from "@autocab/ghost-vs-table";
import { FilterService, GhostVSTableService, RolesService, SharedModalsService, UserService } from "src/services";
import { GetAllRolesResponseView, UserRoleView } from '../../../models';
import { GhostVSTableFilterComponent } from "src/shared/components/ghost-vs-table-filter/ghost-vs-table-filter.component";
import { HttpErrorResponse } from "@angular/common/http";
import { IPortalClassTypes } from "src/interfaces/portal-class-types.interface";
import { ITableFooterItem } from './../ghost-vs-table-footer/ghost-vs-table-footer.component';
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'admin-roles',
    templateUrl: './admin-roles.component.html'
})

export class AdminRolesComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(GhostVSTableFilterComponent) public tableFilter: GhostVSTableFilterComponent;
    @ViewChild(TableComponent) public table: TableComponent;
    @Input() public class: IPortalClassTypes = 'default';
    public adminRolesTableCustomisation: TableCustomisation<UserRoleView> = AdminRolesTableCustomisation;
    public filterSearchTerm: string = '';
    public getRolesUrl: string = '';
    public hasPermission: boolean = true;
    public loading: boolean = false;
    public rolesResult: GetAllRolesResponseView = null;
    public rows: UserRoleView[] = [];
    public tableFooterItems: Array<ITableFooterItem>;
    public tempRows: UserRoleView[] = [];

    private clickEventSubscription: Subscription;

    public constructor(
        private filterService: FilterService,
        private ghostVSTableService: GhostVSTableService,
        private rolesService: RolesService,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService,
        private userService: UserService
    ) { };

    public ngOnInit(): void {
        this.getRolesUrl = this.userService.getUserHalLink('GetAllRoles')?.href;
        this.hasPermission = !!this.getRolesUrl;
        this.getRolesUrl && this.recalculateData();
    };

    public ngAfterViewInit(): void {
        this.table?.resetTableCustomisation();
        this.clickEventSubscription = this.table?.cellClick.subscribe({
            next: (clickEvent: CellClickEvent<UserRoleView>) => {
                clickEvent.event.stopPropagation();
                clickEvent.columnKey === 'delete' && !clickEvent.row.disableDelete && this.onDelete(clickEvent.row);
            }
        });
    };

    public ngOnDestroy(): void {
        this.clickEventSubscription?.unsubscribe();
    };

    public onCreateRole(): void {
        this.sharedModalsService.showUserRoleModal(this.rolesResult._links, null, { windowClass: this.class }).result.then((res: UserRoleView) => {
            this.addRowInTable(res);
        }).catch(() => { });
    };

    public onDelete(role: UserRoleView): void {
        this.sharedModalsService.showConfirmationModal(
            this.translateService.instant('global.modals.role.delete_role.title'),
            this.translateService.instant('global.modals.role.delete_role.message').replace('{0}', role.name),
            null, null, { windowClass: this.class }
        ).result.then(() => this.deleteRole(role)).catch(() => { });
    };

    public onFilterChange($event?: string): void {
        const filteredRows = this.filterService.filterGhostVSTableResults(false, this.filterSearchTerm, this.tempRows, this.adminRolesTableCustomisation);
        this.table.setRowsCollection(filteredRows, filteredRows, false, 0).then(() => this.rows = filteredRows);
    };

    public onSelect(): void {
        this.sharedModalsService.showUserRoleModal(
            this.rolesResult._links,
            this.adminRolesTableCustomisation.selected,
            { windowClass: this.class }
        ).result.then((res: UserRoleView | string) => {
            this.updateRowById(res);
        }).catch(() => { });
    };

    public recalculateData(): void {
        this.loading = true;
        this.rolesService.getRolesObservable(this.getRolesUrl).subscribe({
            next: this.onInitialiseData,
            error: this.onFailure
        });
    };

    private addRowInTable(res: UserRoleView): void {
        this.tempRows = this.tempRows.concat(res);
        this.rows = [...this.tempRows];
        this.table.setRowsCollection(this.tempRows, this.tempRows);
        this.filterSearchTerm && this.onFilterChange();
    };

    private deleteRole(role: UserRoleView): void {
        this.rolesService.deleteRoleAsync(role).subscribe({
            next: (id: string) => this.updateRowById(id),
            error: this.onFailure
        });
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.loading = false;
        this.sharedModalsService.showServerValidationErrorsModal(res, this.translateService.instant('igo_portal.admin.roles'));
    };

    private onInitialiseData = (): void => {
        this.loading = false;
        this.rolesResult = this.rolesService.getRoles();
        this.tempRows = [...this.rolesResult?.roles];
        this.rows = [...this.tempRows];
        this.tableFooterItems = [{ key: 'global.tables.igo_portal.admin.roles.total_role', description: this.tempRows.length }];

        if (this.tempRows.length) {
            !this.tempRows.some(i => i._links.DeleteRole) && this.table.hideColumn('delete');
        };
    };

    private updateRowById(res: UserRoleView | string): void {
        if (res instanceof UserRoleView) {
            //Editing the Role returns the full role object
            if (this.rows.find(i => i.id == res.id)) {
                this.table.updateRow(res);
                Object.assign(this.tempRows.find(i => i.id == res.id), res);
            };
        } else if (typeof res == 'string') {
            //Deleting the Role returns the role ID
            this.ghostVSTableService.handleRowDelete<UserRoleView>(res, this.table, this.rows, this.tempRows);
        };
        this.rows = [...this.tempRows];
        this.filterSearchTerm && this.onFilterChange();
    };
};