import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IPieChartConfig } from '../../../interfaces/charts.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'ghost-pie-chart',
    styleUrls: ['ghost-pie-chart.component.css'],
    templateUrl: './ghost-pie-chart.component.html'
})

export class GhostPieChartComponent {
    @Input() public set config(value: IPieChartConfig) {
        this._config = value;
        if (value) {
            const totalValue = this.config.data.find(i => i.extra == 'all')?.value || 0;
            this.totalDataLength = totalValue;
            for (let i = 0; i < this.config.data.length; i++) {
                if (!totalValue) {
                    this.totalDataLength += this.config.data[i].value;
                };
                this._config.data[i].name = this.translateService.instant(this._config.data[i].name);
            };
        };
    };
    @Input() public selected: string;
    @Output() selectChart: EventEmitter<any> = new EventEmitter<any>();
    public totalDataLength: number = null;
    private _config: IPieChartConfig = null;

    public constructor(private translateService: TranslateService) { };

    public get config(): IPieChartConfig {
        for (let i = 0; i < this._config.data.length; i++) {
            if (this._config.data[i]['data'].length == 0 && !this._config.data[i].showIfZero) {
                this._config.data.splice(i, 1);
                i--;
            };
        };
        return this._config;
    };

    public onSelectGraph(event): void {
        let data = this.config.data.find(i => i.extra == event.extra).data;
        event.data = data;
        this.selectChart.emit(event);
    };
};