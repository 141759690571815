// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon {
    width: 35px;
    height: 35px;
    display: inline-block;
}

.icon-pointer {
    cursor: pointer;
}

.feature-description {
    background: url('feature-description.5baac2db86d50a0a.svg') no-repeat;
    height: 20px;
    vertical-align: sub;
}

.page-help {
    background: url('/shared/assets/svgs/page-help.svg') no-repeat;
    vertical-align: sub;
}

.fa-info-circle {
    color: rgb(var(--igo-dark-grey));
}

i.table-loading {
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    width: -moz-fit-content;
    width: fit-content;
}

.stacked-icons-wrapper {
    height: 25px;
    position: relative;
    width: 20px;
}

.stacked-icons-wrapper .stacked-icon {
    position: absolute;
    transform: translateX(0px);
    transition: all 0.3s ease;
}

.stacked-icons-wrapper .child-icon {
    position: absolute;
    opacity: 0.4;
}`, "",{"version":3,"sources":["webpack://./src/styles/icons.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,qEAAyE;IACzE,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,8DAA8D;IAC9D,mBAAmB;AACvB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,wBAAmB;IAAnB,mBAAmB;IACnB,YAAY;IACZ,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,0BAA0B;IAC1B,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".icon {\n    width: 35px;\n    height: 35px;\n    display: inline-block;\n}\n\n.icon-pointer {\n    cursor: pointer;\n}\n\n.feature-description {\n    background: url(src/shared/assets/svgs/feature-description.svg) no-repeat;\n    height: 20px;\n    vertical-align: sub;\n}\n\n.page-help {\n    background: url('/shared/assets/svgs/page-help.svg') no-repeat;\n    vertical-align: sub;\n}\n\n.fa-info-circle {\n    color: rgb(var(--igo-dark-grey));\n}\n\ni.table-loading {\n    height: fit-content;\n    margin: auto;\n    width: fit-content;\n}\n\n.stacked-icons-wrapper {\n    height: 25px;\n    position: relative;\n    width: 20px;\n}\n\n.stacked-icons-wrapper .stacked-icon {\n    position: absolute;\n    transform: translateX(0px);\n    transition: all 0.3s ease;\n}\n\n.stacked-icons-wrapper .child-icon {\n    position: absolute;\n    opacity: 0.4;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
