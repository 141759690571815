import { Component, Input, Output, EventEmitter, OnDestroy, ViewEncapsulation } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SettingsService } from 'src/services';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class DatepickerComponent implements OnDestroy {
    @Output() onDateRangeChanged: EventEmitter<{ fromDate: Date; toDate: Date; }> = new EventEmitter();
    @Output() onChangedSingleDate: EventEmitter<Date> = new EventEmitter();

    @Input() public set fromDate(value: Date | null) {
        this._fromDate = value;
    };
    @Input() public set toDate(value: Date | null) {
        this._toDate = value;
    };
    @Input() public datepickerType: 'Single' | 'RangeSingle' | 'Range' = 'Single';
    @Input() public disabled = false;
    @Input() public labelKey: string;
    @Input() public showLabel = true;
    @Input() public readonly placeholder: string;
    @Input() public readonly required = false;
    @Input() public readonly maxDate: Date | null = null;
    @Input() public readonly minDate: Date = new Date('12-31-' + (new Date().getFullYear() - 10));
    @Input() public readonly class: string;

    public get fromDate(): Date | null {
        return this._fromDate;
    };

    public get toDate(): Date | null {
        return this._toDate;
    };

    private _fromDate: Date | null = null;
    private _toDate: Date | null = null;

    private readonly _destroying$ = new Subject<void>();

    constructor(
        private _adapter: DateAdapter<any>,
        private settingsService: SettingsService
    ) {
        this.settingsService.onSetLanguageCode$.pipe(takeUntil(this._destroying$)).subscribe(locale => this._adapter.setLocale(locale));
    };

    public ngOnDestroy(): void {
        this._destroying$.next();
        this._destroying$.complete();
    };

    public onSelectDateRange(event: MatDatepickerInputEvent<Date>, isFromDate: boolean): void {
        if (isFromDate) {
            this.fromDate = event.value;
            this.toDate = null;
        } else {
            this.toDate = event.value;
        };
        this.emitDateRange();
    };

    public onSelectSingleDate(event: MatDatepickerInputEvent<Date>): void {
        this.onChangedSingleDate.emit(event.value);
    };

    private emitDateRange(): void {
        this.onDateRangeChanged.emit({
            fromDate: this.fromDate,
            toDate: this.toDate,
        });
    };

    public clearDate(isFromDate: boolean): void {
        if (isFromDate) {
            this.fromDate = null;
        } else {
            this.toDate = null;
        }
        this.emitDateRange();
    };

    public clearDateRange(emit: boolean = true): void {
        this.fromDate = null;
        this.toDate = null;
        emit && this.emitDateRange();
    };
};
