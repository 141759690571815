import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router, Route } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ghost-sidebar',
    templateUrl: './ghost-sidebar.component.html',
})

export class GhostSidebarSidebarComponent implements OnInit, OnDestroy {
    private eventsSubscription: Subscription;
    private _routes = [];
    @Input() public set routes(routes: Route[]) {
        this._routes = [];
        if (routes && routes.length && !routes[0].path && routes[0].children) {
            const childRoutes = routes[0].children;
            const childRoutesLen = routes[0].children.length;
            for (let i = 0; i < childRoutesLen; i++) {
                if (!childRoutes[i].data || !childRoutes[i].data.engineer) {
                    this._routes.push(childRoutes[i]);
                };
            };
        };
    };
    public get routes(): Route[] { return this._routes; };
    @Input() public baseRoute: string; // Path of the component that contains the sidebar
    public location = '';

    public constructor(private router: Router) { };

    public ngOnInit(): void {
        this.location = this.router.url;
        this.eventsSubscription = this.router.events.subscribe({
            next: () => this.location = this.router.url
        });
    };

    public ngOnDestroy(): void {
        this.eventsSubscription?.unsubscribe();
    };
};