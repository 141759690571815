import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IPortalClassTypes } from 'src/interfaces/portal-class-types.interface';
import { MatOption } from '@angular/material/core';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

@Component({
    selector: 'typeahead-input',
    templateUrl: 'typeahead-input.component.html',
})

export class TypeaheadInputComponent {
    @ViewChild('input') public input: ElementRef;
    @ViewChild('singleSelectOption') public singleSelectOption: MatOption;
    @Input() public allOptions: any[];
    @Input() public availableOptions: any[];
    @Input() public canPreviewOption: boolean = false;
    @Input() public class: IPortalClassTypes = 'default';
    @Input() public clearValue: boolean;
    @Input() public displayValue: string;
    @Input() public key: string = 'id';
    @Input() public minFilterLength: number = null;
    @Input() public placeholderKey: string = '';
    @Input() public preventClearOnBlur: boolean;
    @Input() public previewOptionFn: Function;
    @Input() public readonly: boolean = false;
    @Input() public required: boolean;
    @Input() public selectedDisplayName: string = '';
    @Input() public selectedListTitle: string = '';
    @Input() public selectedOptions: any[];
    @Input() public selectedOptionsView: any[];
    @Input() public tooltipText: string;
    @Input() public unselectableOptionsView: any[] = [];
    @Input() public virtualScrollEnabled: boolean = false;
    @Output() public emitSelectedOptions: EventEmitter<{ selected: any[], selectedView: any[]; }> = new EventEmitter<{ selected: any[], selectedView: any[]; }>();
    @Output() public onPreviewOption: EventEmitter<any> = new EventEmitter<any>();
    @Output() public onSelectSingleOption: EventEmitter<any> = new EventEmitter<any>();

    public filteredOptions: Observable<any[]>;
    public formControl = new UntypedFormControl();
    public loadingOptions: boolean = false;

    public clearSingleSelectOption(): void {
        this.input.nativeElement.value = '';
        this.selectedDisplayName = '';
        this.singleSelectOption.deselect();
        this.onSelectSingleOption.emit(null);
        this.initialiseFilteredOptions();
    };

    public onBlur($event): void {
        if (!this.preventClearOnBlur && !this.required && !this.selectedOptions && !$event.target.value) {
            this.onSelectSingleOption.emit(null);
        } else {
            let matchingItem = this.availableOptions.find(item => item[this.displayValue] && item[this.displayValue].toLowerCase() == $event.target.value.toLowerCase());
            matchingItem && matchingItem[this.displayValue] !== this.selectedDisplayName && this.setSelectedOption(matchingItem);
        };
    };

    public onClickPreviewOption(option?: any): void {
        this.onPreviewOption.emit(option);
    };

    public onPreview($event): void {
        $event.stopPropagation();
        this.previewOptionFn();
    };

    public onSelectOption(event): void {
        if (event.option.id) {
            let option = this.availableOptions.find(item => item[this.key] == event.option.id);
            this.setSelectedOption(option);
        };
    };

    public initialiseFilteredOptions(displayName?: string): void {
        this.filteredOptions = this.formControl.valueChanges.pipe(
            startWith(''), map(value => this._filter(value))
        );
        this.formControl.setValue(displayName || this.selectedDisplayName);
    };

    public onRemoveOption(option: any): void {
        let newOptions = [];
        let newOptionsView = [];
        const index = this.selectedOptionsView.findIndex(selectedOption => selectedOption[this.key] == option[this.key]);
        for (let i = 0; i < this.selectedOptionsView.length; i++) {
            if (i !== index) {
                newOptions.push(this.selectedOptions[i]);
                newOptionsView.push(this.selectedOptionsView[i]);
            };
        };
        this.selectedOptions = newOptions;
        this.selectedOptionsView = newOptionsView;
        this.emitSelectedOptions.emit({ selected: this.selectedOptions, selectedView: this.selectedOptionsView });
        this.availableOptions = this.allOptions.filter(option => this.selectedOptions.findIndex(selected => option[this.key] == selected) < 0);
        this.initialiseFilteredOptions();
    };

    private setSelectedOption(selected): void {
        const item = selected || this.availableOptions.find(option => option[this.key] == selected[this.key]);
        if (this.selectedOptions) {
            let newSelectedOptions = [...this.selectedOptions];
            let newSelectedOptionsView = [...this.selectedOptionsView];
            newSelectedOptions.push(item[this.key]);
            newSelectedOptionsView.push(item);
            this.selectedOptions = newSelectedOptions;
            this.selectedOptionsView = newSelectedOptionsView;
            this.emitSelectedOptions.emit({ selected: this.selectedOptions, selectedView: this.selectedOptionsView });
            this.availableOptions = this.allOptions.filter(option => this.selectedOptions.findIndex(selected => option[this.key] == selected) < 0);
            this.initialiseFilteredOptions();
        } else {
            this.input.nativeElement.value = item[this.displayValue];
            this.onSelectSingleOption.emit(item);
        };
    };

    private _filter(value: string): any[] {
        const filterValue = value ? value.toLowerCase() : '';
        if (this.minFilterLength && this.availableOptions.length > 1000) {
            if (filterValue && filterValue.length >= this.minFilterLength) {
                return this.availableOptions.filter(option => option[this.displayValue].toLowerCase().includes(filterValue));
            };
            return [];
        } else {
            if (!value || !value.length || value.length > 2) {
                return this.availableOptions?.filter(option => option[this.displayValue].toLowerCase().includes(filterValue));
            };
            return this.availableOptions;
        };
    };
};