import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from "@angular/core";
import MapboxDraw, { DrawCreateEvent, DrawDeleteEvent, DrawUpdateEvent } from '@mapbox/mapbox-gl-draw';
import mapboxgl from 'mapbox-gl';
import { CoordinateApi, RegionView } from "src/models";
import { MapboxService } from "src/services";
import styles from "src/shared/assets/json/polygon-map-styles.json";

@Component({
    selector: 'region-builder-map',
    styleUrls: ['region-builder-map.component.css'],
    templateUrl: './region-builder-map.component.html'
})

export class RegionBuilderMapComponent implements AfterViewInit, OnChanges, OnDestroy {
    @Input() public canUpdate: boolean = true;
    @Input() public coordinate: CoordinateApi;
    @Input() public mapId: string = 'map';
    @Input() public zoom: number = 12;
    @Input() public set regionCopy(value: RegionView) {
        this._regionCopy = value;
        if (this.map && value && value.points.length) {
            this.redrawRegion(value);
        };
    };
    @Output() public onCreateRegion: EventEmitter<RegionView> = new EventEmitter<RegionView>();
    @Output() public onUpdateRegion: EventEmitter<RegionView> = new EventEmitter<RegionView>();

    public get regionCopy(): RegionView {
        return this._regionCopy;
    };

    public draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
            polygon: true,
            trash: true
        },
        userProperties: true,
        styles: styles
    });

    private map: mapboxgl.Map;
    private marker: mapboxgl.Marker;

    private _regionCopy: RegionView;

    public constructor(
        private mapboxService: MapboxService,
    ) { };

    public ngAfterViewInit(): void {
        this.buildMap();

        // Adding a new polygon
        this.map.on('draw.create', (e: DrawCreateEvent): void => {
            const polygons = this.draw.getAll();
            if (polygons && polygons.features.length > 1) {
                //Delete the existing polygon first
                this.draw.delete(String(polygons.features[0].id));
            };
            this.regionCopy.id = (this.regionCopy.id || e.features[0].id).toString();
            this.regionCopy.points = e.features[0].geometry['coordinates'][0].map(coords => {
                return new CoordinateApi({ latitude: coords[1], longitude: coords[0] });
            });

            this.onCreateRegion.emit(this.regionCopy);
        });

        // Deleting a polygon using the draw tools
        this.map.on('draw.delete', (e: DrawDeleteEvent): void => {
            this.regionCopy = new RegionView();
        });

        // Updating a polygon
        this.map.on('draw.update', (e: DrawUpdateEvent): void => {
            if (this.canUpdate) {
                this.regionCopy.points = e.features[0].geometry['coordinates'][0].map(coords => {
                    return new CoordinateApi({ latitude: coords[1], longitude: coords[0] });
                });
                this.onUpdateRegion.emit(this.regionCopy);
            } else {
                this.drawRegionAsPolygon(this.regionCopy, true);
            };
        });

        // Initialise Existing Map Area
        this.regionCopy.points.length ? this.drawRegionAsPolygon(this.regionCopy, true) : this.draw.changeMode('draw_polygon');
    };

    public ngOnChanges(): void {
        if (this.map) {
            if (this.validCoordinate()) {
                this.setMarker();
            } else {
                if (this.marker) {
                    this.marker.remove();
                    this.marker = null;
                };
            };
            this.moveCenter();
        };
    };

    public ngOnDestroy(): void {
        this.map.remove();
    };

    public moveCenter(coordinate?: CoordinateApi): void {
        coordinate && this.map.flyTo({
            center: [coordinate.longitude, coordinate.latitude],
            speed: 3,
            zoom: this.zoom
        });
    };

    public moveToCentreOfRegion(regionName: string): void {
        const coordinates = this.draw.get(regionName).geometry['coordinates'][0];
        const bounds = coordinates.reduce((bounds, coord) => {
            return bounds.extend(coord);
        }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
        this.map.fitBounds(bounds, {
            padding: 50
        });
        this.canUpdate && this.draw.changeMode('simple_select', { featureIds: [regionName] });
    };

    public redrawRegion(region): void {
        this.drawRegionAsPolygon(region, true);
    };

    public resize(): void {
        this.map.resize();
    };

    public validCoordinate = (): boolean => Boolean(this.coordinate.latitude && this.coordinate.longitude);

    private buildMap(): void {
        this.map = new mapboxgl.Map({
            center: [this.coordinate.longitude, this.coordinate.latitude],
            container: this.mapId,
            style: (<any>this.mapboxService.AUTOCAB_STYLE),
            zoom: this.zoom
        });
        this.map.addControl(new mapboxgl.NavigationControl());
        this.map.addControl(this.draw);
        this.map.dragRotate.disable();
    };

    private drawRegionAsPolygon(region: RegionView, moveToRegion?: boolean): void {
        let coords: number[][] = region.points.map(coord => [coord.longitude, coord.latitude]);
        // If the last coordinates in the map area are not the same as the first coordinates, add them to make a complete polygon that starts and ends on the same point
        if (coords[coords.length - 1][0] !== coords[0][0] && coords[coords.length - 1][1] !== coords[0][1]) {
            coords.push([region.points[0].latitude, region.points[0].longitude]);
        };
        this.draw.add({
            id: region.id || region.regionPolygonId,
            type: "Feature",
            properties: {},
            geometry: {
                type: "Polygon",
                coordinates: [coords],
            }
        });
        if (moveToRegion) {
            setTimeout(() => {
                this.moveToCentreOfRegion(region.id || region.regionPolygonId);
            }, 50);
        };
    };

    private setMarker(): void {
        this.marker && this.marker.setLngLat([this.coordinate.longitude, this.coordinate.latitude]);
    };
};