import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
    selector: 'cookie-consent',
    standalone: true,
    imports: [CommonModule, TranslateModule],
    templateUrl: './cookie-consent.component.html',
    styleUrls: ['./cookie-consent.component.css']
})
export class CookieConsentComponent {
    @Output() public hide: EventEmitter<boolean> = new EventEmitter();
    public showMore: boolean = false;

    public constructor() { };
};