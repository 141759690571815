// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
    font-size: 14px !important;
}

.sla-details {
    margin-bottom: 70px;
}

.sla-details:last-of-type {
    margin-bottom: 0;
}

.booking-stats-item {
    align-items: center;
    border: 1px solid rgba(var(--igo-dark-grey), 0.2);
    border-radius: 20px;
    display: flex;
    font-family: ZonaProBold;
    width: 50%;
}

.booking-stats-item .booking-stats-title {
    align-items: center;
    border-radius: 17px 0 0 17px;
    display: flex;
    flex: 1 1 auto;
    font-size: 16px;
    height: 100%;
    padding: 20px;
}

.booking-stats-item .booking-stats-value {
    align-items: center;
    border-radius: 0 20px 20px 0;
    display: flex;
    font-size: 22px;
    height: 100%;
    justify-content: center;
    min-width: -moz-fit-content;
    min-width: fit-content;
    padding: 20px;
    width: 70px;
}

.booking-stats-item .booking-stats-value.operator {
    background-color: rgb(var(--igo-operator-green));
}

.booking-stats-item .booking-stats-value.originator {
    background-color: rgb(var(--igo-originator-blue));
    color: rgb(var(--primary-white));
}

.booking-stats-item .booking-stats-value.igo {
    background-color: rgb(var(--igo-cyan));
}`, "",{"version":3,"sources":["webpack://./src/modules/shared-modals/modals/cx-connection/cx-connection.modal.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,iDAAiD;IACjD,mBAAmB;IACnB,aAAa;IACb,wBAAwB;IACxB,UAAU;AACd;;AAEA;IACI,mBAAmB;IACnB,4BAA4B;IAC5B,aAAa;IACb,cAAc;IACd,eAAe;IACf,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,mBAAmB;IACnB,4BAA4B;IAC5B,aAAa;IACb,eAAe;IACf,YAAY;IACZ,uBAAuB;IACvB,2BAAsB;IAAtB,sBAAsB;IACtB,aAAa;IACb,WAAW;AACf;;AAEA;IACI,gDAAgD;AACpD;;AAEA;IACI,iDAAiD;IACjD,gCAAgC;AACpC;;AAEA;IACI,sCAAsC;AAC1C","sourcesContent":["p {\n    font-size: 14px !important;\n}\n\n.sla-details {\n    margin-bottom: 70px;\n}\n\n.sla-details:last-of-type {\n    margin-bottom: 0;\n}\n\n.booking-stats-item {\n    align-items: center;\n    border: 1px solid rgba(var(--igo-dark-grey), 0.2);\n    border-radius: 20px;\n    display: flex;\n    font-family: ZonaProBold;\n    width: 50%;\n}\n\n.booking-stats-item .booking-stats-title {\n    align-items: center;\n    border-radius: 17px 0 0 17px;\n    display: flex;\n    flex: 1 1 auto;\n    font-size: 16px;\n    height: 100%;\n    padding: 20px;\n}\n\n.booking-stats-item .booking-stats-value {\n    align-items: center;\n    border-radius: 0 20px 20px 0;\n    display: flex;\n    font-size: 22px;\n    height: 100%;\n    justify-content: center;\n    min-width: fit-content;\n    padding: 20px;\n    width: 70px;\n}\n\n.booking-stats-item .booking-stats-value.operator {\n    background-color: rgb(var(--igo-operator-green));\n}\n\n.booking-stats-item .booking-stats-value.originator {\n    background-color: rgb(var(--igo-originator-blue));\n    color: rgb(var(--primary-white));\n}\n\n.booking-stats-item .booking-stats-value.igo {\n    background-color: rgb(var(--igo-cyan));\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
