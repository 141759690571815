export class RouteUtilities {
    public static parseUrlQueryParams(url: string): any {
        const keyValuePairs = [];
        const urlQueryParams: string = url.split('?')[1];
        const queryParamsArray: string[] = urlQueryParams.split('&');

        queryParamsArray.forEach(queryParams => {
            const keyValue: string[] = queryParams.split('=');
            keyValuePairs.push({ [keyValue[0]]: keyValue[1] });
        });

        const queryParams = keyValuePairs.reduce((arrObj, item) => Object.assign(arrObj, item, {}));

        return queryParams;
    };
};