import { Injectable } from '@angular/core';
import { iGoSession, SessionUser } from '../models/session.model';
import { Endpoints } from '../shared/endpoints';

@Injectable({ providedIn: 'root' })
export class SessionService {
    private static _session: iGoSession = null;

    public static getSessionUser = (): SessionUser => null;
    public static getSessionUserId = (): number => null;
    public static getToken = (): string => SessionService._session ? SessionService._session.token : '';

    public static setToken(token: string) {
        if (!SessionService._session) {
            SessionService.session = new iGoSession();
        };
        return SessionService._session.token = token;
    };

    public static get session(): iGoSession {
        return SessionService._session;
    };

    public static set session(session: iGoSession) {
        SessionService._session = session;
        if (SessionService._session && SessionService._session.apiUrl) {
            Endpoints.API_URL = SessionService._session.apiUrl;
        };
    };
};