import { DeleteRowCellTplComponent } from 'src/shared/table-cell-templates/delete-row-cell-template.component';
import { TableCustomisation } from '@autocab/ghost-vs-table';
import { UserRoleView } from '../../../models';
import { UserService } from '../../../services';

export const AdminRolesTableCustomisation: TableCustomisation<UserRoleView> = {
    customisationKey: 'AdminRolesTableCustomisation',
    tableName: 'global.tables.igo_portal.admin.roles.table_title',
    defaultOrderBy: ['+name'],
    defaultColumns: {
        delete: {
            width: 3,
            name: ' ',
            prop: 'delete',
            show: true,
            template: DeleteRowCellTplComponent,
            templateClickEmitter: true
        },
        name: {
            width: 48,
            name: 'igo_portal.admin.roles.table.name',
            orderBy: 'name',
            prop: 'name',
            show: true
        },
        totalPermissions: {
            width: 48,
            name: 'igo_portal.admin.roles.table.total_permissions',
            orderBy: 'permissions.length',
            prop: 'totalPermissions',
            show: true
        },
        allPermissions: {
            width: 48,
            name: 'igo_portal.admin.roles.table.all_permissions',
            orderBy: 'allPermissions',
            prop: 'allPermissions',
            show: false
        }
    }
};

UserService.registerCustomisation(AdminRolesTableCustomisation);