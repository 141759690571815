// Modules
import { NgModule } from '@angular/core';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CountUpModule } from 'ngx-countup';
import { DirectivesModule } from 'src/modules/directives/directives.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ModalHeaderComponent } from 'src/app/standalone/modal-header/modal-header.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
    NgbAlertModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbPopoverModule,
    NgbProgressbarModule,
    NgbRatingModule,
    NgbNavModule,
    NgbTooltipModule,
    NgbTypeaheadModule
} from '@ng-bootstrap/ng-bootstrap';
import { GhostVSTableModule } from '@autocab/ghost-vs-table';
import { GhostVSTableFilterModule } from './components/ghost-vs-table-filter/ghost-vs-table-filter.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TranslateModule } from '@ngx-translate/core';
import { ToggleSwitchModule } from './components/toggle-switch/toggle-switch.module';

// Pipes
import { CurrencyGlobalPipe } from './pipes/currency.pipe';
import { DatePipe } from '@angular/common';
import { FormatTimePipe } from './pipes/format-time.pipe';
import { GenericDatePipe } from './pipes/generic-date.pipe';
import { GenericDateTimePipe } from './pipes/generic-date-time.pipe';
import { SidebarRouteFilterPipe } from './pipes/sidebar-route-filter.pipe';

// Components
import { AccountTransactionsComponent } from './components/account-transactions/account-transactions.component';
import { ActivityBookingsFiltersComponent } from './components/activity-bookings-filters/activity-bookings-filters.component';
import { ActivityDisputesComponent } from './components/activity-disputes/activity-disputes.component';
import { ActivityDisputesChartsComponent } from './components/activity-disputes/activity-disputes-charts/activity-disputes-charts.component';
import { AddressDetailsFormComponent } from './components/address-details-form/address-details-form.component';
import { AddressSelectorComponent } from './components/address-selector/address-selector.component';
import { AdminRolesComponent } from './components/admin-roles/admin-roles.component';
import { AdminSettingsComponent } from './components/admin-settings/admin-settings.component';
import { AdminUsersComponent } from './components/admin-users/admin-users.component';
import { ColourPickerInputComponent } from './components/colour-picker-input/colour-picker-input.component';
import { CXConnectionsFiltersComponent } from './components/cx-connections-filters/cx-connections-filters.component';
import { CXMarketplacesComponent } from './components/cx-marketplaces/cx-marketplaces.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { GhostAreaChartComponent } from './components/ghost-area-chart/ghost-area-chart.component';
import { GhostBarChartComponent } from './components/ghost-bar-chart/ghost-bar-chart.component';
import { GhostGroupedBarChartComponent } from './components/ghost-grouped-bar-chart/ghost-grouped-bar-chart.component';
import { GhostMapComponent } from './components/ghost-map/ghost-map.component';
import { GhostPieChartComponent } from './components/ghost-pie-chart/ghost-pie-chart.component';
import { GhostSidebarSidebarComponent } from './components/ghost-sidebar/ghost-sidebar.component';
import { GhostTimepickerComponent } from './components/ghost-timepicker/ghost-timepicker.component';
import { GhostVSTableFooterComponent } from './components/ghost-vs-table-footer/ghost-vs-table-footer.component';
import { HoldToDeleteButtonComponent } from './components/hold-to-delete-button/hold-to-delete-button.component';
import { iGoButtonGroupComponent } from './components/igo-button-group/igo-button-group.component';
import { iGoButtonGroupMultiComponent } from './components/igo-button-group-multi/igo-button-group-multi.component';
import { ModalService, SharedModalsService } from '../services';
import { MultipleSelectionModalComponent } from 'src/modules/shared-modals/modals/multiple-selection/multiple-selection.modal';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { OperatorCoverageBuilderMapComponent } from './components/operator-coverage-builder-map/operator-coverage-builder-map.component';
import { RegionBuilderMapComponent } from './components/region-builder-map/region-builder-map.component';
import { SingleSelectionModalComponent } from 'src/modules/shared-modals/modals/single-selection/single-selection.modal';
import { SharedValueInputComponent } from './components/shared-value-input/shared-value-input.component';
import { ToastComponent } from './components/toast/toast.component';
import { TypeaheadInputComponent } from './components/typeahead-input/typeahead-input.component';
import { TransactionNotesModalComponent } from './components/account-transactions/modals/transaction-notes-modal/transaction-notes.modal';

// Standalone Components
import { FormGroupSectionHeaderComponent } from '../app/standalone/form-group-section-header/form-group-section-header.component';
import { NoAccessComponent } from '../app/standalone/no-access/no-access.component';

@NgModule({
    imports: [
        ClipboardModule,
        CountUpModule,
        DirectivesModule,
        GhostVSTableFilterModule,
        FormsModule,
        FormGroupSectionHeaderComponent,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatFormFieldModule,
        ModalHeaderComponent,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgbAlertModule,
        NgbDatepickerModule,
        NgbDropdownModule,
        NgbPopoverModule,
        NgbProgressbarModule,
        NgbRatingModule,
        NgbNavModule,
        NgbTooltipModule,
        NgbTypeaheadModule,
        NgxChartsModule,
        NgxDatatableModule,
        NoAccessComponent,
        ReactiveFormsModule,
        RouterModule,
        ScrollingModule,
        ToggleSwitchModule,
        TranslateModule,
    ],
    declarations: [
        AccountTransactionsComponent,
        ActivityBookingsFiltersComponent,
        ActivityDisputesComponent,
        ActivityDisputesChartsComponent,
        AddressDetailsFormComponent,
        AddressSelectorComponent,
        AdminRolesComponent,
        AdminSettingsComponent,
        AdminUsersComponent,
        ColourPickerInputComponent,
        CXConnectionsFiltersComponent,
        CXMarketplacesComponent,
        CurrencyGlobalPipe,
        DatepickerComponent,
        FormatTimePipe,
        GhostAreaChartComponent,
        GenericDatePipe,
        GenericDateTimePipe,
        GhostBarChartComponent,
        GhostGroupedBarChartComponent,
        GhostMapComponent,
        GhostSidebarSidebarComponent,
        GhostPieChartComponent,
        GhostTimepickerComponent,
        GhostVSTableFooterComponent,
        HoldToDeleteButtonComponent,
        iGoButtonGroupComponent,
        iGoButtonGroupMultiComponent,
        MultipleSelectionModalComponent,
        OperatorCoverageBuilderMapComponent,
        RegionBuilderMapComponent,
        SharedValueInputComponent,
        SidebarRouteFilterPipe,
        SingleSelectionModalComponent,
        ToastComponent,
        TypeaheadInputComponent,
        TransactionNotesModalComponent
    ],
    exports: [
        AccountTransactionsComponent,
        ActivityBookingsFiltersComponent,
        ActivityDisputesComponent,
        ActivityDisputesChartsComponent,
        AddressDetailsFormComponent,
        AddressSelectorComponent,
        AdminRolesComponent,
        AdminSettingsComponent,
        AdminUsersComponent,
        ClipboardModule,
        ColourPickerInputComponent,
        CXConnectionsFiltersComponent,
        CXMarketplacesComponent,
        CountUpModule,
        CurrencyGlobalPipe,
        DatepickerComponent,
        DirectivesModule,
        FormsModule,
        FormatTimePipe,
        GhostAreaChartComponent,
        GenericDatePipe,
        GenericDateTimePipe,
        GhostBarChartComponent,
        GhostGroupedBarChartComponent,
        GhostMapComponent,
        GhostPieChartComponent,
        GhostSidebarSidebarComponent,
        GhostTimepickerComponent,
        GhostVSTableModule,
        GhostVSTableFilterModule,
        GhostVSTableFooterComponent,
        HoldToDeleteButtonComponent,
        iGoButtonGroupComponent,
        iGoButtonGroupMultiComponent,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MultipleSelectionModalComponent,
        NgbAlertModule,
        NgbDatepickerModule,
        NgbDropdownModule,
        NgbModalModule,
        NgbPopoverModule,
        NgbProgressbarModule,
        NgbRatingModule,
        NgbNavModule,
        NgbTooltipModule,
        NgbTypeaheadModule,
        NgxChartsModule,
        NgxDatatableModule,
        NoAccessComponent,
        OperatorCoverageBuilderMapComponent,
        ReactiveFormsModule,
        RegionBuilderMapComponent,
        ScrollingModule,
        SidebarRouteFilterPipe,
        ToggleSwitchModule,
        TranslateModule,
        SharedValueInputComponent,
        SingleSelectionModalComponent,
        ToastComponent,
        TypeaheadInputComponent,
        TransactionNotesModalComponent
    ],
    providers: [
        DatePipe,
        ModalService,
        SharedModalsService
    ]
})
export class SharedModule { };