import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { IComponent } from '../../interfaces/component.interface';
import { SharedModalsService } from '../../services';

@Injectable()
export class UnsavedChangesGuard implements CanDeactivate<IComponent> {

    public constructor(private router: Router, private sharedModalsService: SharedModalsService) { };

    public async canDeactivate(component: IComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Promise<boolean> {
        return await this.checkComponentDataChange(component, nextState);
    };

    public async checkComponentDataChange(component: IComponent, nextState: RouterStateSnapshot): Promise<boolean> {
        if (component && !component.confirmProceedWithoutSaving && component.noDataChange && !component.noDataChange()) {
            this.sharedModalsService.showUnsavedChangesModal().result.then(() => {
                component.confirmProceedWithoutSaving = true;
                this.router.navigate([nextState.url]);
            }).catch(() => { });
            return false;
        };
        return true;
    };
};