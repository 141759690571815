import { Injectable } from '@angular/core';
import { ArrayUtilities } from 'src/shared/array.utilities';
import { AuthenticationService } from './authentication.service';
import { ChartsService } from './charts.service';
import { Endpoints } from 'src/shared/endpoints';
import {
    AuthenticationResponse,
    CreateIGoOperatorPayload,
    CreateIGoOriginatorPayload,
    CXAgentView,
    CXVendorView,
    DiscoverOperatorContactDetailsApi,
    EnabledAuditView,
    EnabledAuditsListApi,
    EnabledAuditsListView,
    GetAllCompanyOriginatorsResponseApi,
    GetAllCompanyOriginatorsResponseView,
    GetAllCompanyOperatorsResponseApi,
    GetAllCompanyOperatorsResponseView,
    GetAllCXOriginatorsResponseView,
    GetAllCXOriginatorsResponseApi,
    GetAllCXOperatorsResponseApi,
    GetAllCXOperatorsResponseView,
    GetAllIGoCompaniesResponseApi,
    GetAllIGoCompaniesResponseView,
    GetAllOperatorsResponseApi,
    GetAllOperatorsResponseView,
    GetAllOriginatorsResponseApi,
    GetAllOriginatorsResponseView,
    GetOperatorsPayload,
    iGoCompanyApi,
    iGoCompanyView,
    iGoCompanyOperatorApi,
    iGoCompanyOperatorCoverageAreaApi,
    iGoCompanyOperatorCoverageAreaView,
    iGoCompanyOperatorPartialView,
    iGoCompanyOperatorView,
    iGoCompanyOriginatorApi,
    iGoCompanyOriginatorPartialView,
    iGoCompanyOriginatorView,
    iGoSession,
    LinkIGoOperatorPayload,
    LinkIGoOriginatorPayload,
    LookupType,
    OperatorProfileContactInfoApi,
    RegionView,
    StatusAuditView,
    UpdateIGoOperatorPayload,
    UpdateIGoOriginatorAsIGoPayload
} from 'src/models';
import { ErrorUtilities } from 'src/shared/error.utilities';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { GainsightService } from './gainsight.service';
import { IChartConfigMap } from 'src/interfaces/charts.interface';
import { ObjectUtilities } from 'src/shared/object.utilities';
import { Observable, Subject } from 'rxjs';
import { PortalsService } from './portals.service';
import { RequestService } from './request.service';
import { SessionService } from './session.service';
import { StorageService } from './storage.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class iGoCompaniesService {
    public companies: GetAllIGoCompaniesResponseView = null;
    public operators: GetAllOperatorsResponseView = null;
    public originators: GetAllOriginatorsResponseView = null;

    public currentOperator: Subject<iGoCompanyOperatorView | iGoCompanyOperatorPartialView> = new Subject();
    public currentOriginator: Subject<iGoCompanyOriginatorView | iGoCompanyOriginatorPartialView> = new Subject();

    public constructor(
        private authenticationService: AuthenticationService,
        private chartService: ChartsService,
        private http: RequestService,
        private portalsService: PortalsService
    ) { };

    public getIGoCompanies = (): GetAllIGoCompaniesResponseView => this.companies;
    public getOperators = (): GetAllOperatorsResponseView => this.operators;
    public getOriginators = (): GetAllOriginatorsResponseView => this.originators;

    public accessProfileAsync(url: string): Observable<AuthenticationResponse> {
        return this.http.authenticatedPost<AuthenticationResponse>(url, {}, {}, 'global.request.accessing_profile').pipe(
            map(res => {
                //Set the new token.
                SessionService.setToken(res.access_token);
                //Set the new session in SessionService.
                SessionService.session = new iGoSession({
                    apiUrl: Endpoints.API_URL,
                    token: res.access_token,
                    tokenExpiry: res.expiry_in,
                    tokenType: res.token_type,
                });
                //Set the new session in local storage.
                StorageService.setItem(this.authenticationService.sessionStorageKey, SessionService.session);
                return res;
            })
        );
    };

    public cacheCurrentOperator(operator?: iGoCompanyOperatorView | iGoCompanyOperatorPartialView): void {
        operator ? StorageService.setItem('operator', operator) : StorageService.deleteItem('operator');
        operator ? sessionStorage.setItem('operator', JSON.stringify(operator)) : sessionStorage.removeItem('operator');
        UserService.updateGainsightUserInfo();
        this.currentOperator.next(operator);
    };

    public cacheCurrentOriginator(originator?: iGoCompanyOriginatorView | iGoCompanyOriginatorPartialView): void {
        originator ? StorageService.setItem('originator', originator) : StorageService.deleteItem('originator');
        originator ? sessionStorage.setItem('originator', JSON.stringify(originator)) : sessionStorage.removeItem('originator');
        UserService.updateGainsightUserInfo();
        this.currentOriginator.next(originator);
    };

    public createIGoCompanyAsync(url: string, company: iGoCompanyView): Observable<iGoCompanyView> {
        return this.http.authenticatedPost<iGoCompanyApi>(url, new iGoCompanyApi(company), {}, 'global.request.creating_company').pipe(
            map(this.mapIGoCompanyToViewModel)
        );
    };

    public createIGoOperatorAsync(url: string, operator: CreateIGoOperatorPayload): Observable<iGoCompanyOperatorView> {
        return this.http.authenticatedPost<iGoCompanyOperatorApi>(url, new CreateIGoOperatorPayload(operator), {}, 'global.request.creating_operator').pipe(
            tap(res => {
                res && GainsightService.trackEvent('CreateOperator');
            }),
            map(this.mapIGoOperatorToViewModel)
        );
    };

    public createIGoOriginatorAsync(url: string, originator: CreateIGoOriginatorPayload): Observable<iGoCompanyOriginatorView> {
        const payload: CreateIGoOriginatorPayload = new CreateIGoOriginatorPayload(originator);
        const { EventAuthorizationSettings } = payload;
        if (EventAuthorizationSettings.Mode !== 'BasicAuth') { EventAuthorizationSettings.BasicAuth = null; };
        if (EventAuthorizationSettings.Mode !== 'OAuth2') { EventAuthorizationSettings.OAuth2 = null; };

        return this.http.authenticatedPost<iGoCompanyOriginatorApi>(url, payload, {}, 'global.request.creating_originator').pipe(
            tap(res => {
                res && GainsightService.trackEvent('CreateOriginator');
            }),
            map(this.mapIGoOriginatorToViewModel)
        );
    };

    public deleteIGoCompanyAsync(company: iGoCompanyView): Observable<string> {
        return this.http.authenticatedDelete<void>(company._links.DeleteCompany.href, {}, 'global.request.deleting_company').pipe(
            map(res => company.id)
        );
    };

    public deleteIGoOperatorAsync(operator: iGoCompanyOperatorView | iGoCompanyOperatorPartialView): Observable<string> {
        return this.http.authenticatedDelete<void>(operator._links.DeleteOperator.href, {}, 'global.request.deleting_operator').pipe(
            map(res => operator.id)
        );
    };

    public deleteIGoOriginatorAsync(originator: iGoCompanyOriginatorView | iGoCompanyOriginatorPartialView): Observable<string> {
        return this.http.authenticatedDelete<void>(originator._links.DeleteOriginator.href, {}, 'global.request.deleting_originator').pipe(
            map(res => originator.id)
        );
    };

    public discoverOperatorsObservable(searchArea: RegionView): Observable<GetAllOperatorsResponseView> {
        const url = Endpoints.API_URL + Endpoints.DISCOVER_OPERATORS;
        return this.http.authenticatedPost<GetAllOperatorsResponseApi>(url, { points: searchArea.discoverPoints }, {}, 'global.request.finding_operators').pipe(
            map(res => new GetAllOperatorsResponseView({ operators: ArrayUtilities.sortAlphabetically(res.operators, 'name'), _links: res._links }))
        );
    };

    public filterIGoCompaniesOriginators(filteredOriginators?: iGoCompanyOriginatorView[]): IChartConfigMap {
        let originators = this.originators?.originators;
        if (filteredOriginators) {
            originators = filteredOriginators;
        };

        this.chartService.resetChartData('iGoCompaniesOriginatorsChartConfig');

        originators.forEach(originator => {
            this.chartService.iGoCompaniesOriginatorsChartConfig.all.data.push(originator);
            if (originator.active) {
                this.chartService.iGoCompaniesOriginatorsChartConfig.enabled.data.push(originator);
            } else {
                this.chartService.iGoCompaniesOriginatorsChartConfig.disabled.data.push(originator);
            };
        });

        return this.chartService.setChartValues('iGoCompaniesOriginatorsChartConfig');
    };

    public getAllCXOperatorsObservable(): Observable<GetAllCXOperatorsResponseView> {
        return this.http.authenticatedGet<GetAllCXOperatorsResponseApi>(Endpoints.API_URL + Endpoints.OPERATORS._CX_OPERATOR, {}, 'global.request.loading_operators').pipe(
            map(res => new GetAllCXOperatorsResponseView({ vendors: ArrayUtilities.sortAlphabetically(res.vendors, 'name'), _links: res._links }))
        );
    };

    public getAllCXOriginatorsObservable(): Observable<GetAllCXOriginatorsResponseView> {
        return this.http.authenticatedGet<GetAllCXOriginatorsResponseApi>(Endpoints.API_URL + Endpoints.ORIGINATORS._CX_ORIGINATOR, {}, 'global.request.loading_originators').pipe(
            map(res => new GetAllCXOriginatorsResponseView({ agents: ArrayUtilities.sortAlphabetically(res.agents, 'name'), _links: res._links }))
        );
    };

    public getAllOriginatorsObservable(url: string): Observable<GetAllOriginatorsResponseView> {
        return this.http.authenticatedGet<GetAllOriginatorsResponseApi>(url, {}, 'global.request.loading_originators').pipe(
            map(res => new GetAllOriginatorsResponseView({ originators: ArrayUtilities.sortAlphabetically(res.originators, 'name'), _links: res._links }))
        );
    };

    public getAllOperatorsObservable(payload: GetOperatorsPayload, start: number = 0, url?: string): Observable<GetAllOperatorsResponseView> {
        const payloadCopy = { ...payload, start };
        return this.http.authenticatedPost<GetAllOperatorsResponseApi>(url || (Endpoints.API_URL + Endpoints.OPERATORS._OPERATORS + Endpoints.OPERATORS._ALL), { operatorFilter: payloadCopy }, {}, 'global.request.loading_operators').pipe(
            map(res => {
                let existingOperators: iGoCompanyOperatorView[] = [];
                if (start !== 0 && this.operators?.operators) {
                    existingOperators = ObjectUtilities.deepCopy(this.operators?.operators);
                };
                const operatorsResultView = new GetAllOperatorsResponseView(res as GetAllOperatorsResponseView);
                this.operators = ObjectUtilities.deepCopy(operatorsResultView);
                this.operators.operators = existingOperators.concat(this.operators.operators);

                return operatorsResultView;
            })
        );
    };

    public getAllOperatorsCountObservable(payload: GetOperatorsPayload, start: number = 0): Observable<number> {
        const payloadCopy = { ...payload, start };
        return this.http.authenticatedPost<{ count: number; }>(Endpoints.API_URL + Endpoints.OPERATORS._OPERATORS + Endpoints.OPERATORS._COUNT, { operatorFilter: payloadCopy }, {}, 'global.request.loading_operators').pipe(
            map(res => res.count)
        );
    };

    public getCompanyOriginatorsObservable(url: string): Observable<GetAllCompanyOriginatorsResponseView> {
        return this.http.authenticatedGet<GetAllCompanyOriginatorsResponseApi>(url, {}, 'global.request.loading_originators').pipe(
            map(res => new GetAllCompanyOriginatorsResponseView(res as GetAllCompanyOriginatorsResponseView))
        );
    };

    public getDiscoverOperatorsContactDetailsObservable(url: string): Observable<DiscoverOperatorContactDetailsApi> {
        return this.http.authenticatedGet<DiscoverOperatorContactDetailsApi>(url, {}, 'global.request.loading_network').pipe(
            map(res => new DiscoverOperatorContactDetailsApi(res as DiscoverOperatorContactDetailsApi))
        );
    };

    public getCompanyOperatorsObservable(url: string): Observable<GetAllCompanyOperatorsResponseView> {
        return this.http.authenticatedGet<GetAllCompanyOperatorsResponseApi>(url, {}, 'global.request.loading_operators').pipe(
            map(res => new GetAllCompanyOperatorsResponseView(res as GetAllCompanyOperatorsResponseView))
        );
    };

    public getConnectedOperatorsObservable(): Observable<GetAllOperatorsResponseView> {
        return this.http.authenticatedGet<GetAllOperatorsResponseApi>(Endpoints.API_URL + Endpoints.OPERATORS._OPERATORS + Endpoints.OPERATORS._CONNECTED, {}, 'global.request.loading_connected_operators').pipe(
            map(res => new GetAllOperatorsResponseView(res as GetAllOperatorsResponseView))
        );
    };

    public getConnectedOriginatorsObservable(): Observable<GetAllCompanyOriginatorsResponseView> {
        return this.http.authenticatedGet<GetAllCompanyOriginatorsResponseApi>(Endpoints.API_URL + Endpoints.ORIGINATORS._ORIGINATORS + Endpoints.ORIGINATORS._CONNECTED, {}, 'global.request.loading_connected_originators').pipe(
            map(res => new GetAllCompanyOriginatorsResponseView(res as GetAllCompanyOriginatorsResponseView))
        );
    };

    public getCurrentOriginatorObservable(url: string): Observable<iGoCompanyOriginatorView> {
        return this.http.authenticatedGet<iGoCompanyOriginatorApi>(url, {}, 'global.request.loading_originator').pipe(
            map(res => {
                const originatorView = this.mapIGoOriginatorToViewModel(res);
                this.cacheCurrentOriginator(originatorView);
                return originatorView;
            })
        );
    };

    public getCurrentOperatorObservable(url: string): Observable<iGoCompanyOperatorView> {
        return this.http.authenticatedGet<iGoCompanyOperatorApi>(url, {}, 'global.request.loading_operator').pipe(
            map(res => {
                const operatorView = this.mapIGoOperatorToViewModel(res);
                this.cacheCurrentOperator(operatorView);
                return operatorView;
            })
        );
    };

    public getEnabledAuditsAsync(url: string): Observable<EnabledAuditView[]> {
        return this.http.authenticatedGet<EnabledAuditsListApi>(url, {}, 'global.request.loading_change_history').pipe(
            map(res => new EnabledAuditsListView(res as EnabledAuditsListView).audits)
        );
    };

    public getIGoCompaniesObservable(url: string): Observable<void> {
        return this.http.authenticatedGet<GetAllIGoCompaniesResponseApi>(url, {}, 'global.request.loading_companies').pipe(
            map(res => {
                const companiesView = res.companies.map(this.mapIGoCompanyToViewModel);
                this.companies = new GetAllIGoCompaniesResponseView({ companies: ArrayUtilities.sortAlphabetically(companiesView, 'name'), _links: res._links });
            })
        );
    };

    public getGlobalOriginatorObservable(): Observable<iGoCompanyOriginatorView> {
        return this.http.authenticatedGet<GetAllOriginatorsResponseApi>(Endpoints.API_URL + Endpoints.ORIGINATORS._ORIGINATORS, {}, 'global.request.loading_igo').pipe(
            mergeMap(res => res.originators),
            filter(originator => originator.global),
            map(globalOriginator => this.mapIGoOriginatorToViewModel(globalOriginator))
        );
    };

    public getOperatorByIdAsOriginatorObservable(operatorId: string): Observable<iGoCompanyOperatorView> {
        return this.http.authenticatedGet<iGoCompanyOperatorApi>(Endpoints.API_URL + Endpoints.OPERATORS._OPERATORS + `/${operatorId}` + Endpoints.OPERATORS._AS_ORIGINATOR, {}, 'global.request.loading_operator').pipe(
            map(this.mapIGoOperatorToViewModel)
        );
    };

    public getOperatorByIdAsIGoObservable(operatorId: string): Observable<iGoCompanyOperatorView> {
        return this.http.authenticatedGet<iGoCompanyOperatorApi>(Endpoints.API_URL + Endpoints.OPERATORS._OPERATORS + `/${operatorId}` + Endpoints.OPERATORS._AS_IGO, {}, 'global.request.loading_operator').pipe(
            map(this.mapIGoOperatorToViewModel)
        );
    };

    public getOperatorCoverageAreaObservable(url: string): Observable<iGoCompanyOperatorCoverageAreaView> {
        return this.http.authenticatedGet<iGoCompanyOperatorCoverageAreaApi>(url, {}, 'global.request.loading_coverage_area').pipe(
            map(res => new iGoCompanyOperatorCoverageAreaView(res as iGoCompanyOperatorCoverageAreaView))
        );
    };

    public getOperatorProfileContactObservable(operatorId: string): Observable<OperatorProfileContactInfoApi> {
        const url: string = Endpoints.API_URL + Endpoints.OPERATORS._CX_OPERATOR + `/${operatorId}` + Endpoints.OPERATORS._CONTACT_DETAILS;
        return this.http.authenticatedGet<OperatorProfileContactInfoApi>(url, {}, 'global.request.loading_operator_profile_contact').pipe(
            map(res => new OperatorProfileContactInfoApi(res as OperatorProfileContactInfoApi))
        );
    };

    public getOperatorPaymentModesAsync(url: string): Observable<LookupType[]> {
        return this.http.authenticatedGet<string[]>(url, {}, 'global.request.loading_payment_modes').pipe(
            map(res => res.map(i => new LookupType({
                type: i, description: i.match(/[A-Z][a-z]+/g).join(' ')
            })))
        );
    };

    public getOperatorVatModesAsync(url: string): Observable<LookupType[]> {
        return this.http.authenticatedGet<string[]>(url, {}, 'global.request.loading_vat_modes').pipe(
            map(res => res.map(i => new LookupType({
                type: i, description: i.match(/[A-Z][a-z]+/g).join(' ')
            })))
        );
    };

    public getOperatorsObservable(): Observable<void> {
        return this.http.authenticatedGet<GetAllOperatorsResponseApi>(Endpoints.API_URL + Endpoints.OPERATORS._OPERATORS, {}, 'global.request.loading_operators').pipe(
            map(res => {
                this.operators = new GetAllOperatorsResponseView({ operators: ArrayUtilities.sortAlphabetically(res.operators, 'name'), _links: res._links });
            })
        );
    };

    public getOperatorsKycStatusAuditsObservable(url: string): Observable<StatusAuditView[]> {
        return this.http.authenticatedGet<StatusAuditView[]>(url, {}, 'global.request.loading_change_history').pipe(
            map(res => res.map((item, index) => new StatusAuditView(item, index + 1)))
        );
    };

    public getOriginatorByIdAsIGoObservable(originatorId: string): Observable<iGoCompanyOriginatorView> {
        return this.http.authenticatedGet<iGoCompanyOriginatorApi>(Endpoints.API_URL + Endpoints.ORIGINATORS._ORIGINATORS + `/${originatorId}` + Endpoints.ORIGINATORS._AS_IGO, {}, 'global.request.loading_originator').pipe(
            map(this.mapIGoOriginatorToViewModel)
        );
    };

    public getOriginatorByIdAsOperatorObservable(originatorId: string): Observable<iGoCompanyOriginatorView> {
        return this.http.authenticatedGet<iGoCompanyOriginatorApi>(Endpoints.API_URL + Endpoints.ORIGINATORS._ORIGINATORS + `/${originatorId}` + Endpoints.ORIGINATORS._AS_OPERATOR, {}, 'global.request.loading_originator').pipe(
            map(this.mapIGoOriginatorToViewModel)
        );
    };

    public getOriginatorEventAuthorizationModesAsync(url: string): Observable<LookupType[]> {
        return this.http.authenticatedGet<string[]>(url, {}, 'global.request.loading_event_authorization_modes').pipe(
            map(res => res.map(i => new LookupType({ type: i, description: i })))
        );
    };

    public getOriginatorsObservable(): Observable<void> {
        return this.http.authenticatedGet<GetAllOriginatorsResponseApi>(Endpoints.API_URL + Endpoints.ORIGINATORS._ORIGINATORS, {}, 'global.request.loading_originators').pipe(
            map(res => {
                this.originators = new GetAllOriginatorsResponseView({ originators: ArrayUtilities.sortAlphabetically(res.originators, 'name'), _links: res._links });
            })
        );
    };

    public getUnlinkedAgentsAsync(url: string): Observable<CXAgentView[]> {
        return this.http.authenticatedGet<{ agents: CXAgentView[]; }>(url, {}, 'global.request.loading_cab_exchange_agents').pipe(
            map(res => res.agents.map(i => new CXAgentView(i)))
        );
    };

    public getUnlinkedVendorsAsync(url: string): Observable<CXVendorView[]> {
        return this.http.authenticatedGet<{ vendors: CXVendorView[]; }>(url, {}, 'global.request.loading_cab_exchange_vendors').pipe(
            map(res => res.vendors.map(i => new CXVendorView(i)))
        );
    };

    public loadSelectedOperatorOrOriginator(item: iGoCompanyOperatorView | iGoCompanyOperatorPartialView | iGoCompanyOriginatorView | iGoCompanyOriginatorPartialView): string {
        try {
            let route: string = '';
            if (item instanceof iGoCompanyOperatorView || item instanceof iGoCompanyOperatorPartialView) {
                route = this.portalsService.portals.find(i => i.class == 'operator')?.route;
                route && this.cacheCurrentOriginator(null);
                route && this.cacheCurrentOperator(item);
                if (!route) { throw new Error(ErrorUtilities.operators.unableToLoadOperator); };
            } else {
                route = this.portalsService.portals.find(i => i.class == 'originator')?.route;
                route && this.cacheCurrentOperator(null);
                route && this.cacheCurrentOriginator(item);
                if (!route) { throw new Error(ErrorUtilities.originators.unableToLoadOriginator); };
            };
            return route;
        } catch (error) {
            throw error;
        };
    };

    public linkIGoOperatorAsync(url: string, operator: iGoCompanyOperatorView): Observable<iGoCompanyOperatorView> {
        return this.http.authenticatedPost<iGoCompanyOperatorApi>(url, new LinkIGoOperatorPayload(operator), {}, 'global.request.linking_operator').pipe(
            tap(res => {
                res && GainsightService.trackEvent('CreateOperator');
            }),
            map(this.mapIGoOperatorToViewModel)
        );
    };

    public linkIGoOriginatorAsync(url: string, originator: iGoCompanyOriginatorView): Observable<iGoCompanyOriginatorView> {
        return this.http.authenticatedPost<iGoCompanyOriginatorApi>(url, new LinkIGoOriginatorPayload(originator), {}, 'global.request.linking_originator').pipe(
            tap(res => {
                res && GainsightService.trackEvent('CreateOriginator');
            }),
            map(this.mapIGoOriginatorToViewModel)
        );
    };

    public returnUserAccessAsync(): Observable<AuthenticationResponse> {
        return this.http.authenticatedPost<AuthenticationResponse>(Endpoints.API_URL + Endpoints.COMPANIES._COMPANIES + Endpoints.COMPANIES._RETURN_USER_ACCESS, {}, {}, 'global.request.loading_igo').pipe(
            map(res => {
                //Set the new session in the SessionService.
                SessionService.session = new iGoSession({
                    apiUrl: Endpoints.API_URL,
                    token: res.access_token,
                    tokenExpiry: res.expiry_in,
                    tokenType: res.token_type,
                });
                //Set the new session in local storage.
                StorageService.setItem(this.authenticationService.sessionStorageKey, SessionService.session);
                return res;
            })
        );
    };

    public toggleIGoOperatorEnabledAsync(url: string, enabled: boolean): Observable<EnabledAuditView[]> {
        return this.http.authenticatedPut<EnabledAuditsListApi>(url, { enabled }, {}, 'global.request.updating_operator').pipe(
            map(res => new EnabledAuditsListView(res as EnabledAuditsListView).audits)
        );
    };

    public toggleIGoOriginatorEnabledAsync(url: string, enabled: boolean): Observable<EnabledAuditView[]> {
        return this.http.authenticatedPut<EnabledAuditsListApi>(url, { enabled }, {}, 'global.request.updating_operator').pipe(
            map(res => new EnabledAuditsListView(res as EnabledAuditsListView).audits)
        );
    };

    public setOperatorMarketplaceModeOffAsync(url: string): Observable<iGoCompanyOperatorView> {
        return this.http.authenticatedPost<iGoCompanyOperatorApi>(url, {}, {}, 'global.request.turning_off_operator').pipe(
            map(this.mapIGoOperatorToViewModel)
        );
    };

    public setOperatorMarketplaceModeOnAsync(url: string): Observable<iGoCompanyOperatorView> {
        return this.http.authenticatedPost<iGoCompanyOperatorApi>(url, {}, {}, 'global.request.putting_operator_live').pipe(
            map(this.mapIGoOperatorToViewModel)
        );
    };

    public setOperatorTestModeAsync(url: string): Observable<iGoCompanyOperatorView> {
        return this.http.authenticatedPost<iGoCompanyOperatorApi>(url, {}, {}, 'global.request.starting_live_test').pipe(
            map(this.mapIGoOperatorToViewModel)
        );
    };

    public updateIGoCompanyAsync(company: iGoCompanyView): Observable<iGoCompanyView> {
        return this.http.authenticatedPut<iGoCompanyApi>(company._links.UpdateCompany.href, new iGoCompanyApi(company), {}, 'global.request.updating_company').pipe(
            map(this.mapIGoCompanyToViewModel)
        );
    };

    public updateIGoOperatorAsync(url: string, operator: iGoCompanyOperatorView): Observable<iGoCompanyOperatorView> {
        return this.http.authenticatedPut<iGoCompanyOperatorApi>(url, new UpdateIGoOperatorPayload(operator), {}, 'global.request.updating_operator').pipe(
            map(this.mapIGoOperatorToViewModel)
        );
    };

    public updateIGoOperatorCoverageAsync(operator: iGoCompanyOperatorView, coverageArea: iGoCompanyOperatorCoverageAreaView): Observable<iGoCompanyOperatorView> {
        return this.http.authenticatedPut<iGoCompanyOperatorApi>(operator._links.UpdateOperatorCoverage.href, new iGoCompanyOperatorCoverageAreaApi(coverageArea), {}, 'global.request.updating_operator').pipe(
            map(this.mapIGoOperatorToViewModel)
        );
    };

    public updateOperatorKycStatusAsync(url: string, kycStatus: boolean): Observable<iGoCompanyOperatorView> {
        return this.http.authenticatedPut<iGoCompanyOperatorApi>(url, { kycStatus }, {}, 'global.request_updating_kyc_status').pipe(
            map(this.mapIGoOperatorToViewModel)
        );
    };

    public updateOperatorProfileContactAsync(company: OperatorProfileContactInfoApi): Observable<OperatorProfileContactInfoApi> {
        const url: string = Endpoints.API_URL + Endpoints.OPERATORS._CX_OPERATOR + Endpoints.OPERATORS._CONTACT_DETAILS;
        return this.http.authenticatedPut<OperatorProfileContactInfoApi>(url, new OperatorProfileContactInfoApi(company), {}, 'global.request.updating_operator').pipe(
            map(this.mapOperatorProfileContactToViewModel)
        );
    };

    public updateIGoOriginatorAsync(url: string, originator: iGoCompanyOriginatorView): Observable<iGoCompanyOriginatorView> {
        return this.http.authenticatedPut<iGoCompanyOriginatorApi>(url, new iGoCompanyOriginatorApi(originator), {}, 'global.request.updating_originator').pipe(
            map(this.mapIGoOriginatorToViewModel)
        );
    };

    public updateIGoOriginatorAsIGoAsync(originator: iGoCompanyOriginatorView): Observable<iGoCompanyOriginatorView> {
        return this.http.authenticatedPut<iGoCompanyOriginatorApi>(originator._links.UpdateOriginatorAsIGo.href, new UpdateIGoOriginatorAsIGoPayload(originator), {}, 'global.request.updating_originator').pipe(
            map(this.mapIGoOriginatorToViewModel)
        );
    };

    public updateLogoAsync(logoFile: File, isOriginator: boolean): Observable<string> {
        let formData = new FormData();
        formData.append("file", logoFile);
        let url: string = Endpoints.API_URL;
        if (isOriginator) {
            url += Endpoints.ORIGINATORS._ORIGINATORS + Endpoints.ORIGINATORS._LOGO;
        } else {
            url += Endpoints.OPERATORS._OPERATORS + Endpoints.OPERATORS._LOGO;
        };
        return this.http.authenticatedPut<any>(url, formData, {}, 'global.request.uploading_logo').pipe(
            map(res => res.logo)
        );
    };

    private mapIGoCompanyToViewModel = (company: iGoCompanyApi): iGoCompanyView => {
        return new iGoCompanyView(company as iGoCompanyView);
    };

    private mapIGoOperatorToViewModel = (operator: iGoCompanyOperatorApi): iGoCompanyOperatorView => {
        return new iGoCompanyOperatorView(operator as iGoCompanyOperatorView);
    };

    private mapOperatorProfileContactToViewModel = (operatorProfileContact: OperatorProfileContactInfoApi): OperatorProfileContactInfoApi => {
        return new OperatorProfileContactInfoApi(operatorProfileContact as OperatorProfileContactInfoApi);
    };

    private mapIGoOriginatorToViewModel = (originator: iGoCompanyOriginatorApi): iGoCompanyOriginatorView => {
        return new iGoCompanyOriginatorView(originator as iGoCompanyOriginatorView);
    };

};
