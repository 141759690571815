import { DeleteRowCellTplComponent } from 'src/shared/table-cell-templates/delete-row-cell-template.component';
import { iGoUserView } from '../../../models';
import { TableCustomisation } from '@autocab/ghost-vs-table';
import { UserService } from '../../../services';
import { UserStatusCellTplComponent } from 'src/shared/table-cell-templates/user-status-cell-template.component';

export const AdminUsersTableCustomisation: TableCustomisation<iGoUserView> = {
    customisationKey: 'AdminUsersTableCustomisation',
    tableName: 'global.tables.igo_portal.admin.users.table_title',
    defaultOrderBy: ['+name'],
    defaultColumns: {
        delete: {
            width: 3,
            name: ' ',
            prop: 'delete',
            show: true,
            template: DeleteRowCellTplComponent,
            templateClickEmitter: true
        },
        name: {
            width: 25,
            name: 'igo_portal.admin.users.table.name',
            orderBy: 'name',
            prop: 'name',
            show: true
        },
        email: {
            width: 25,
            name: 'igo_portal.admin.users.table.email',
            orderBy: 'email',
            prop: 'email',
            show: true
        },
        roles: {
            width: 25,
            name: 'igo_portal.admin.users.table.roles',
            orderBy: 'roles',
            prop: 'rolesString',
            show: true
        },
        companyName: {
            width: 32,
            name: 'igo_portal.admin.users.table.company_name',
            orderBy: 'companyName',
            prop: 'companyName',
            show: false
        },
        registered: {
            width: 22,
            name: 'igo_portal.admin.users.table.registered',
            orderBy: 'registered',
            prop: 'registered',
            template: UserStatusCellTplComponent,
            show: true
        },

    }
};

UserService.registerCustomisation(AdminUsersTableCustomisation);