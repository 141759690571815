export class DateUtilities {
    public static formatDateToShortDate(date: Date): string {
        const day = this.getTwoDigitDay(date);
        const month = this.getTwoDigitMonth(date);
        const year = date.getFullYear();

        return year + '-' + month + '-' + day;
    };

    public static async getDateRange(type: string): Promise<{ from: Date, to: Date; }> {
        const today = new Date();
        let fromDate;
        let dateRange = {
            from: new Date(),
            to: new Date()
        };
        switch (type) {
            case "thisWeek":
                dateRange.from = new Date(today.setDate(today.getDate() - today.getDay() + 1));
                break;
            case "previousWeek":
                let thisWeekFirstDate = new Date(today.setDate(today.getDate() - today.getDay() + 1));
                const previousWeekFirstDate = new Date(thisWeekFirstDate.setDate(thisWeekFirstDate.getDate() - 7));
                dateRange.from = previousWeekFirstDate;
                const previousWeekLastDate = new Date(thisWeekFirstDate.setDate(thisWeekFirstDate.getDate() + 6));
                dateRange.to = previousWeekLastDate;
                break;
            case "last7Days":
                dateRange.from = new Date(today.getTime() - 6 * 24 * 60 * 60 * 1000);
                break;
            case "thisMonth":
                dateRange.from = new Date(today.getFullYear(), today.getMonth(), 1);
                break;
            case "previousMonth":
                dateRange.from = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                let lastDayOfLastMonth = new Date();
                lastDayOfLastMonth.setMonth(lastDayOfLastMonth.getMonth(), 0);
                dateRange.to = lastDayOfLastMonth;
                break;
            case "last30Days":
                dateRange.from = new Date(today.getTime() - 29 * 24 * 60 * 60 * 1000);
                break;
            case "last3Months":
                fromDate = new Date(today.getFullYear(), today.getMonth() - 3);
                dateRange.from = new Date(fromDate.getFullYear(), fromDate.getMonth(), today.getDate() + 1);
                break;
            case "last12Months":
                fromDate = new Date(today.getFullYear(), today.getMonth() - 12);
                dateRange.from = new Date(fromDate.getFullYear(), fromDate.getMonth(), today.getDate() + 1);
                break;
        };
        return dateRange;
    };

    public static async getDateRangeBefore6Months(type: string, dateBefore6Months: Date): Promise<{ from: Date, to: Date; }> {
        let dateRange = {
            from: dateBefore6Months,
            to: dateBefore6Months
        };
        switch (type) {
            case "1month":
                dateRange.from = new Date(dateBefore6Months.getFullYear(), dateBefore6Months.getMonth() - 1, dateBefore6Months.getDate());
                break;
            case "2month":
                dateRange.from = new Date(dateBefore6Months.getFullYear(), dateBefore6Months.getMonth() - 2, dateBefore6Months.getDate());
                break;
            case "3month":
                dateRange.from = new Date(dateBefore6Months.getFullYear(), dateBefore6Months.getMonth() - 3, dateBefore6Months.getDate());
                break;
            case "6month":
                dateRange.from = new Date(dateBefore6Months.getFullYear(), dateBefore6Months.getMonth() - 6, dateBefore6Months.getDate());
                break;
        };
        return dateRange;
    };

    public static getEndOfDay(date?: Date): Date {
        let endOfDay = date || new Date();
        endOfDay.setHours(23, 59, 59, 999);
        return endOfDay;
    };

    public static getEndOfDayUTC(date?: Date): Date {
        const endOfDay = date || new Date();
        return new Date(Date.UTC(endOfDay.getFullYear(), endOfDay.getMonth(), endOfDay.getDate(), 23, 59, 59, 999));
    };

    public static getMidnight(date?: Date): Date {
        let midnight = date || new Date();
        midnight.setHours(0, 0, 0, 0);
        return midnight;
    };

    public static getMidnightUTC(date?: Date): Date {
        const midnight = date || new Date();
        return new Date(Date.UTC(midnight.getFullYear(), midnight.getMonth(), midnight.getDate(), 0, 0, 0, 0));
    };

    public static getNumberOfDaysBetweenDates(date1: Date, date2: Date): number {
        const timeDiff = Math.abs(date2.getTime() - date1.getTime());
        return Math.ceil(timeDiff / (1000 * 3600 * 24));
    };

    public static getNumberOfMinutesBetweenTwoDates(date1: Date, date2: Date): number {
        const timeDiff = date2.getTime() - date1.getTime();
        return Math.round(timeDiff / 60000);
    };

    public static getTwoDigitDay(date: Date): string {
        return (date.getDate() < 10 ? '0' : '') + date.getDate();
    };

    public static getTwoDigitMonth(date: Date): string {
        const month = date.getMonth() + 1;
        return (month < 10 ? '0' : '') + month;
    };

    public static setTimeOfDate(date: Date, time: string): void {
        const timeCopy = time ? time : '00:00:00';
        const [hours, mins, secs] = timeCopy.split(':').map(item => parseInt(item));
        if (hours && mins && secs) {
            date.setHours(hours, mins, secs);
        };
    };

    public static getIsoDate(date: Date) {
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    };

    public static getReadableDateTimeFromISOString(date: string, displayYear: boolean) {
        if (date.length > 20 && date.length < 25) date += ':00';
        const d = new Date(date);
        return `${String(d.getDate()).padStart(2, '0')}/${String(d.getMonth() + 1).padStart(2, '0')}${displayYear ? '/' + d.getFullYear() : ''} ${date.substring(11, 16)}`;
    }

    public static toISOLocal(d: Date) {
        const z = n => ('0' + n).slice(-2);
        let off = d.getTimezoneOffset();
        const sign = off < 0 ? '+' : '-';
        off = Math.abs(off);

        return d.getFullYear() + '-'
            + z(d.getMonth() + 1) + '-' +
            z(d.getDate()) + 'T' +
            z(d.getHours()) + ':' +
            z(d.getMinutes()) + ':' +
            z(d.getSeconds()) +
            sign + z(off / 60 | 0) + ':' + z(off % 60);
    }

};