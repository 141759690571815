import { Injectable } from "@angular/core";
import { Endpoints } from "src/shared/endpoints";
import { catchError, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { PermissionCategoryView, PermissionGroupView, PermissionItemView } from "src/models";
import { RequestService } from "./request.service";
import { UserService } from "./user.service";

@Injectable({ providedIn: 'root' })
export class PermissionsService {
    public individualPermissions: PermissionItemView[] = [];
    public permissions: PermissionCategoryView[] = [];

    public constructor(
        private http: RequestService,
        private userService: UserService
    ) { };

    public getIndividualPermissions = (): PermissionItemView[] => this.individualPermissions;
    public getPermissions = (): PermissionCategoryView[] => this.permissions;

    public getPermissionsObservable(): Observable<void> {
        return this.http.authenticatedGet<{ categories: PermissionCategoryView[] }>(Endpoints.API_URL + Endpoints.PERMISSIONS, {}, 'global.request.loading_permissions').pipe(
            map(res => {
                this.permissions = this.mapPermissionsToViewModel(res.categories);
                this.individualPermissions = this.listAllIndividualPermissions(this.permissions);
            }),
            catchError((err) => {
                this.permissions = null;
                this.individualPermissions = null;
                return throwError(err);
            })
        );
    };

    public listAllIndividualPermissions(permissionCategories: PermissionCategoryView[]): PermissionItemView[] {
        let allGroups: PermissionGroupView[] = permissionCategories.map(p => p.groups).reduce((list, items) => list.concat(items), []);
        return allGroups.map(p => p.permissions).reduce((list, items) => list.concat(items), []);
    };

    public mapPermissionsToViewModel(categories: PermissionCategoryView[]): PermissionCategoryView[] {
        return categories.map(i => new PermissionCategoryView(i));
    };
};