import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'boolean-cell',
    template: `<i [attr.class]="iconClass"></i>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [`
        :host { display: flex; flex-flow: column; height: 100%; justify-content: center; padding: 0 20px; width: 100%; }
        :host:not(.gvs-action-cell) { opacity: .5; }
    `]
})
export class BooleanCellTplComponent {
    @Input() public set row(item: any) {
        if (item) {
            this.iconClass = `fal ${item[this.prop] ? 'text-success fa-check-circle' : 'text-danger fa-times-circle'} fa-2x`;
            this.cd.detectChanges();
        };
    };
    public iconClass: string = '';
    public prop: string = '';
    public constructor(private cd: ChangeDetectorRef) { };
};