// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body {
    display: flex;
    flex-direction: column;
}

.table-wrapper {
    height: 100%;
}

ghost-vs-table {
    position: relative;
}`, "",{"version":3,"sources":["webpack://./src/modules/shared-modals/modals/edit-cx-marketplace/edit-cx-marketplace.modal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".modal-body {\n    display: flex;\n    flex-direction: column;\n}\n\n.table-wrapper {\n    height: 100%;\n}\n\nghost-vs-table {\n    position: relative;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
