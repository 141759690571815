// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
    align-items: center;
    background: url('hero.8e144c7a42a07563.svg') no-repeat center;
    display: flex;
    justify-content: center;
}

.container {
    text-align: center;
}

.container h1 {
    font-family: ZonaProLight, Arial, Helvetica, sans-serif !important;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/standalone/no-access/no-access.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,6DAAiE;IACjE,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kEAAkE;IAClE,SAAS;AACb","sourcesContent":[":host {\n    align-items: center;\n    background: url(src/shared/assets/svgs/hero.svg) no-repeat center;\n    display: flex;\n    justify-content: center;\n}\n\n.container {\n    text-align: center;\n}\n\n.container h1 {\n    font-family: ZonaProLight, Arial, Helvetica, sans-serif !important;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
