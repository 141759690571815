import { AddToRowCellTplComponent } from 'src/shared/table-cell-templates/add-to-row-cell-template.component';
import { CXMarketplaceView } from '../../../models';
import { TableCustomisation } from '@autocab/ghost-vs-table';
import { UserService } from '../../../services';

export const CXMarketplacesTableCustomisation: TableCustomisation<CXMarketplaceView> = {
    customisationKey: 'CXMarketplacesTableCustomisation',
    tableName: 'global.tables.igo_portal.marketplaces.table_title',
    defaultOrderBy: ['+name'],
    defaultColumns: {
        name: {
            width: 97,
            name: 'igo_portal.marketplaces.table.name',
            orderBy: 'name',
            prop: 'name',
            show: true
        },
        plus: {
            width: 3,
            name: ' ',
            prop: 'plus',
            show: true,
            template: AddToRowCellTplComponent,
            templateClickEmitter: true
        },
        defaultCommision: {
            width: 20,
            name: 'igo_portal.marketplaces.table.default_commision',
            orderBy: 'agentCommissionRules.defaultCommission',
            prop: 'agentCommissionRules.defaultCommission',
            show: false
        },
        defaultCommisionVAT: {
            width: 20,
            name: 'igo_portal.marketplaces.table.default_commision_vat',
            orderBy: 'agentCommissionRules.defaultCommissionVAT',
            prop: 'agentCommissionRules.defaultCommissionVAT',
            show: false
        },
        defaultMinimumAmount: {
            width: 20,
            name: 'igo_portal.marketplaces.table.default_minimum_amount',
            orderBy: 'agentCommissionRules.defaultMinimumAmountCurrency',
            prop: 'agentCommissionRules.defaultMinimumAmountCurrency',
            show: false
        },
    }
};

UserService.registerCustomisation(CXMarketplacesTableCustomisation);