export class NumberUtilities {
    public static truncate(value: number | string, precision: number): number | string {
        let [integer, mantissa] = String(value).split('.');
        if (mantissa !== undefined) {
            if (mantissa.length > precision) {
                mantissa = mantissa.substr(0, precision);
            };
            return typeof value === 'number' ? Number(`${integer}.${mantissa}`) : `${integer}.${mantissa}`;
        };
        return typeof value === 'number' ? Number(integer) : integer;
    };

    public static numberToMinutesAndSeconds(value: number): { minutes: number, seconds: number } {
        const totalSeconds: number = Math.abs(value);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.floor(totalSeconds - minutes * 60);
        
        if (value < 0) {
            return { minutes: minutes > 0 ? -minutes: 0, seconds: seconds > 0 ? -seconds: 0 };
        };
        return { minutes, seconds };
    };
};