import { Injectable } from '@angular/core';
import { getLocaleCurrencyCode, getLocaleCurrencySymbol } from '@angular/common';
import { StorageService } from './storage.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SettingsService {
    public static LanguageCode: string = 'en-GB';

    public getLanguageCode = (): string => SettingsService.LanguageCode || 'en-GB';
    public static getLanguageCode = (): string => StorageService.getItem('languageCode') || 'en-GB';

    public onSetLanguageCode$: BehaviorSubject<string> = new BehaviorSubject(SettingsService.getLanguageCode());

    public constructor() { };

    public static getCurrencyCode(): string {
        const languageCode: string = StorageService.getItem('languageCode') || SettingsService.LanguageCode;
        const locale: string = languageCode.replace('_', '-');
        return getLocaleCurrencyCode(locale);
    };

    public static getCurrencySymbol(): string {
        const languageCode: string = StorageService.getItem('languageCode') || SettingsService.LanguageCode;
        const locale: string = languageCode.replace('_', '-');
        return getLocaleCurrencySymbol(locale);
    };

    public setLanguageCode = (locale: string) => {
        StorageService.setItem('languageCode', locale);
        this.onSetLanguageCode$.next(locale);
    };
};
