import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChartsService } from '../../../services';
import { IBarChartConfig, IChartColorScheme } from '../../../interfaces/charts.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'ghost-bar-chart',
    styleUrls: [
        'ghost-bar-chart.component.css'
    ],
    templateUrl: './ghost-bar-chart.component.html'
})

export class GhostBarChartComponent implements OnInit {
    @Input() public set config(value: IBarChartConfig) {
        this._config = value;
        if (value) {
            if (value.data && value.data.length) {
                for (let i = 0; i < value.data.length; i++) {
                    this._config.data[i].name = this.translateService.instant(this._config.data[i].name);
                };
            };
            this.xAxisLabel = this._config && this._config.labels && this._config.labels[0] && this._config.labels[0].xAxisLabel ? this.translateService.instant(this._config.labels[0].xAxisLabel) : '';
            this.yAxisLabel = this._config && this._config.labels && this._config.labels[0] && this._config.labels[0].yAxisLabel ? this.translateService.instant(this._config.labels[0].yAxisLabel) : '';
        };
    };
    @Input() public customColors: { name: string, value: string }[];
    @Output() selectChart: EventEmitter<any> = new EventEmitter<any>();

    public singleColourScheme: IChartColorScheme;
    public xAxisLabel: string;
    public yAxisLabel: string;
    private _config: IBarChartConfig = null;

    public constructor(
        private chartService: ChartsService,
        private translateService: TranslateService
    ) { };

    public ngOnInit(): void {
        this.singleColourScheme = this.chartService.singleColourScheme;
    };

    public get config(): IBarChartConfig {
        return this._config;
    };

    public onSelectGraph(event): void {
        let data = this.config.data.find(i => i.extra == event.extra).data;
        event.data = data;
        this.selectChart.emit(event);
    };

    public yAxisTickFormattingFn(value: any): any {
        if (typeof value == 'number') {
            return value.toFixed(0);
        };
        return value;
    };    
};