// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.colour-bar.horizontal {
    flex-direction: row;
    height: 2px;
    width: 168px;
}

.colour-bar.vertical {
    flex-direction: column;
    height: 168px;
    width: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/standalone/igo-colour-bar/igo-colour-bar.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,UAAU;AACd","sourcesContent":[".colour-bar.horizontal {\n    flex-direction: row;\n    height: 2px;\n    width: 168px;\n}\n\n.colour-bar.vertical {\n    flex-direction: column;\n    height: 168px;\n    width: 2px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
