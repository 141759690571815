import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { getCurrencySymbol } from '@angular/common';

@Component({
    selector: 'shared-value-input',
    templateUrl: './shared-value-input.component.html',
    styleUrls: ['./shared-value-input.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SharedValueInputComponent),
            multi: true
        }
    ]
})
export class SharedValueInputComponent implements ControlValueAccessor {
    @Input() public id: string = "withdrawAmount";
    @Input() public label?: string = "";
    @Input() public name: string = "withdraw-amount";
    @Input() public set currencyCode(val: string) {
        this._currencyCode = val;
        this.currencySymbol = getCurrencySymbol(val, 'wide');
    };
    @Input() public min: number = 0;
    @Input() public max?: number;
    @Input() public required?: string;
    @Input() public readonly?: boolean;
    @Input() public labelCurrencySymbol?: string;
    @Input() public set value(val: number) {
        this._value = val;
    };
    public currencySymbol: string;

    public _currencyCode: string;
    public _value: number;

    public constructor() { }

    public get currencyCode(): string { return this._currencyCode; };
    public get value(): number { return this._value; };

    public writeValue(value: any): void {
        this.value = value;
    };

    public registerOnChange(fn: any): void {
        this.onChanged = fn;
    };

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    };

    public updateValue() {
        this.onTouched();
        this.onChanged(this.value);
    };

    private onTouched: Function = () => { };
    private onChanged: Function = () => { };
}
