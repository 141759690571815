// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        :host { align-items: center; display: flex; flex-flow: column; justify-content: center; height: 100%; width: 100%; }
        :host:not(.gvs-action-cell) { opacity: .5; }
        :host.gvs-action-cell:hover { background-color: rgb(42 42 42 / 20%) !important; transition: 0.2s ease-in-out; }
        :host.disabled { opacity: 0.5; cursor: not-allowed; }
    `, "",{"version":3,"sources":["webpack://./src/shared/table-cell-templates/delete-row-cell-template.component.ts"],"names":[],"mappings":";QACQ,QAAQ,mBAAmB,EAAE,aAAa,EAAE,iBAAiB,EAAE,uBAAuB,EAAE,YAAY,EAAE,WAAW,EAAE;QACnH,8BAA8B,WAAW,EAAE;QAC3C,8BAA8B,gDAAgD,EAAE,4BAA4B,EAAE;QAC9G,iBAAiB,YAAY,EAAE,mBAAmB,EAAE","sourcesContent":["\n        :host { align-items: center; display: flex; flex-flow: column; justify-content: center; height: 100%; width: 100%; }\n        :host:not(.gvs-action-cell) { opacity: .5; }\n        :host.gvs-action-cell:hover { background-color: rgb(42 42 42 / 20%) !important; transition: 0.2s ease-in-out; }\n        :host.disabled { opacity: 0.5; cursor: not-allowed; }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
