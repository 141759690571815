export class JourneyUtilities {
    private static MetresToMilesConversion: number = 0.0006213712;
    private static MilesToKilometersConversion: number = 1.609344;

    public static convertMetresToMiles(metres: number): number {
        return Number((metres * JourneyUtilities.MetresToMilesConversion).toFixed(2));
    };

    public static convertMilesToKilometers(miles: number): number {
        return Number((miles * JourneyUtilities.MilesToKilometersConversion).toFixed(2));
    };
};