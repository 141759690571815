import { Injectable } from '@angular/core';
import { NgbModalRef, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { SharedModalsService } from './shared-modals.service';

@Injectable({ providedIn: 'root' })
export class TabsetService {
    public changeTab: Subject<void> = new Subject<void>();

    public constructor(private sharedModalsService: SharedModalsService) { };
    
    public onNavChange(event: NgbNavChangeEvent, currentComponent: any): NgbModalRef | void {
        if (!currentComponent.noDataChange()) {
            event.preventDefault();
            return this.sharedModalsService.showUnsavedChangesModal();
        };
    };
};