// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar-container .progress {
    background-color: transparent !important;
    background-image: url('https://i.pinimg.com/originals/ad/c6/83/adc6831e04b97a857f78881dcb4a4467.jpg');
    background-size: cover;
    border-radius: 0;
    width: 100%;
    height: 100%;
}

.progress-bar-container {
    background-color: transparent;
    height: 100%;
    padding: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}

.progress-bar-container .title {
    position: absolute;
    top: 30%;
    left: 20%;
    color: rgb(var(--igo-dark-blue));
    font-size: 3rem;
    font-family: ZonaProBold;
}

.progress-bar-container .message {
    position: absolute;
    top: 40%;
    left: 20%;
    color: rgb(var(--igo-turqoise));
    font-size: 2rem;
}

.progress-bar-container .progress-bar {
    background-color: rgb(90 90 90 / 80%) !important;
    box-shadow: 4px 0px 100px 0px rgb(var(--igo-dark-grey));
}

.progress-bar-container .progress-text {
    position: absolute;
    top: 55%;
    left: 20%;
    color: white;
    font-size: 10rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/ngbprogressbar.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;IACxC,qGAAqG;IACrG,sBAAsB;IACtB,gBAAgB;IAChB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,UAAU;IACV,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,eAAe;IACf,wBAAwB;AAC5B;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,+BAA+B;IAC/B,eAAe;AACnB;;AAEA;IACI,gDAAgD;IAChD,uDAAuD;AAC3D;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".progress-bar-container .progress {\n    background-color: transparent !important;\n    background-image: url('https://i.pinimg.com/originals/ad/c6/83/adc6831e04b97a857f78881dcb4a4467.jpg');\n    background-size: cover;\n    border-radius: 0;\n    width: 100%;\n    height: 100%;\n}\n\n.progress-bar-container {\n    background-color: transparent;\n    height: 100%;\n    padding: 0;\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 100%;\n}\n\n.progress-bar-container .title {\n    position: absolute;\n    top: 30%;\n    left: 20%;\n    color: rgb(var(--igo-dark-blue));\n    font-size: 3rem;\n    font-family: ZonaProBold;\n}\n\n.progress-bar-container .message {\n    position: absolute;\n    top: 40%;\n    left: 20%;\n    color: rgb(var(--igo-turqoise));\n    font-size: 2rem;\n}\n\n.progress-bar-container .progress-bar {\n    background-color: rgb(90 90 90 / 80%) !important;\n    box-shadow: 4px 0px 100px 0px rgb(var(--igo-dark-grey));\n}\n\n.progress-bar-container .progress-text {\n    position: absolute;\n    top: 55%;\n    left: 20%;\n    color: white;\n    font-size: 10rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
