import { Component, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { environment } from 'src/environments/environment';
import { SnackbarService } from 'src/services/snackbar.service';
import { TableComponent } from '@autocab/ghost-vs-table';

@Component({
    selector: 'ghost-vs-table-dropdown',
    templateUrl: './ghost-vs-table-dropdown.component.html',
})

export class GhostVSTableDropdownComponent {
    @Input() public buttonClass: 'igo' | 'operator' | 'originator' | 'secondary' = 'secondary';
    @Input() public tableComponent: TableComponent;
    @Input() public showExportButton: boolean = true;
    public environment: any = environment;

    public constructor(
        private clipboard: Clipboard,
        private snackbarService: SnackbarService
    ) { };

    public onCopySelectedRowAsJson(): void {
        this.clipboard.copy(JSON.stringify(this.tableComponent.getSelected()));
        this.snackbarService.showSnackbar('global.modals.copied');
    };

    public onCopyTableDataAsJson(): void {
        this.clipboard.copy(JSON.stringify(this.tableComponent.rows));
        this.snackbarService.showSnackbar('global.modals.copied');
    };

    public openExportTableModal(): void {
        if (this.tableComponent) {
            this.tableComponent.showExportOverlay();
        };
    };

    public openPrintTableModal(): void {
        if (this.tableComponent) {
            this.tableComponent.showPrintOverlay();
        };
    };
};