import { Component, AfterViewInit, TemplateRef, ViewChild } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Component({
    selector: 'validation-popover',
    templateUrl: './validation-popover.component.html'
})

export class ValidationPopoverComponent implements AfterViewInit {
    @ViewChild('validationPopover') private validationPopover: TemplateRef<any>;

    public errorMessage: string;
    private template: Subject<TemplateRef<any>> = new Subject<TemplateRef<any>>();

    public ngAfterViewInit(): void {
        if (this.validationPopover) {
            this.template.next(this.validationPopover);
            this.template.complete();
        };
    };

    public getTemplateRef = (): Observable<TemplateRef<any>> => this.template.asObservable();
};