import { Injectable } from "@angular/core";
import { DateRangeTemplateApi, DateRangeTemplateView } from "src/models/date-range.model";
import { Endpoints } from "src/shared/endpoints";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { RequestService } from "./request.service";

@Injectable({ providedIn: 'root' })
export class TimeRangesService {
    public constructor(
        private http: RequestService
    ) { };

    public getDateRangeTemplateByIdObservable(dateRangeTemplateId: string): Observable<DateRangeTemplateView> {
        return this.http.authenticatedGet<DateRangeTemplateApi>(Endpoints.API_URL + Endpoints.DATE_RANGE + `/${dateRangeTemplateId}`, {}, 'global.request.loading_date_range_template').pipe(
            map(this.mapDateRangeTemplateToViewModel)
        );
    };

    private mapDateRangeTemplateToViewModel = (template: DateRangeTemplateApi): DateRangeTemplateView => {
        return new DateRangeTemplateView(template as DateRangeTemplateView);
    };
}