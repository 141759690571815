import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { SessionService } from './session.service';

@Injectable({ providedIn: 'root' })
export class RequestService {
    public constructor(
        private http: HttpClient
    ) { };

    public authenticatedDelete<T>(url: string, options?: any, loadingMessage?: string): Observable<any> {
        const requestOptions = this.buildAuthenticatedRequestOptions(options, loadingMessage);
        return this.http.delete<T>(url, requestOptions);
    };

    public authenticatedGet<T>(url: string, options?: any, loadingMessage?: string): Observable<T> {
        const requestOptions = this.buildAuthenticatedRequestOptions(options, loadingMessage);
        return this.http.get<T>(url, requestOptions);
    };

    public authenticatedPost<T>(url: string, payload: any | null, options?: any, loadingMessage?: string): Observable<T> {
        const requestOptions = this.buildAuthenticatedRequestOptions(options, loadingMessage);
        return this.http.post<T>(url, payload, requestOptions);
    };

    public authenticatedPut<T>(url: string, payload: any | null, options?: any, loadingMessage?: string): Observable<T> {
        const requestOptions = this.buildAuthenticatedRequestOptions(options, loadingMessage);
        return this.http.put<T>(url, payload, requestOptions);
    };

    public delete(url: string, options?: Object): Observable<any> {
        return this.http.delete(url, options);
    };

    public get(url: string, options?: Object): Observable<any> {
        return this.http.get(url, options);
    };

    public post(url: string, payload?: any | null, options?: Object): Observable<any> {
        return this.http.post(url, payload, options);
    };

    public put(url: string, payload?: any | null, options?: Object): Observable<any> {
        return this.http.put(url, payload, options);
    };

    private buildAuthenticatedRequestOptions(options?: any, loadingMessage?: string): { headers: any, responseType: any; } {
        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + SessionService.getToken(),
            'Accept': 'application/hal+json'
        });

        if (options && options.headers) {
            for (const key in options.headers) {
                headers = headers.append(key, options.headers[key]);
            };
        };

        if (loadingMessage) {
            headers = headers.append('LoadingMessage', loadingMessage);
        };

        return {
            headers,
            responseType: (options && options.responseType) ? options.responseType : 'json'
        };
    };
};