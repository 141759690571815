// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map {
    border-top-right-radius: 15px;
    bottom: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/ghost-map/ghost-map.component.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,SAAS;IACT,YAAY;IACZ,kBAAkB;IAClB,MAAM;IACN,WAAW;AACf","sourcesContent":[".map {\n    border-top-right-radius: 15px;\n    bottom: 0;\n    height: 100%;\n    position: absolute;\n    top: 0;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
