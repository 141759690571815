import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { RouterPreloader } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class RouterPreloaderService {
    public constructor(private routerPreloader: RouterPreloader) {
        this.routerPreloader.setUpPreloading();
    };

    public preloadAllModules = (): Observable<any> => this.routerPreloader.preload();
};