// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-tooltip-icon {
    position: absolute;
}

.tooltip.show {
    opacity: 1;
}

.tooltip-inner {
    background-color: rgba(var(--igo-dark-grey), 0.9);
    padding: 20px 30px;
    font-family: ZonaProLight, Arial, Helvetica, sans-serif;
    width: max-content;
    height: max-content;
    max-width: 300px;
}

.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
    border-top-color: rgba(var(--igo-dark-grey), 0.9);
}

.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
    border-right-color: rgba(var(--igo-dark-grey), 0.9);
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
    border-bottom-color: rgba(var(--igo-dark-grey), 0.9);
}

.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {
    border-left-color: rgba(var(--igo-dark-grey), 0.9);
}

.warning-tooltip-icon {
    color: #ff0000;
}`, "",{"version":3,"sources":["webpack://./src/styles/tooltips.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,iDAAiD;IACjD,kBAAkB;IAClB,uDAAuD;IACvD,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,iDAAiD;AACrD;;AAEA;IACI,mDAAmD;AACvD;;AAEA;IACI,oDAAoD;AACxD;;AAEA;IACI,kDAAkD;AACtD;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".button-tooltip-icon {\n    position: absolute;\n}\n\n.tooltip.show {\n    opacity: 1;\n}\n\n.tooltip-inner {\n    background-color: rgba(var(--igo-dark-grey), 0.9);\n    padding: 20px 30px;\n    font-family: ZonaProLight, Arial, Helvetica, sans-serif;\n    width: max-content;\n    height: max-content;\n    max-width: 300px;\n}\n\n.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {\n    border-top-color: rgba(var(--igo-dark-grey), 0.9);\n}\n\n.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {\n    border-right-color: rgba(var(--igo-dark-grey), 0.9);\n}\n\n.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {\n    border-bottom-color: rgba(var(--igo-dark-grey), 0.9);\n}\n\n.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {\n    border-left-color: rgba(var(--igo-dark-grey), 0.9);\n}\n\n.warning-tooltip-icon {\n    color: #ff0000;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
