import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'logo-cell',
    template: `<div class="logo-cell h-100" [ngStyle]="{'background-image': logo}"></div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [`
        :host { align-items: flex-start; display: flex; flex-flow: column; justify-content: center; height: 100%; width: 100%; }
        :host.gvs-action-cell:hover { background-color: rgba(149, 38, 84, 0.2) !important; }
        :host .logo-cell { background-position: center; background-repeat: no-repeat; background-size: contain; margin-left: 20px; position: absolute; width: 50px; }
    `]
})
export class LogoCellTplComponent {
    @Input() public set row(item: any) {
        if (item) {
            this.logo = `url(${item.logo})`;
            this.cd.detectChanges();
        };
    };
    public logo: string;
    public constructor(private cd: ChangeDetectorRef) { };
};