// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timepicker-block {
    display: flex;
    align-items: center;
}

.timepicker-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 35px;
}

.timepicker-block .timepicker-item button {
    cursor: pointer;
    font-size: 1.5rem;
    color: rgb(var(--igo-dark-grey));
    padding: 5px 10px;
}

.timepicker-block .timepicker-item input {
    padding: 0;
    text-align: center;
}

.timepicker-spacer {
    width: auto;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/ghost-timepicker/ghost-timepicker.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,6BAA6B;IAC7B,WAAW;AACf;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,gCAAgC;IAChC,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".timepicker-block {\n    display: flex;\n    align-items: center;\n}\n\n.timepicker-item {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: space-around;\n    width: 35px;\n}\n\n.timepicker-block .timepicker-item button {\n    cursor: pointer;\n    font-size: 1.5rem;\n    color: rgb(var(--igo-dark-grey));\n    padding: 5px 10px;\n}\n\n.timepicker-block .timepicker-item input {\n    padding: 0;\n    text-align: center;\n}\n\n.timepicker-spacer {\n    width: auto;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
