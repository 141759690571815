import { Injectable, ComponentFactoryResolver } from "@angular/core";
import { NgbModal, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { IConfirmationModalConfig } from "src/modules/shared-modals/modals/confirmation/confirmation.modal";
import { UnsavedChangesModalComponent } from "src/modules/shared-modals/modals/unsaved-changes/unsaved-changes.modal";

@Injectable({ providedIn: 'root' })
export class ModalService {
    public constructor(
        private modalService: NgbModal,
        private componentFactoryResolver: ComponentFactoryResolver
    ) { };

    public open(content: any, options: NgbModalOptions = {}): NgbModalRef {
        const cmpFactory = this.componentFactoryResolver.resolveComponentFactory(content);
        const modalRef = this.modalService.open(content, Object.assign({}, {
            backdrop: 'static',
            keyboard: true,
            beforeDismiss: (reason) => {
                const parentComponentInstance = modalRef.componentInstance;
                if (parentComponentInstance.beforeDismiss) {
                    return parentComponentInstance.beforeDismiss(reason);
                } else if (modalRef.componentInstance.noDataChange && !modalRef.componentInstance.noDataChange()) {
                    const modalRef = this.modalService.open(UnsavedChangesModalComponent,
                        //windowClass from the parent modal is used on the UnsavedChangesModalComponent. Only the first class provided should be used ('igo' / 'operator' / 'originator').
                        { size: 'sm', windowClass: `submodal ${options && options.windowClass && options.windowClass.split(' ')[0]}` }
                    );
                    modalRef.componentInstance.config = {
                        message: parentComponentInstance.noDataChangeMessage ? parentComponentInstance.noDataChangeMessage : 'global.modals.unsaved_changes.message',
                        title: parentComponentInstance.noDataChangeTitle ? parentComponentInstance.noDataChangeTitle : 'global.modals.unsaved_changes.title'
                    } as IConfirmationModalConfig;
                    return modalRef.result;
                } else {
                    return true;
                };
            },
        }, options));
        if (cmpFactory && (!options.backdrop || options.backdrop !== 'static')) {
            const elements = document.getElementsByTagName(cmpFactory.selector);
            if (elements.length) {
                const nativeElement: Element = document.getElementsByTagName(cmpFactory.selector)[elements.length - 1];
                const ngbModalWindowElement: Element = nativeElement.closest('ngb-modal-window');
                const downListener = (event) => !nativeElement.contains(event.target) && modalRef.dismiss();
                const removeListener = () => ngbModalWindowElement.removeEventListener('mousedown', downListener);

                ngbModalWindowElement.addEventListener('mousedown', downListener);
                modalRef.result.then(removeListener, removeListener);
            };
        };

        return modalRef;
    };
};