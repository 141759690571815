import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class PageHelpService {
    public links = {
        operatorportal: {
            demand: {
                pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5202667008925'
            },
            activity: {
                pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5202763780893'
            },
            analytics: {
                pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5204089157661'
            },
            invoicing: {
                pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5204101680029'
            },
            profile: {
                pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5204889630877'
            },
            admin: {
                pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5204900540829'
            }
        },

        originatorportal: {
            network: {
                pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5212852295197'
            },
            activity: {
                pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5215016859165'
            },
            analytics: {
                pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5215313448989'
            },
            invoicing: {
                pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5222451089181'
            },
            profile: {
                pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5222520291997'
            },
            admin: {
                pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5222963867805'
            },
        },

        igoportal: {
            network: {
                pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5232240535965'
            },
            marketplaces: {
                pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5233689223325'
            },
            companies: {
                pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5233683768093',
                originators: {
                    pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5233724584733'
                },
                operators: {
                    pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5233705076253'
                },
            },
            activity: {
                pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5233725443869'
            },
            invoicing: {
                pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5233713048221'
            },
            profile: {
                pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5233701388829'
            },
            admin: {
                pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5233737392797'
            },
        },

        analytics: {
            invoicing: {
                pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5233713048221'
            },
            profile: {
                pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5233701388829'
            },
            admin: {
                pageUrl: 'https://support.autocab.com/hc/en-gb/articles/5233737392797'
            },
        }
    };
};