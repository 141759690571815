import { Component, OnInit, OnDestroy, HostListener, ViewContainerRef } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { CookieService } from 'src/services/cookie.service';
import { CookieConsentComponent } from './standalone/cookie-consent/cookie-consent.component';
import { environment } from 'src/environments/environment';
import { filter, takeUntil } from 'rxjs/operators';
import { GhostTranslationService, LoadingService, StorageService, ToastService } from '../services';
import { Keys } from 'src/enums/keys.enum';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs/internal/Subscription';
import { Subject } from 'rxjs';
import { ToastStylesEnum } from 'src/enums/toast-styles.enum';
import { TranslateService } from '@ngx-translate/core';

declare const zE;

const BASE_ROUTES = {
    igo: '/igoportal/network',
    operator: '/operatorportal/activity',
    originator: '/originatorportal/network',
};

@Component({
    selector: 'app',
    styleUrls: ['app.component.css'],
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit, OnDestroy {
    @HostListener('window:keyup', ['$event']) onKeyup(event: KeyboardEvent) {
        if (event.ctrlKey && event.keyCode == Keys.I) {
            const appVersionContainer = this.bottomSheet.open(AppVersion, { scrollStrategy: new NoopScrollStrategy() });
            setTimeout(() => {
                appVersionContainer.dismiss();
            }, 3000);
        };
    };
    public loadingText = null;
    public location: string;
    public selectedPortalName: string;
    public showPageLoadingBar: boolean = false;

    private routerSubscription: Subscription;
    private loadingSubscription: Subscription;
    private destroy$ = new Subject<void>();

    public offlineEvent = addEventListener('offline', () => this.toastService.setToastMessage(this.translateService.instant('global.toast.disconnected'), ToastStylesEnum.DANGER, 5000));
    public onlineEvent = addEventListener('online', () => this.toastService.setToastMessage(this.translateService.instant('global.toast.connected'), ToastStylesEnum.SUCCESS, 5000));

    public constructor(
        private bottomSheet: MatBottomSheet,
        private cookieService: CookieService,
        private ghostTranslationService: GhostTranslationService,
        private router: Router,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private viewContainer: ViewContainerRef
    ) { };

    public ngOnInit(): void {
        this.location = this.router.url;
        this.location == '/' && this.navigateToBaseRoute();
        this.setSelectedPortalName();
        this.ghostTranslationService.setDefaultLanguageTranslation().subscribe();
        this.routerSubscription = this.router.events.pipe(
            filter(event => event instanceof ActivationEnd)
        ).subscribe({
            next: (event: ActivationEnd) => {
                this.location = this.router.url;
                this.location == '/' && this.navigateToBaseRoute();
                this.setSelectedPortalName();
            }
        });
        this.loadingSubscription = this.loadingService.status.subscribe({
            next: (status: { show: boolean, text: string; }) => {
                this.loadingText = status.text || this.loadingText;
                this.showPageLoadingBar = status.show;
            }
        });
        // Cookie consent
        !this.cookieService.hasResponded() && this.showCookiePolicy();
        !this.cookieService.hasConsented() && this.cookieService.deleteCookie('_ga');

    };

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.loadingSubscription?.unsubscribe();
        this.routerSubscription?.unsubscribe();
    };

    public openWebchat = (): void => zE("webWidget", "open");

    public showCookiePolicy(canReload?: boolean): void {
        this.viewContainer.clear();
        this.viewContainer.createComponent(CookieConsentComponent).instance.hide.pipe(takeUntil(this.destroy$)).subscribe({
            next: hasAccepted => {
                this.cookieService.setCookie('igo_cookieconsent', hasAccepted ? 'allow' : 'deny');
                !hasAccepted && this.cookieService.deleteCookie('_ga'); // If the user declined cookies, we need to remove the _ga cookie
                // If the user has changed their cookie preference to 'allow' from the user-panel dropdown, we need to reload the application with Google Analytics Cookies
                canReload && hasAccepted && location.reload();
                this.viewContainer.clear();
            }
        });
    };

    private navigateToBaseRoute(): void {
        const operator = StorageService.getItem('operator');
        const originator = StorageService.getItem('originator');

        const route = operator ? BASE_ROUTES.operator : originator ? originator.global ? BASE_ROUTES.igo : BASE_ROUTES.originator : '/';
        this.router.navigate([route]);
    };

    private setSelectedPortalName(): void {
        if (this.location.includes('operatorportal')) {
            this.selectedPortalName = 'operator';
        } else if (this.location.includes('originatorportal')) {
            this.selectedPortalName = 'originator';
        } else if (this.location.includes('igoportal')) {
            this.selectedPortalName = 'igo';
        } else {
            this.selectedPortalName = '';
        };
    };
};

@Component({
    selector: 'app-version',
    template: `<div>Version #{{buildNumber}}</div>`,
})
class AppVersion {
    public buildNumber: string | number;

    public constructor() {
        this.buildNumber = environment.buildNumber;
    };
};