import { Injectable } from '@angular/core';
import { ArrayUtilities } from 'src/shared/array.utilities';
import { ChartsService } from './charts.service';
import {
    CreateTestEmulatorPayloadApi,
    CreateTestRegionPayload,
    GetAllTestEmulatorTestsResponseApi,
    GetAllTestEmulatorTestsResponseView,
    GetAllTestRegionsResponseApi,
    GetAllTestRegionsResponseView,
    GetGeneratedTestBookingsResponseApi,
    GetGeneratedTestBookingsResponseView,
    TestEmulatorBookingApi,
    TestEmulatorBookingView,
    TestEmulatorStatsApi,
    TestEmulatorTestApi,
    TestEmulatorTestView,
    UpdateGeneratedBookingPayload
} from '../models/test-emulator.model';
import { Endpoints } from '../shared/endpoints';
import { IChartConfigMap } from 'src/interfaces/charts.interface';
import { LookupType, RegionApi, RegionView } from 'src/models';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { RequestService } from './request.service';
import { StorageService } from './storage.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class TestEmulatorService {
    public dayTypes: LookupType[] = [
        { type: 'Monday', description: this.translateService.instant('originator_portal.test_emulator.test_rule.days.monday') },
        { type: 'Tuesday', description: this.translateService.instant('originator_portal.test_emulator.test_rule.days.tuesday') },
        { type: 'Wednesday', description: this.translateService.instant('originator_portal.test_emulator.test_rule.days.wednesday') },
        { type: 'Thursday', description: this.translateService.instant('originator_portal.test_emulator.test_rule.days.thursday') },
        { type: 'Friday', description: this.translateService.instant('originator_portal.test_emulator.test_rule.days.friday') },
        { type: 'Saturday', description: this.translateService.instant('originator_portal.test_emulator.test_rule.days.saturday') },
        { type: 'Sunday', description: this.translateService.instant('originator_portal.test_emulator.test_rule.days.sunday') }
    ];
    public jobTypes: LookupType[] = [
        { type: 'Asap', description: this.translateService.instant('originator_portal.test_emulator.test_rule.job_types.asap') },
        { type: 'Pre', description: this.translateService.instant('originator_portal.test_emulator.test_rule.job_types.pre') },
        { type: 'Now', description: this.translateService.instant('originator_portal.test_emulator.test_rule.job_types.now') }
    ];
    public vehicleTypes: LookupType[] = [
        { type: 'Standard', description: this.translateService.instant('originator_portal.test_emulator.test_rule.vehicle_types.standard') },
        { type: 'Luxury', description: this.translateService.instant('originator_portal.test_emulator.test_rule.vehicle_types.luxury') },
        { type: 'Wheelchair', description: this.translateService.instant('originator_portal.test_emulator.test_rule.vehicle_types.wheelchair') },
        { type: 'SUV', description: this.translateService.instant('originator_portal.test_emulator.test_rule.vehicle_types.suv') }
    ];

    public changeTab: Subject<number> = new Subject<number>();
    public selectedTestUpdated: Subject<TestEmulatorTestView> = new Subject<TestEmulatorTestView>();
    public tests: GetAllTestEmulatorTestsResponseView = null;

    public constructor(
        private chartService: ChartsService,
        private http: RequestService,
        private translateService: TranslateService
    ) { };

    public getTests = (): GetAllTestEmulatorTestsResponseView => this.tests;

    public createTestAsync(url: string, test: TestEmulatorTestView): Observable<TestEmulatorTestView> {
        test.originatorId = StorageService.getItem('originator').id;
        return this.http.authenticatedPost<TestEmulatorTestApi>(url || (Endpoints.API_URL + Endpoints.TEST_EMULATOR._TEST), new CreateTestEmulatorPayloadApi(test), {}, 'global.request.creating_test').pipe(
            map(res => new TestEmulatorTestView(res as TestEmulatorTestView))
        );
    };

    public createTestRegionAsync(region: RegionView): Observable<RegionView> {
        const originatorId: string = UserService.getPartnerTypeDetails().id;
        return this.http.authenticatedPost<RegionApi>(Endpoints.API_URL + Endpoints.TEST_EMULATOR._REGIONS, new CreateTestRegionPayload(region, originatorId), {}, 'global.request.creating_test_region').pipe(
            map(res => new RegionView(res as RegionView))
        );
    };

    public deleteTestAsync(testId: string): Observable<string> {
        return this.http.authenticatedDelete<void>(Endpoints.API_URL + Endpoints.TEST_EMULATOR._TEST + `/${testId}`, {}, 'global.request.deleting_test').pipe(
            map(res => testId)
        );
    };

    public deleteTestRegionAsync(testRegionId: string): Observable<string> {
        return this.http.authenticatedDelete<void>(Endpoints.API_URL + Endpoints.TEST_EMULATOR._REGIONS + `/${testRegionId}`, {}, 'global.request.deleting_region').pipe(
            map(res => testRegionId)
        );
    };

    public filterTestEmulatorTests(filteredTests?: TestEmulatorTestView[]): IChartConfigMap {
        let tests = this.tests.testEmulatorsList;
        if (filteredTests) {
            tests = filteredTests;
        };

        this.chartService.resetChartData('originatorTestEmulatorTestsChartConfig');

        tests.forEach(test => {
            this.chartService.originatorTestEmulatorTestsChartConfig.all.data.push(test);
            if (test.status == 'Pending') {
                this.chartService.originatorTestEmulatorTestsChartConfig.pending.data.push(test);
            } else if (test.status == 'ReadyToStart') {
                this.chartService.originatorTestEmulatorTestsChartConfig.readyToStart.data.push(test);
            } else if (test.status == 'Active') {
                this.chartService.originatorTestEmulatorTestsChartConfig.active.data.push(test);
            } else if (test.status == 'Stopped') {
                this.chartService.originatorTestEmulatorTestsChartConfig.stopped.data.push(test);
            } else if (test.status == 'Completed') {
                this.chartService.originatorTestEmulatorTestsChartConfig.completed.data.push(test);
            };
        });

        return this.chartService.setChartValues('originatorTestEmulatorTestsChartConfig');
    };

    public filterCompletedBookings(completedBookings: TestEmulatorBookingView[]): IChartConfigMap {
        this.chartService.resetChartData('testBookingsStatsChartConfig');

        completedBookings.forEach((booking, index) => {
            this.chartService.testBookingsStatsChartConfig.all.data.push(booking);

            booking.completionFilter == 'Completed' && this.chartService.testBookingsStatsChartConfig.completed.data.push(booking);
            booking.completionFilter == 'WithErrors' && this.chartService.testBookingsStatsChartConfig.completedWithErrors.data.push(booking);
            booking.completionFilter == 'NotCompleted' && this.chartService.testBookingsStatsChartConfig.notCompleted.data.push(booking);

            booking.preBookingFilter == 'ZeroToTwo' && this.chartService.testBookingsStatsChartConfig.prebookingLatenessZeroToTwo.data.push(booking);
            booking.preBookingFilter == 'TwoToFive' && this.chartService.testBookingsStatsChartConfig.prebookingLatenessTwoToFive.data.push(booking);
            booking.preBookingFilter == 'FiveToTen' && this.chartService.testBookingsStatsChartConfig.prebookingLatenessFiveToTen.data.push(booking);
            booking.preBookingFilter == 'TenPlus' && this.chartService.testBookingsStatsChartConfig.prebookingLatenessTenPlus.data.push(booking);

            booking.asapLatenessFilter == 'ZeroToTwo' && this.chartService.testBookingsStatsChartConfig.asapLatenessZeroToTwo.data.push(booking);
            booking.asapLatenessFilter == 'TwoToFive' && this.chartService.testBookingsStatsChartConfig.asapLatenessTwoToFive.data.push(booking);
            booking.asapLatenessFilter == 'FiveToTen' && this.chartService.testBookingsStatsChartConfig.asapLatenessFiveToTen.data.push(booking);
            booking.asapLatenessFilter == 'TenPlus' && this.chartService.testBookingsStatsChartConfig.asapLatenessTenPlus.data.push(booking);

            booking.asapTimeToArriveFilter == 'ZeroToFive' && this.chartService.testBookingsStatsChartConfig.asapTimeToArriveZeroToFive.data.push(booking);
            booking.asapTimeToArriveFilter == 'FiveToTen' && this.chartService.testBookingsStatsChartConfig.asapTimeToArriveFiveToTen.data.push(booking);
            booking.asapTimeToArriveFilter == 'TenToFifteen' && this.chartService.testBookingsStatsChartConfig.asapTimeToArriveTenToFifteen.data.push(booking);
            booking.asapTimeToArriveFilter == 'FifteenPlus' && this.chartService.testBookingsStatsChartConfig.asapTimeToArriveFifteenPlus.data.push(booking);

            booking.timeToDispatchFilter == 'ZeroToOne' && this.chartService.testBookingsStatsChartConfig.timeToDispatchZeroToOne.data.push(booking);
            booking.timeToDispatchFilter == 'OneToTwo' && this.chartService.testBookingsStatsChartConfig.timeToDispatchOneToTwo.data.push(booking);
            booking.timeToDispatchFilter == 'TwoToThree' && this.chartService.testBookingsStatsChartConfig.timeToDispatchTwoToThree.data.push(booking);
            booking.timeToDispatchFilter == 'ThreeToFour' && this.chartService.testBookingsStatsChartConfig.timeToDispatchThreeToFour.data.push(booking);
            booking.timeToDispatchFilter == 'FourToFive' && this.chartService.testBookingsStatsChartConfig.timeToDispatchFourToFive.data.push(booking);
            booking.timeToDispatchFilter == 'FiveToSix' && this.chartService.testBookingsStatsChartConfig.timeToDispatchFiveToSix.data.push(booking);
            booking.timeToDispatchFilter == 'SixToSeven' && this.chartService.testBookingsStatsChartConfig.timeToDispatchSixToSeven.data.push(booking);
            booking.timeToDispatchFilter == 'SevenToEight' && this.chartService.testBookingsStatsChartConfig.timeToDispatchSevenToEight.data.push(booking);
            booking.timeToDispatchFilter == 'EightPlus' && this.chartService.testBookingsStatsChartConfig.timeToDispatchEightPlus.data.push(booking);
        });

        return this.chartService.setChartValues('testBookingsStatsChartConfig');
    };

    public generateTestAsync(testId: string): Observable<GetGeneratedTestBookingsResponseView> {
        const payload = { id: testId, originatorId: StorageService.getItem('originator').id };
        return this.http.authenticatedPost<GetGeneratedTestBookingsResponseApi>(Endpoints.API_URL + Endpoints.TEST_EMULATOR._TEST + `/${testId}` + Endpoints.TEST_EMULATOR._GENERATE, payload, {}, 'global.request.generating_bookings').pipe(
            map(res => new GetGeneratedTestBookingsResponseView(res))
        );
    };

    public getAllTestRegionsObservable(): Observable<GetAllTestRegionsResponseView> {
        return this.http.authenticatedGet<GetAllTestRegionsResponseApi>(Endpoints.API_URL + Endpoints.TEST_EMULATOR._REGIONS, {}, 'global.request.loading_test_regions').pipe(
            map(res => new GetAllTestRegionsResponseView(res as GetAllTestRegionsResponseView))
        );
    };

    public getTestByIdObservable(testId: string): Observable<TestEmulatorTestView> {
        return this.http.authenticatedGet<TestEmulatorTestApi>(Endpoints.API_URL + Endpoints.TEST_EMULATOR._TEST + `/${testId}`, {}, 'global.request.loading_test').pipe(
            map(res => new TestEmulatorTestView(res as TestEmulatorTestView))
        );
    };

    public getAllTestsObservable(url: string): Observable<void> {
        return this.http.authenticatedGet<GetAllTestEmulatorTestsResponseApi>(url, {}, 'global.request.loading_tests').pipe(
            map(res => {
                const testsView = res.testEmulatorsList.map(i => new TestEmulatorTestView(i as TestEmulatorTestView));
                this.tests = new GetAllTestEmulatorTestsResponseView({
                    testEmulatorsList: ArrayUtilities.sortAlphabetically(testsView, 'name'),
                    _links: res._links
                });
            })
        );
    };

    public getCompletedTestEmulatorBookingsObservable(testEmulatorId: string): Observable<GetGeneratedTestBookingsResponseView> {
        return this.http.authenticatedGet<GetGeneratedTestBookingsResponseApi>(Endpoints.API_URL + Endpoints.TEST_EMULATOR._BOOKINGS + `/${testEmulatorId}` + Endpoints.TEST_EMULATOR._COMPLETED_BOOKINGS, {}, 'global.request.loading_completed_bookings').pipe(
            map(res => new GetGeneratedTestBookingsResponseView(res))
        );
    };

    public getGeneratedTestEmulatorBookingsObservable(testEmulatorId: string): Observable<GetGeneratedTestBookingsResponseView> {
        return this.http.authenticatedGet<GetGeneratedTestBookingsResponseApi>(Endpoints.API_URL + Endpoints.TEST_EMULATOR._BOOKINGS + `/${testEmulatorId}`, {}, 'global.request.loading_test_bookings').pipe(
            map(res => new GetGeneratedTestBookingsResponseView(res))
        );
    };

    public getTestEmulatorStatsObservable(testEmulatorId: string): Observable<TestEmulatorStatsApi> {
        return this.http.authenticatedGet<TestEmulatorStatsApi>(Endpoints.API_URL + Endpoints.TEST_EMULATOR._STATS + `/${testEmulatorId}`, {}, 'global.request.loading_test_stats').pipe(
            map(res => new TestEmulatorStatsApi(res))
        );
    };

    public startTestAsync(testId: string): Observable<TestEmulatorTestView> {
        return this.http.authenticatedPost<TestEmulatorTestApi>(Endpoints.API_URL + Endpoints.TEST_EMULATOR._TEST + `/${testId}` + Endpoints.TEST_EMULATOR._START, {}, {}, 'global.request.starting_test').pipe(
            map(res => new TestEmulatorTestView(res as TestEmulatorTestView))
        );
    };

    public stopTestAsync(testId: string): Observable<TestEmulatorTestView> {
        return this.http.authenticatedPost<TestEmulatorTestApi>(Endpoints.API_URL + Endpoints.TEST_EMULATOR._TEST + `/${testId}` + Endpoints.TEST_EMULATOR._STOP, {}, {}, 'global.request.stopping_test').pipe(
            map(res => new TestEmulatorTestView(res as TestEmulatorTestView))
        );
    };

    public updateGeneratedBookingDetailsAsync(payload: UpdateGeneratedBookingPayload): Observable<TestEmulatorBookingView> {
        return this.http.authenticatedPut<TestEmulatorBookingApi>(Endpoints.API_URL + Endpoints.TEST_EMULATOR._BOOKINGS + `/${payload.id}`, payload, {}, 'global.request.updating_test_booking').pipe(
            map(res => new TestEmulatorBookingView(res))
        );
    };

    public updateTestAsync(test: TestEmulatorTestView): Observable<TestEmulatorTestView> {
        return this.http.authenticatedPut<TestEmulatorTestApi>(test._links.UpdateTest.href, new TestEmulatorTestApi(test), {}, 'global.request.updating_test').pipe(
            map(res => new TestEmulatorTestView(res as TestEmulatorTestView))
        );
    };
};