import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { TestEmulatorTestView } from 'src/models';

@Component({
    selector: 'test-emulator-update-status-cell',
    template: `<button class="btn btn-{{buttonClass}} status-box">
        <i [attr.class]="iconClass + ' me-2'"></i> {{buttonText | translate}}
    </button>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { '[class.disabled]': 'disabled' },
    styles: [`
        :host { display: flex; flex-flow: column; justify-content: center; height: 100%; padding: 0 20px; width: fit-content; }
        :host.gvs-action-cell i { font-size: 20px; }
        :host.disabled { opacity: 0.5; cursor: not-allowed; }
        :host .status-box { align-items: center; display: flex; padding: 12px 30px; white-space: pre-line; }
    `]
})
export class TestEmulatorUpdateStatusCellTplComponent {
    @Input() public set row(item: TestEmulatorTestView) {
        if (item) {
            this.updatingStatus = Boolean(item.updatingStatus);
            this.disabled = this.updatingStatus;
            const { buttonClass, buttonText, iconClass } = this.getButtonValues(item);
            this.buttonClass = buttonClass || 'originator';
            this.buttonText = buttonText;
            this.iconClass = iconClass;
            this.cd.detectChanges();
        };
    };
    public buttonClass: string = '';
    public buttonText: string = '';
    public disabled: boolean = false;
    public iconClass: string = '';
    public updatingStatus: boolean = false;
    public constructor(private cd: ChangeDetectorRef) { };

    private getButtonValues(item: TestEmulatorTestView): { buttonClass?: string, iconClass: string, buttonText: string } {
        if (this.updatingStatus) {
            return { iconClass: 'fal fa-spinner-third fa-spin', buttonText: this.buttonText };
        } else {
            switch (item.status) {
                case 'Pending':
                    return { iconClass: 'fal fa-clipboard-list', buttonText: 'originator_portal.test_emulator.table.generate_bookings' };
                case 'ReadyToStart':
                    return { buttonClass: 'success', iconClass: 'fal fa-play-circle', buttonText: 'originator_portal.test_emulator.table.start_test' };
                case 'Active':
                    return { buttonClass: 'danger', iconClass: 'fal fa-stop-circle', buttonText: 'originator_portal.test_emulator.table.stop_test' };
                case 'Stopped':
                    return { buttonClass: 'success', iconClass: 'fal fa-play-circle', buttonText: 'originator_portal.test_emulator.table.start_test' };
                case 'Completed':
                    return { buttonClass: 'operator', iconClass: 'fal fa-chart-bar', buttonText: 'originator_portal.test_emulator.table.view_stats' };
                default:
                    return { iconClass: '', buttonText: '' };
            };
        };
    };
};