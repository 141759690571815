// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form .color-picker .preset-area {
    padding: 4px 4px;
}

form .color-picker .preset-area .preset-color {
    border: none;
    border-radius: 0px;
    height: 34px;
    margin: 0px;
    width: 44px;
}

form .color-picker .preset-area .preset-label {
    font-size: 13px;
    font-family: ZonaProBold;
}

form input.form-control[colour] {
    cursor: pointer;
    border: 1px solid rgb(var(--igo-dark-grey));
    width: 40px;
    border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/styles/ngxcolorpicker.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,WAAW;AACf;;AAEA;IACI,eAAe;IACf,wBAAwB;AAC5B;;AAEA;IACI,eAAe;IACf,2CAA2C;IAC3C,WAAW;IACX,kBAAkB;AACtB","sourcesContent":["form .color-picker .preset-area {\n    padding: 4px 4px;\n}\n\nform .color-picker .preset-area .preset-color {\n    border: none;\n    border-radius: 0px;\n    height: 34px;\n    margin: 0px;\n    width: 44px;\n}\n\nform .color-picker .preset-area .preset-label {\n    font-size: 13px;\n    font-family: ZonaProBold;\n}\n\nform input.form-control[colour] {\n    cursor: pointer;\n    border: 1px solid rgb(var(--igo-dark-grey));\n    width: 40px;\n    border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
