// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.currency-input {
    align-items: baseline;
    font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/shared-value-input/shared-value-input.component.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,eAAe;AACnB","sourcesContent":[".currency-input {\n    align-items: baseline;\n    font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
