import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

interface IProgress {
    description: string;
    progress: number;
};

export interface ILoadingBarStatus {
    show: boolean;
    text?: string;
}

@Injectable({ providedIn: 'root' })
export class LoadingService {
    public appDataLoadingProgress = new Subject<any>();
    public status: Subject<ILoadingBarStatus> = new Subject<ILoadingBarStatus>();
    
    private progress = 0;
    
    public constructor() {
        this.progress = 0;
    };

    public display(value: ILoadingBarStatus): void {
        this.status.next(value);
    };

    public emitProgress(progress: IProgress, finishedLoading?: boolean): void {
        if (finishedLoading) {
            this.resetProgress();
        };
        this.appDataLoadingProgress.next(progress);
    };

    public getProgress(requestCount: number): number {
        this.progress += 100 / requestCount;
        return Math.floor(this.progress);
    };

    public onAppDataLoadingChange = (): Observable<any> => this.appDataLoadingProgress.asObservable();

    public resetProgress(): void {
        this.progress = 0;
    };
};