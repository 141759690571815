// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        :host { align-items: center; display: flex; height: 100%; padding: 2px 20px; white-space: pre-line; width: 100%; }
        :host.gvs-action-cell:hover { background-color: rgb(42 42 42 / 20%) !important; transition: 0.2s ease-in-out; }
        :host span { overflow: hidden; text-overflow: ellipsis; white-space: pre; }
        :host.gvs-action-cell i { font-size: 20px; }
    `, "",{"version":3,"sources":["webpack://./src/shared/table-cell-templates/dispute-last-message-cell-template.component.ts"],"names":[],"mappings":";QACQ,QAAQ,mBAAmB,EAAE,aAAa,EAAE,YAAY,EAAE,iBAAiB,EAAE,qBAAqB,EAAE,WAAW,EAAE;QACjH,8BAA8B,gDAAgD,EAAE,4BAA4B,EAAE;QAC9G,aAAa,gBAAgB,EAAE,uBAAuB,EAAE,gBAAgB,EAAE;QAC1E,0BAA0B,eAAe,EAAE","sourcesContent":["\n        :host { align-items: center; display: flex; height: 100%; padding: 2px 20px; white-space: pre-line; width: 100%; }\n        :host.gvs-action-cell:hover { background-color: rgb(42 42 42 / 20%) !important; transition: 0.2s ease-in-out; }\n        :host span { overflow: hidden; text-overflow: ellipsis; white-space: pre; }\n        :host.gvs-action-cell i { font-size: 20px; }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
